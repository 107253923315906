import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ItemButton, RightDivider } from '@code-yellow/spider';
import { Button, Modal } from 'semantic-ui-react';
import { Batch } from 'store/Batch';
import { getPrinters, getPrinterType, printQrCode } from 'helpers/print'
import { api } from 'store/Base'
import { GlobalValue } from 'store/GlobalValue'
import { isFeatureFlagEnabled } from 'helpers/featureFlags'

const QRCode = styled.img`
    padding-top: 25px;
    max-width: 250px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

@observer
export class BatchQrCode extends Component {
    static propTypes = {
        batch: PropTypes.instanceOf(Batch).isRequired,
    }

    render() {
        const { batch } = this.props

        if (!isFeatureFlagEnabled('batch_qr_code')) {
            return null
        }

        return (
            <QRCode data-qr-code-img
                src={`/api/batch/${batch.id}/qr/`}
                alt="qr-code"
            />
        )
    }
}

const QRCODE_TEMPLATE = `
CLS\n
SIZE 90 mm, 70 mm\n
GAP 10 mm\n
OFFSET 0 mm\n

DIRECTION 0\n
REFERENCE 0,0\n
DENSITY 8\n
SPEED 2.0\n

SET RIBBON ON\n

SET PEEL OFF\n
SET CUTTER OFF\n

SET TEAR ON\n

QRCODE 80,580,L,4,M,0,M2,S7,"A{{qr_code}}"\n

PRINT 1\n
`
// BARCODE 80,520,"128",100,2,270,4,2,1,"{{serial_number}}"\n
// TEXT 230,400,"ROMAN.TTF",270,1,8,"{{serial_number}}"\n

@observer
export default class BatchQRModal extends Component {
    static propTypes = {
        batch: PropTypes.instanceOf(Batch).isRequired,
    };

    @observable isOpen = false
    @observable printerModel = new GlobalValue({ key: 'printer_model' })
    @observable qrCode = null

    open = async () => {
        const { batch } = this.props;

        this.isOpen = true;
        await this.printerModel.fetch()
        this.qrCode = await api.get(`batch/${batch.id}/qr/`)
    };
    close = () => this.isOpen = false;

    render() {
        const { batch } = this.props;

        if (!isFeatureFlagEnabled('batch_qr_code')) {
            return null
        }

        return (
            <div>
                <Modal
                    closeOnDimmerClick={false}
                    closeIcon
                    trigger={
                        <ItemButton data-test-qr-modal-button
                            primary={false}
                            label={'View QR code'}
                            icon="qrcode"
                        />
                    }
                    open={this.isOpen}
                    onOpen={this.open}
                    onClose={this.close}
                >
                    <Modal.Header>
                        {'Batch ID: ' + batch.id + ' | SN: ' + batch.serialNumber}
                    </Modal.Header>
                    <Modal.Content>
                        <Container>
                            {this.qrCode && <BatchQrCode batch={batch} />}
                        </Container>
                    </Modal.Content>
                    <Modal.Actions>
                        <RightDivider />
                        <Button data-test-print-qr-code
                            icon='print'
                            content={'Print QR Code'}
                            disabled={this.qrCode === null}
                            onClick={async () => {
                                const printer = (await getPrinters()).find((printer) => getPrinterType(printer) === this.printerModel.value)
                                const instructions = QRCODE_TEMPLATE.replace(/\{\{qr_code\}\}/g, this.qrCode)
                                if (printer) {
                                    await printQrCode(printer, instructions, { copies: 1 })
                                }
                            }}
                        />
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}