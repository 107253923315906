import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { SalesOrder } from './SalesOrder';
import { CarrierRequestRowPickOrderLinkStore } from './CarrierRequestRowPickOrderLink.js'

export class CarrierRequestRow extends Model {
  static backendResourceName = 'carrier_request_row';

  @observable colloNr = '';
  @observable shippingUnit = '';
  @observable length = '';
  @observable width = '';
  @observable height = '';
  @observable weigth = '';
  @observable volume = null;
  @observable reference = '';
  @observable barcode = null;
  @observable printed = false;

  relations() {
    return {
      salesOrder: SalesOrder,
      carrierRequestRowsPickOrderLinks: CarrierRequestRowPickOrderLinkStore,
    }
  }

  getShippingLabel() {
    return this.api.get(`${this.url}shipping_label/`)
  }
}

export class CarrierRequestRowStore extends Store {
  Model = CarrierRequestRow
  static backendResourceName = 'carrier_request_row';
}
