
import { observable, computed } from 'mobx'
import { Model, Store } from 'store/Base'
import { result } from 'lodash'

export class GlobalValue extends Model {
  static backendResourceName = 'global_value'

  @observable id = null
  @observable key = ''
  @observable value = '' // NOTE: May need special casts for keys with custom (de)serializers...
  @observable readable = ''
  @observable writable = ''

  getUrl() {
    return `/assets/global-value/${this.id}/edit`
  }

  @computed get isNew() {
    return false
  }

  @computed get url() {
    // can't use super() with @computed values so code was copied
    // https://github.com/mobxjs/mobx/issues/1605
    if (this.id) {
      const id = this[this.constructor.primaryKey]
      return `${result(this, 'urlRoot')}${id ? `${id}/` : ''}`
    } else {
      return `${result(this, 'urlRoot')}value/${this.key}/`
    }
  }
}

export class GlobalValueStore extends Store {
  Model = GlobalValue
  static backendResourceName = 'global_value'

}
