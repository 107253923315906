import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { UserStore } from './User'
import { OperatorStore } from './Operator'
import { LeaveSlotStore } from './LeaveSlot'

export class LeaveCalendar extends Model {
  static backendResourceName = 'leave_calendar'

  @observable id = null
  @observable name = ''

  relations() {
    return {
      users: UserStore,
      operators: OperatorStore,
      leaveSlots: LeaveSlotStore,
    }
  }
}

export class LeaveCalendarStore extends Store {
  Model = LeaveCalendar
  static backendResourceName = 'leave_calendar'
}
