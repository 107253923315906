import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { TYPE_INTEGRATION_RADAN } from '../../../store/Integration';
import RadanExportStep from './Export/Radan';
import { Batch } from 'store/Batch';
import PerformStep from './Step'
import { Container } from './helpers'



@observer
export default class PerformExportStep extends PerformStep {

  static propTypes = {
    ...PerformStep.propTypes,
    batch: PropTypes.instanceOf(Batch).isRequired
  }

  /**
   * Get the content depending on the type of integration
   */
  getIntegrationContent = () => {
    const { step, batch } = this.props;
    const integration = step.exportStep?.integration;

    if (!integration) {
      return (<></>)
    }

    if (integration.type === TYPE_INTEGRATION_RADAN) {
      return (
        <RadanExportStep
          integration={integration}
          batch={batch}
        />
      )
    }


    return (<></>);
  }

  render() {
    const content = this.getIntegrationContent();
    return (
      <>
        <Container>
          {content}
        </Container>
        {this.renderButtons()}
      </>
    )
  }

}
