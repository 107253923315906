import React, { Component } from 'react';
import TimingTable from '../../feature/OEE/Components/ProcessTimingChart/TimingTable';
import { ProductionRequest } from '../../store/ProductionRequest';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import moment from 'moment';
import { MetaData, ProcessData, StepDatum } from '../../feature/OEE/Components/ProcessTimingChart/Interface';
import GanttLegend from '../../feature/OEE/Components/ProcessTimingChart/GanttLegend';

interface TimingGanttChartProps {
    productionRequest: ProductionRequest;
}


@observer
export default class TimingGanttChart extends Component<TimingGanttChartProps> {

    @observable stepData: null | StepDatum[] = null;

    @observable processData: null | ProcessData = null;

    @observable metaData: null | MetaData  = null;

    componentDidMount() {
        const { productionRequest } = this.props;

        // @ts-ignore
        productionRequest.api
            .__request('get', `production_request/${productionRequest.id}/process_timing/`, {}, {})
            .then(data => {

                this.stepData = data.steps.map(
                    datum => {
                        return {
                            name: datum.name,
                            workStation :datum.work_station,
                            plannedStart: moment(datum.planned_start),
                            plannedEnd: moment(datum.planned_end),
                            actualStart: datum.actual_start ? moment(datum.actual_start) : datum.actual_start,
                            actualEnd: datum.actual_end ? moment(datum.actual_end) : datum.actual_end,
                        }
                    }
                )

                this.metaData = {
                    firstTimeStamp: moment(data.meta.first_timestamp),
                    lastTimeStamp: moment(data.meta.last_timestamp)
                }

                this.processData = {
                    name: data.process.name,
                    plannedStartAt: moment(data.process.planned_start_at),
                    plannedEndAt: moment(data.process.planned_end_at),
                    erpPlannedEndAt: data.process.erp_planned_end_at && moment(data.process.erp_planned_end_at),
                    actualStartAt: data.process.actual_start_at && moment(data.process.actual_start_at),
                    actualEndAt: data.process.actual_end_at && moment(data.process.actual_end_at)
                }
            })

    }

    render() {
        if (!this.stepData || !this.processData || !this.metaData) {
            return (
                <></>
            )
        }
        return (
            <div style={{ position: 'relative' }}>
                <TimingTable
                    steps={this.stepData}
                    processData={this.processData}
                    metaData={this.metaData}
                />
                <GanttLegend/>
            </div>
        )
    }
}
