import React, { Component } from 'react'
import { Table } from 'semantic-ui-react'
import PropTypes from 'prop-types'

import { Batch } from 'store/Batch'
import { Step } from 'store/Step'
import { SerialNumber, Quantity } from 'component/TargetInfoModal/TargetDescription'

import { humanReadable } from 'helpers/decimal'
import Decimal from 'decimal.js'

export default class BatchMaterialPlan extends Component {

  static propTypes = {
    batch: PropTypes.instanceOf(Batch).isRequired,
    step: PropTypes.instanceOf(Step),
  }

  // Add the quantites of all the scanned batches of a certain article type
  // Also compares this quantity to the expected bom value
  getLineQuantity(line) {

    const { batch } = this.props

    // Sum of all scanned batches
    const sum = line.scannedBatches.map((scan) => scan.scannedQuantity).reduce((sum, quantity) => sum.add(quantity), Decimal(0))
    // Required quatity from the bom (quantity needed for whole order / items in order * number of items in the batch)
    const bomRequired = Decimal(line.bomItem.quantity).mul(batch.quantity)
    return(
    <Quantity>{humanReadable(sum, 3)}/{humanReadable(bomRequired, 3)}</Quantity>
  )
  }

  // Returns a comma separated list of all the serial numbers scanned for an article type
  getLineScannedBatches(line) {
    return(line.scannedBatches.map((scan) => (
      <><SerialNumber>{scan.batch.serialNumber}</SerialNumber><Quantity>({humanReadable(scan.scannedQuantity)})</Quantity></>
  )))
  }

  // Converts all the component batches info a data structure called "lines" with:
  //
  // scannedBatches: [{batch, scannedQuantity}] - scanned batch and how much of it was used. There can be multiple scanned batches
  // articleType - stores the article type of the scanned batches
  // bomItem - stores the linked billOfMaterialItem linked to this PR and ArticleType
  //
  getLinesInfo(subBatches){

    const { batch } = this.props

    let lines = []
    subBatches.forEach((subBatch) => {

      const usedBatch = subBatch.batchUsings.at(0).usedBatch
      const articleType = subBatch.batchType.articleType
      const targetLine = lines.findIndex((line) => line['articleType'].id === articleType.id)

      if (targetLine === -1){
        const bomItem = batch.productionRequest.productionOrder.materialPlan.items.find((item) => item.articleType.id === articleType.id)
        lines.push({
          scannedBatches : [{ batch: usedBatch, scannedQuantity: subBatch.quantity }],
          articleType : articleType,
          bomItem : bomItem,
        })
      }
      else{
        lines[targetLine]['scannedBatches'].push({ batch: usedBatch, scannedQuantity: subBatch.quantity })
      }
    })

    return lines
  }

  renderStepMaterialPlanLine(line) {
    const { step } = this.props
    return (
      <Table.Row>
        <Table.Cell data-test-material-scanned-batches={line.articleType.id} data-test-step={step?.id} collapsing>
          {this.getLineScannedBatches(line)}
        </Table.Cell>
        <Table.Cell data-test-material-quantity={line.articleType.id} data-test-step={step?.id} collapsing>
          {this.getLineQuantity(line)}
        </Table.Cell>
        <Table.Cell collapsing data-test-material-article-link={line.articleType.id} data-test-step={step?.id}>{line.articleType.getLink()}</Table.Cell>
        <Table.Cell collapsing data-test-material-article-name={line.articleType.id} data-test-step={step?.id}>{line.articleType.name}</Table.Cell>
      </Table.Row>
    )
  }

  render() {
    const { batch, step } = this.props

    if (!batch){
      return null
    }

    let subBatches = batch.componentBatches.filter((batch) =>
      batch.deleted === false &&
      batch.scrapReason === null
    )

    // We also have to display the entire material plan (at no particular step)
    if(step){
      subBatches = subBatches.filter((batch) => batch.lastStep.id === step.id)
    }

    const lines = this.getLinesInfo(subBatches)

    return(
      <Table data-test-materials-table data-test-step={step?.id} collapsing basic="very">
      <Table.Header>
          <Table.Row>
              <Table.HeaderCell collapsing>{t('targetInfoModal.materialPlanTaskField.usedBatches')}</Table.HeaderCell>
              <Table.HeaderCell collapsing>{t('targetInfoModal.materialPlanTaskField.quantity')}</Table.HeaderCell>
              <Table.HeaderCell collapsing/>
              <Table.HeaderCell collapsing>{t('targetInfoModal.materialPlanTaskField.description')}</Table.HeaderCell>
          </Table.Row>
      </Table.Header>
      {lines.map((line) => (
          this.renderStepMaterialPlanLine(line)
      ))}
  </Table>
    )
  }
}


