import React, { Component } from 'react';
import { TargetSerialNumberFormat as BaseTSNF } from '@code-yellow/spider';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { MetafieldStore } from '../store/Metafield';

@observer
export default class TargetSerialNumberFormat extends Component {

  @observable metafields = new MetafieldStore({
    params: {
      limit: 'none',
      '.entry_level': 'article_type',
      '.type:in': 'text,check,format,choice', // types should be printable in a metafield
    }
  });

  static propTypes = BaseTSNF.propTypes;

  componentDidMount() {
    this.metafields.fetch();
  }

  render() {
    return <BaseTSNF metafields={this.metafields} {...this.props} />;
  }
}

export { SerialNumberFormatPropType, SerialNumberPartPropType } from '@code-yellow/spider';
