import qz from 'qz-tray'
import sha from 'sha.js'

import { DateTime } from 'luxon';
import { api } from 'store/Base'
import { showErrorNotification } from './notification';

let __onPrintError = () => { }
let mockPrinter = false

export function setMockPrinter(value) {
  mockPrinter = value
}

export function onPrintError(err) {
  return __onPrintError(err)
}

export function setOnPrintError(func) {
  __onPrintError = func
}

qz.api.setPromiseType((resolver) => new Promise(resolver))
qz.api.setSha256Type((data) => sha('sha256').update(data).digest('hex'))

qz.security.setCertificatePromise((resolve, reject) => api.get('certificate/').then(resolve).catch(reject))

qz.security.setSignaturePromise((message) => (resolve, reject) =>
  api.get('sign/', { message }).then(resolve).catch(reject)
)

let qzConnection = null

qz.websocket.setClosedCallbacks([() => qzConnection = null])

export async function connect() {
  if (qzConnection === null) {
    qzConnection = qz.websocket.connect()
  }
  try {
    return await qzConnection
  } catch (e) {
    qzConnection = null
    return e
  }
}

async function testConnection(printer) {
  if (mockPrinter) {
    window.viewStore.showNotification({
      key: 'mockPrinter',
      error: false,
      dismissAfter: 4000,
      message: 'Printjob sent to Mocked printer',
      icon: 'print',
    })
    return;
  }

  await connect()
  await qz.printers.find(printer)
}

export async function printPdf(printer, file, { copies = 1, handleError = true, key = '' }) {
  if (mockPrinter) {
    window.viewStore.showNotification({
      key: 'printInstructions' + key,
      error: false,
      dismissAfter: 4000,
      message: `Print Document: ${file}`,
      icon: 'print',
    })
    return
  }

  const pdfData = Buffer.from(await api.get(file.slice('/api'.length), undefined, { responseType: 'arraybuffer' })).toString('base64')

  const data = []
  for (let i = 0; i < copies; i++) {
    data.push({
      type: 'pixel',
      format: 'pdf',
      flavor: 'base64',
      data: pdfData,
    })
  }

  try {
    await connect()
    return await qz.print(qz.configs.create(printer), data)
  } catch (err) {
    if (handleError) {
      onPrintError(err)
    }
  }
}

export async function printQrCode(printer, qrCode, { copies = 1, handleError = true, key = '' }) {
  if (mockPrinter) {
    window.viewStore.showNotification({
      key: 'printInstructions' + key,
      error: false,
      dismissAfter: 4000,
      message: `Print QR Code: ${qrCode}`,
      icon: 'print',
    })
    return
  }

  const data = []
  for (let i = 0; i < copies; i++) {
    data.push({
      type: 'pixel',
      format: 'image',
      flavor: 'file',
      data: qrCode,
    });
  }

  try {
    await connect()
    return await qz.print(qz.configs.create(printer), data)
  } catch (err) {
    if (handleError) {
      onPrintError(err)
    }
  }
}

export function getPrinterType(name) {
  if (window.viewStore.altecPrinterNames.includes(name)) {
    return 'altec'
  } else if (window.viewStore.zebraPrinterNames.includes(name)) {
    return 'zebra'
  } else {
    return 'document'
  }
}

export async function getPrinters() {
  if (mockPrinter) {
    const printers = [
      ...window.viewStore.altecPrinterNames,
      ...window.viewStore.zebraPrinterNames,
    ]
    for (let i = 1; true; i++) {
      const name = i === 1 ? 'document' : `document (${i})`
      if (getPrinterType(name) === 'document') {
        printers.push(name)
        break
      }
    }
    return printers
  }
  await connect()
  try {
    return await qz.printers.find()
  } catch (e) {
    showErrorNotification('Cannot connect to QZ tray')
    return [];
  }
}

export async function print(printer, instructions, { copies = 1, handleError = true, key = '' } = {}) {
  if (getPrinterType(printer) === 'altec'){
    instructions = instructions + '\n'
  }

  instructions = instructions.repeat(copies)
  if (mockPrinter) {
    window.viewStore.showNotification({
      key: 'printInstructions' + key,
      error: false,
      dismissAfter: 4000,
      message: `Print Instructions (${printer}): ${instructions}`,
      icon: 'print',
    })
    return
  }

  // use this for local testing to connect directly to printer via printer IP in CY office
  // if (qzConnection === null) {
  //     const options = {'host': ["10.97.14.144"]};
  //     qzConnection = qz.websocket.connect(options).catch((err) => {
  //         throw err;
  //     });
  // }



  try {
    await connect()
    printer = await qz.printers.find(printer)
    return await qz.print(qz.configs.create(printer), [instructions])
  } catch (err) {
    if (handleError) {
      onPrintError(err)
    }
    throw err
  }
}

const PRINTERS = [
  {
    type: 'altec',
    print: (printer, ...args) => print(printer, ...args),
    testConnection: (printer, ...args) => testConnection(printer, ...args),
  },
  {
    type: 'zebra',
    print: (printer, ...args) => print(printer, ...args),
    testConnection: (printer, ...args) => testConnection(printer, ...args),
  },
]

const PRINT_CODE_TEMPLATES = {
  altec: (code, name) =>
    'CLS\n' +
    'SIZE 60 mm, 40 mm\n' +
    'GAP 10 mm\n' +
    'OFFSET 0 mm\n' +
    '\n' +
    'DIRECTION 1,0\n' +
    'REFERENCE 0,0\n' +
    'DENSITY 8\n' +
    'SPEED 2.0\n' +
    '\n' +
    'SET RIBBON ON\n' +
    '\n' +
    'SET PEEL OFF\n' +
    'SET CUTTER OFF\n' +
    '\n' +
    'SET TEAR ON\n' +
    '\n' +
    `BARCODE 360,122,"128",200,0,0,4,4,2,"${code}"\n` +
    `TEXT ${360 - 10 * name.length},334,"1",0,2,2,"${name}"\n` +
    '\n' +
    'PRINT 1\n',
}

export const PRINTERS_WITH_CODE_TEMPLATE = PRINTERS.map((p) => p.type).filter(
  (type) => PRINT_CODE_TEMPLATES[type] !== undefined
)

export function printCode(code, name, printers = PRINTERS_WITH_CODE_TEMPLATE) {
  if (printers.length === 0) {
    return onPrintError(t('productionLine.codesModal.noPrintersAvailable'))
  }

  const head = printers[0]
  const tail = printers.slice(1)
  const { print } = PRINTERS.find((p) => p.type === head)

  return print(PRINT_CODE_TEMPLATES[head](code, name), false).catch(() => printCode(code, name, tail))
}

export default PRINTERS

export async function getSubBatchInstructions(template, productionRequest, batch, operator, batchSize, selectedMaterialPlanTask){

  let articleType
  if (selectedMaterialPlanTask){
    articleType = selectedMaterialPlanTask?.articleType
  }
  else if (batch.batchType){
    articleType = batch.batchType.articleType
  }
  else{
    articleType = productionRequest.articleType
  }

  const exactShopOrder = productionRequest.productionOrder.exactShopOrder
  const exactGlobeProductionOrder = productionRequest.productionOrder.exactGlobeProductionOrder
  let erpOrderID

  if(!exactShopOrder.isNew){
    erpOrderID = exactShopOrder.number
  }
  else if (!exactGlobeProductionOrder.isNew){
    erpOrderID = exactGlobeProductionOrder.productionOrderNumber
  }

  const batch_size = batchSize ?? batch.quantity ?? productionRequest.quantity
  const now = new Date()
  const date = `${now.getFullYear()}-${now.getMonth()}-${now.getDate()}`
  const time = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`
  const fullName = operator?.firstName + (operator?.lastName ? ' ' + operator.lastName : '')

  const instructions = (
    template
      .replace(/\{\{operator_badge_id\}\}/g, operator.badgeId ?? '-')
      .replace(/\{\{operator_name\}\}/g, fullName ?? '-')
      .replace(/\{\{serial_number\}\}/g, batch.serialNumber ?? '-')
      .replace(/\{\{erp_order_id\}\}/g, erpOrderID ?? '-')
      .replace(/\{\{quantity\}\}/g, productionRequest.quantity ?? '-')
      .replace(/\{\{batch_size\}\}/g, batch_size ?? '-')
      .replace(/\{\{date\}\}/g, date ?? '-')
      .replace(/\{\{time\}\}/g, time ?? '-')
      .replace(/\{\{article_type_code\}\}/g, articleType?.code ?? '-')
      .replace(/\{\{article_type_name\}\}/g, articleType?.name ?? '-')
      .replace(/\{\{order_id\}\}/g, productionRequest.productionOrder.id ?? '-')
      .replace(/\{\{material_plan_line_description\}\}/g, selectedMaterialPlanTask?.description ?? '-')
      .replace(/\{\{material_plan_line_progress\}\}/g, selectedMaterialPlanTask?.taskProgress ?? '-')
      .replace(/\{\{[A-z]*\}\}/g, '')
  )

  return instructions
}

/**
 * Fill in "stockLocationGlobalValue"
 *
 * @param {string} template `stock_location_print` global value
 * @param {ArticleType} articleType some article type
 * @param {string} warehouseCodes String with warehouse codes
 * @param {string} warehouseLocations String with storageLocation codes
 * @param {ProductionOrder} productionOrder some production order, or null
 * @param {string} bestBeforeDate best before date as string, or null (T46145)
 *
 * @returns {string} filled in template
 */
export function getStockLabelInstructions(template, articleType, warehouseCodes, warehouseLocations, productionOrder=null, bestBeforeDate=null) {
  const now = DateTime.local()

  let filledTemplate = template
    .replace(/\{\{article_type_code\}\}/g, articleType?.code ?? '-')
    .replace(/\{\{article_type_name\}\}/g, articleType?.name ?? '-')
    .replace(/\{\{warehouse_code\}\}/g, warehouseCodes)
    .replace(/\{\{warehouse_locations\}\}/g, warehouseLocations)
    .replace(/\{\{erp_order_id\}\}/g, productionOrder?.erpId ?? '-')
    .replace(/\{\{date\}\}/g, now.toFormat('yyyy-L-d'))
    .replace(/\{\{time\}\}/g, now.toFormat('hh:mm:ss'))
    .replace(/\{\{best_before_date\}\}/g, bestBeforeDate ?? '-')

  if (articleType === null) {
    return filledTemplate
  }

  // T46048: replace {{metafield_slug}} with articleType.metavalue of given slug
  // eslint-disable-next-line
  for (const metavalue of articleType?.metavalues?.models) {
    if (metavalue?.metafield?.slug && metavalue?.value) {
      filledTemplate = filledTemplate.replace(`{{${metavalue.metafield.slug}}}`, metavalue.value)
    }
  }

  return filledTemplate
}
