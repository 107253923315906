import Tabs from 'component/Tabs'
import Toolbar from 'component/Toolbar'
import WorkCalendar from 'component/WorkCalendar'
import WorkSchedules from 'container/Operator/WorkSchedules'
import { showSaveNotification } from 'helpers/notification'
import { t } from 'i18n'
import DefaultFloorPlan from 'image/default_floor_plan.png'
import { omit } from 'lodash'
import { action, computed, observable, reaction, toJS } from 'mobx'
import { observer } from 'mobx-react'
import { darken, opacify } from 'polished'
import PropTypes from 'prop-types'
import { Body, Content, ContentContainer, Sidebar } from 're-cy-cle'
import React, { Component } from 'react'
import Barcode from 'react-barcode'
import ReactDOM from 'react-dom'
import Measure from 'react-measure'
import { Rnd } from 'react-rnd'
import { Link, withRouter } from 'react-router-dom'
import { Form, List, Button, Icon } from 'semantic-ui-react'
import {
  COLORS,
  FormGroup,
  HeaderRight,
  LabelDot,
  RightDivider,
  SaveButton,
  stripQueryParams,
  TargetFile,
  TargetMultiPick,
  TargetSelect,
  TargetTextInput
} from '@code-yellow/spider'
import { TargetColorSelect } from 'component/semantic-ui/targets/TargetColorSelect'
import { LeaveCalendarStore } from 'store/LeaveCalendar'
import { ProductionLineVersion } from 'store/ProductionLineVersion'
import { FactoryStore } from 'store/Factory'
import styled from 'styled-components'
import ProductionLineSelectModal from './SelectModal'
import WorkStationModal from './WorkStationModal'
import PrintButtonModal from 'component/PrintButtonModal'
import getGlobalValue from 'helpers/getGlobalValue'

function clamp(value, minValue = 0, maxValue = 1) {
  return Math.min(Math.max(value, minValue), maxValue)
}

export const FullContent = styled(Content)`
  > div > main {
    padding: 0 !important;
    max-width: unset !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
`

const SmallForm = styled(Form)`
  max-width: 900px !important;
  margin: 0 auto;
`

export const StyledHeaderRight = styled(HeaderRight)`
  padding: 25px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  margin-bottom: 0 !important;
`

export const DraftBanner = styled.div`
  color: rgba(34, 36, 38, 0.45);
  font-size: 1.1rem;
  padding: 0.25rem 0.375rem;
  font-weight: bold;
  background-color: #f8f0d0;
  border-radius: 0.375rem;
  align-self: flex-start;
  margin-left: 0.5rem;
  position: relative;
  top: -0.1rem;
`

const StyledFloorPlanContainer = styled.div`
  flex: 1 1 0;
  position: relative;
  background-color: #e0e0e2;
  padding: 20px;
  overflow: hidden;
  user-select: none;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    filter: drop-shadow(0 0.1rem 0.3rem rgba(0, 0, 0, 0.1));
  }
  ${({ onMouseDown }) =>
    onMouseDown
      ? `
        cursor: crosshair;
    `
      : ''}
`

const FloorPlanImg = styled.img`
  pointer-events: none;
`

const WorkStationButtonBar = styled(Button.Group)`
  button:nth-child(1) {
    white-space: normal;
    text-align: left;
    width: 223px;
    padding-left: 1em;
  }
`

const Overlay = styled(Rnd)`
  border: 3px solid ${({ color }) => color};
  background-color: ${({ color }) => opacify(-0.5, color)};
  border-radius: 5px;
  font-weight: bold;
  color: ${({ color }) => darken(0.25, color)};
  text-align: center;
  font-size: 1.3rem;
  transition: opacity 300ms ease, border-width 300ms ease, line-height 300ms ease;
  ${({ unselected, height }) =>
    unselected
      ? `
        opacity: 0.5;
        border-width: 0;
    `
      : ''};
  ${({ dragging }) =>
    dragging
      ? `
        pointer-events: none;
    `
      : ''}
`

const OverlayContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const OverlayIcon = styled(Icon)`
  position: absolute;
  top: 8px;
  right: 3px;
  cursor: pointer;
  line-height: 1.4285em;
`

const CodesContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
`

const CodeContainer = styled.div`
  display: flex;
  align-items: center;

  border-top: 1px solid rgba(34, 36, 38, 0.15);
  padding: 0 1em;

  &:first-child {
    border-top: unset;
  }
`

class FloorPlanContainer extends Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    setPos: PropTypes.func.isRequired,
    onMouseDown: PropTypes.func,
    onMouseMove: PropTypes.func,
    onMouseUp: PropTypes.func,
    dragging: PropTypes.bool,
    children: PropTypes.node.isRequired,
  }

  constructor(...args) {
    super(...args)
    this.onMeasure = this.onMeasure.bind(this)
    this.onImgLoad = this.onImgLoad.bind(this)
    this.renderFloorPlan = this.renderFloorPlan.bind(this)
    this.onMouseDown = this.onMouseDown.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
    this.onMouseUp = this.onMouseUp.bind(this)
  }

  @observable containerSize = null
  @observable imgSize = null

  pos = { x: 0, y: 0, width: 1, height: 1 }

  onMouseEvent(e, handler) {
    if (!this.floorPlanContainer) {
      return
    }

    const rect = this.floorPlanContainer.getBoundingClientRect()

    // Dirty hack for the test runners:
    if (rect.x === undefined) {
      /*
            In the test runner, rect will be an empty object, probably due to the runner machine being headless.
            The following work-around isn't great, but will at least give some number values to x and y rather than the default value undefined.
            */
      rect.x = 100
      rect.y = 100
    }
    const x = (e.clientX - rect.x - this.pos.x) / this.pos.width
    const y = (e.clientY - rect.y - this.pos.y) / this.pos.height

    handler({ x, y })
  }

  onMouseDown(e) {
    if (e.button !== 0) {
      return
    }
    const { onMouseDown } = this.props
    this.onMouseEvent(e, onMouseDown)
  }

  onMouseMove(e) {
    const { onMouseMove } = this.props
    this.onMouseEvent(e, onMouseMove)
  }

  onMouseUp(e) {
    if (e.button !== 0) {
      return
    }
    const { onMouseUp } = this.props
    this.onMouseEvent(e, onMouseUp)
  }

  @action onMeasure({ offset: { width, height } }) {
    this.containerSize = {
      width: width - 40,
      height: height - 40,
    }
    this.setPos()
  }

  @action onImgLoad() {
    const node = ReactDOM.findDOMNode(this)
    const img = node.children[0]

    this.imgSize = {
      width: img.naturalWidth,
      height: img.naturalHeight,
    }
    this.setPos()
  }

  setPos() {
    if (this.containerSize === null || this.imgSize === null) {
      return
    }

    const { setPos } = this.props

    const pos = { x: 20, y: 20 }

    const containerRatio = this.containerSize.width / this.containerSize.height
    const imgRatio = this.imgSize.width / this.imgSize.height

    if (containerRatio > imgRatio) {
      // Horizontal padding
      pos.height = this.containerSize.height
      pos.width = pos.height * imgRatio
      pos.x += (this.containerSize.width - pos.width) / 2
    } else {
      // Vertical padding
      pos.width = this.containerSize.width
      pos.height = pos.width / imgRatio
      pos.y += (this.containerSize.height - pos.height) / 2
    }

    this.pos = pos
    setPos(pos)
  }

  floorPlanContainer = null

  renderFloorPlan({ measureRef }) {
    const { src, children, onMouseDown, onMouseMove, onMouseUp, dragging, ...props } = this.props

    delete props.setPos

    return (
      <StyledFloorPlanContainer
        data-test-floor-plan
        innerRef={(ref) => {
          measureRef(ref)
          if (ref) {
            this.floorPlanContainer = ref
          }
        }}
        onMouseDown={onMouseDown && this.onMouseDown}
        onMouseMove={dragging && onMouseMove && this.onMouseMove}
        onMouseUp={dragging && onMouseUp && this.onMouseUp}
        {...props}
      >
        <FloorPlanImg src={stripQueryParams(src)} onLoad={this.onImgLoad} onError={this.onImgLoad} />
        {children}
      </StyledFloorPlanContainer>
    )
  }

  render() {
    return (
      <Measure offset onResize={this.onMeasure}>
        {this.renderFloorPlan}
      </Measure>
    )
  }
}

@observer
export class ProductionLineMap extends Component {
  static propTypes = {
    productionLineVersion: PropTypes.object.isRequired,
    selected: PropTypes.arrayOf(PropTypes.number.isRequired),
    onSelect: PropTypes.func,
    onMouseUp: PropTypes.func,
    onMouseDown: PropTypes.func,
    onMouseMove: PropTypes.func,
    editable: PropTypes.bool,
    renderContent: PropTypes.func,
    dragging: PropTypes.bool,
  }

  static defaultProps = {
    editable: false,
    renderContent: ({ workStation, ordering }) => (
      <React.Fragment>
        {workStation.name}
        {workStation.productionStrategy !== 'together' && ` ${ordering + 1}`}
      </React.Fragment>
    ),
    dragging: false,
  }

  constructor(...args) {
    super(...args)
    this.setPos = this.setPos.bind(this)
    this.renderOverlay = this.renderOverlay.bind(this)
  }

  @observable pos = { x: 0, y: 0, width: 1, height: 1 }

  setPos(pos) {
    this.pos = pos
  }

  @computed get overlays() {
    const { productionLineVersion, selected } = this.props

    const res = []
    // eslint-disable-next-line
    for (const workStation of productionLineVersion.workStations.models) {
      let i = 0
      // eslint-disable-next-line
      for (const { x, y, width, height, ordering } of workStation.locations.models) {
        res.push({
          x,
          y,
          width,
          height,
          ordering,
          color: COLORS[workStation.color],
          unselected: selected && !selected.includes(workStation.id),
          workStation,
          index: i++,
        })
      }
    }
    return res
  }

  @observable resizeStartSize = null

  renderOverlay(overlay, i) {
    const { editable, renderContent, dragging, onSelect } = this.props

    const { x, y, width, height, children, workStation, index, ...props } = overlay

    return (
      <Overlay
        data-test-rectangle
        dragging={dragging}
        key={`${workStation.cid}_${index}`}
        position={{
          x: Math.round(this.pos.x + this.pos.width * x),
          y: Math.round(this.pos.y + this.pos.height * y),
        }}
        size={{
          width: Math.round(this.pos.width * width),
          height: Math.round(this.pos.height * height),
        }}
        disableDragging={!editable}
        enableResizing={{
          bottom: editable,
          bottomLeft: editable,
          bottomRight: editable,
          left: editable,
          right: editable,
          top: editable,
          topLeft: editable,
          topRight: editable,
        }}
        onDrag={action((e, pos) => {
          const location = workStation.locations.at(index)
          location.setInput('x', clamp((pos.x - this.pos.x) / this.pos.width, 0, 1 - width))
          location.setInput('y', clamp((pos.y - this.pos.y) / this.pos.height, 0, 1 - height))
        })}
        onResizeStart={() => (this.resizeStartSize = { width, height })}
        onResize={action((e, dir, ref, delta, pos) => {
          const location = workStation.locations.at(index)
          location.setInput('x', clamp((pos.x - this.pos.x) / this.pos.width))
          location.setInput('y', clamp((pos.y - this.pos.y) / this.pos.height))
          location.setInput(
            'width',
            clamp((pos.x + delta.width - this.pos.x) / this.pos.width + this.resizeStartSize.width) - location.x
          )
          location.setInput(
            'height',
            clamp((pos.y + delta.height - this.pos.y) / this.pos.height + this.resizeStartSize.height) - location.y
          )
        })}
        onResizeStop={() => (this.resizeStartSize = null)}
        onMouseDown={(e) => e.stopPropagation()}
        onClick={onSelect && (() => onSelect(overlay))}
        {...props}
      >
        <OverlayContent>
          {renderContent(overlay)}
          {editable && (
            <OverlayIcon
              size="small"
              name="delete"
              onClick={action(() => {
                workStation.locations.remove(workStation.locations.at(index))
              })}
            />
          )}
        </OverlayContent>
      </Overlay>
    )
  }

  render() {
    const { productionLineVersion, onMouseDown, onMouseMove, onMouseUp, dragging } = this.props

    return (
      <FloorPlanContainer
        src={productionLineVersion.floorPlan || DefaultFloorPlan}
        setPos={this.setPos}
        dragging={dragging}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
      >
        {this.overlays.map(this.renderOverlay)}
      </FloorPlanContainer>
    )
  }
}

@withRouter
@observer
export default class ProductionLineEdit extends Component {
  static propTypes = {
    productionLine: PropTypes.object.isRequired,
    version: PropTypes.number,
    afterSave: PropTypes.func.isRequired,
    baseUrl: PropTypes.string.isRequired,
    view: PropTypes.string.isRequired,
    viewStore: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  }

  /** The workstation that is selected for drawing on the map. */
  @observable selectedWorkStation = null

  constructor(...args) {
    super(...args)
    this.save = this.save.bind(this)
    this.onCopy = this.onCopy.bind(this)
    this.finalize = this.finalize.bind(this)
    this.nextVersion = this.nextVersion.bind(this)
    this.renderWorkStation = this.renderWorkStation.bind(this)
    this.addWorkStation = this.addWorkStation.bind(this)
    this.onMapMouseDown = this.onMapMouseDown.bind(this)
    this.onMapMouseMove = this.onMapMouseMove.bind(this)
    this.onMouseUp = this.onMouseUp.bind(this)
    this.renderMap = this.renderMap.bind(this)
    this.renderCodes = this.renderCodes.bind(this)
    this.renderAvailability = this.renderAvailability.bind(this)
    this.renderWorkStationCode = this.renderWorkStationCode.bind(this)
  }

  locationOrderingReactions = {}

  setLocationOrderingReactions() {
    const newReactions = {}

    // eslint-disable-next-line
    for (const workStation of this.version.workStations.models) {
      let newReaction = this.locationOrderingReactions[workStation.cid]
      delete this.locationOrderingReactions[workStation.cid]

      if (newReaction === undefined) {
        newReaction = reaction(
          () => workStation.locations.map(({ cid }) => cid).join(','),
          action(() => {
            let i = 0
            // eslint-disable-next-line
            for (const location of workStation.locations.models) {
              location.setInput('ordering', i++)
            }
            if (workStation.locations.length <= 1) {
              if (workStation.productionStrategy !== 'together') {
                workStation.setInput('productionStrategy', 'together')
              }
              if (workStation.maintenanceStrategy !== 'together') {
                workStation.setInput('maintenanceStrategy', 'together')
              }
            }
          })
        )
      }

      newReactions[workStation.cid] = newReaction
    }

    // eslint-disable-next-line
    for (const oldReaction of Object.values(this.locationOrderingReactions)) {
      oldReaction()
    }

    this.locationOrderingReactions = newReactions
  }

  componentDidMount() {
    this.versionReaction = reaction(
      () => this.version,
      (version) => {
        if (this.setOrderingReaction) {
          this.setOrderingReaction()
        }

        this.setOrderingReaction = reaction(
          () => version.workStations.map(({ cid }) => cid).join(','),
          action(() => {
            let i = 0
            // eslint-disable-next-line
            for (const workStation of version.workStations.models) {
              workStation.setInput('ordering', i++)
            }
            this.setLocationOrderingReactions()
          })
        )
        this.setLocationOrderingReactions()
      },
      { fireImmediately: true }
    )
    document.addEventListener('mouseup', this.onMouseUp)
    document.addEventListener('keydown', this.onKeyPress, true); // handle during capturing phase
  }

  componentWillUnmount() {
    this.versionReaction()
    this.setOrderingReaction()
    document.removeEventListener('mouseup', this.onMouseUp)
  }

  /** Toggle the workstation for drawing. */
  toggleWorkstation = action((workStation) => {
    if (this.selectedWorkStation === workStation) {
      this.selectedWorkStation = null
    } else {
      this.selectedWorkStation = workStation
      const { history, match, baseUrl } = this.props
      if (match.url.startsWith(baseUrl) && match.url.slice(baseUrl.length) !== '/map') {
        history.push(baseUrl + '/map') // switch to map tab if not already there
      }
    }
  })

  /** React on global key presses. */
  onKeyPress = action((event) => {
    if(event.key === 'Escape'){
      // if a workstation is active for drawing, we deselect it
      this.selectedWorkStation = null
    }
  })

  @action save() {
    const { productionLine, afterSave } = this.props

    if (productionLine.isNew) {
      // Extract the versions
      const versions = productionLine.versions
      const index = productionLine.__activeCurrentRelations.indexOf('versions')
      if (index !== -1) {
        productionLine.__activeCurrentRelations.splice(index, 1)
      }
      delete productionLine.versions
      // Save without versions
      const promise = productionLine.save({
        onlyChanges: true,
        params: { with: 'versions' },
        mapData: (data) => omit(data, 'versions'),
      })
      // Inject veriosns
      if (index !== -1) {
        productionLine.__activeCurrentRelations.splice(index, 0, 'versions')
        productionLine.versions = versions
      }

      return promise
        .then(
          action((res) => {
            this.version.id = res.data._meta.with.production_line_version[0].id
          })
        )
        .then(this.save)
    }

    return productionLine
      .save({
        relations: ['versions.workStations.locations', 'versions.workSchedules.workSlots', 'leaveSlots'],
        onlyChanges: true,
        mapData: (data) => omit(data, 'versions'),
      })
      .then(showSaveNotification)
      .then(afterSave)
      .then(() => productionLine.versions.clearSetChanges())
  }

  @action onCopy(version) {
    this.version.workStations.clear()
    this.version.workStations.add(
      version.workStations.map((workStationModel) => {
        const { locations, ...workStation } = workStationModel.toJS()
        return {
          ...omit(workStation, 'id', 'code'),
          locations: locations.map((location) => omit(location, 'id', 'code')),
        }
      })
    )

    if (version.floorPlan) {
      fetch(version.floorPlan)
        .then((res) => res.blob())
        .then((blob) => {
          // So apparently Blob.preview is a react-dropzone only
          // thing but mobx-spine expects it, so we fake it
          blob.preview = URL.createObjectURL(blob)
          this.version.setInput('floorPlan', blob)
        })
    } else {
      this.version.setInput('floorPlan', null)
    }
  }

  finalize() {
    const { productionLine, afterSave, viewStore } = this.props

    function showValidationError(msg) {
      viewStore.showNotification({
        key: 'requestError', error: true, message: msg
      });
    }

    return this.version
      .finalize()
      .catch((err) => {
        // show notifications if there are validation errors
        if (err.response) {
          const valErrors = this.version.api.parseBackendValidationErrors(err.response)
          if (valErrors) {
            this.version.parseValidationErrors(valErrors)
            const errors = toJS(this.version.actuallyUsefulErrors)

            if ('workStations' in errors && errors.workStations[0].code === 'required') {
              showValidationError(t('productionLineVersion.field.workStations.error.missing'))
            }
            if ('workSchedules' in errors && errors.workSchedules[0].code === 'required') {
              showValidationError(t('productionLine.field.workSchedules.errors.missing'))
            }
          }
        }
        throw err
      })
      .then(() => productionLine.fetch())
      .then(afterSave)
  }

  nextVersion() {
    const { productionLine, afterSave } = this.props
    return this.version
      .nextVersion()
      .then(() => productionLine.fetch())
      .then(afterSave)
  }

  @computed get version() {
    const { version, productionLine } = this.props
    return (
      (version === null
        ? productionLine.versions.at(productionLine.versions.length - 1)
        : productionLine.versions.models.find((v) => v.version === version)) ||
      new ProductionLineVersion({ version: 1, draft: true }, { relations: productionLine.versions.__activeRelations })
    )
  }

  @computed get lastVersion() {
    const { productionLine } = this.props
    return (productionLine.versions.at(productionLine.versions.length - 1) || { version: 1 }).version
  }

  @observable dragging = null
  @observable dragStart = null

  @action onMapMouseDown({ x, y }) {
    if (this.selectedWorkStation) {
      x = clamp(x)
      y = clamp(y)
      this.dragStart = { x, y }
      this.dragging = this.selectedWorkStation.locations.add({ x, y, width: 0, height: 0 })
    }
  }

  @action onMapMouseMove({ x, y }) {
    if (!this.dragging) {
      return
    }

    x = clamp(x)
    y = clamp(y)

    this.dragging.setInput('x', Math.min(x, this.dragStart.x))
    this.dragging.setInput('width', Math.max(x, this.dragStart.x) - this.dragging.x)
    this.dragging.setInput('y', Math.min(y, this.dragStart.y))
    this.dragging.setInput('height', Math.max(y, this.dragStart.y) - this.dragging.y)
  }

  @action onMouseUp(e) {
    if (e.button !== 0 || !this.dragging) {
      return
    }
    if (this.dragging.width < 0.05 && this.dragging.height < 0.05) {
      this.selectedWorkStation.locations.remove(this.dragging)
    }
    this.dragStart = null
    this.dragging = null
  }

  renderWorkStation(workStation, i) {
    // disabled when we are not the active workStation or when this production line is not a draft
    var disabled = (this.selectedWorkStation && this.selectedWorkStation !== workStation) || !this.version.draft
    const buttonColor = workStation.hasErrors ? 'red' : ''

    return (
      <List.Item key={i}
        greyed={disabled}
        data-test-work-station={workStation.id || workStation.cid}
      >
        <WorkStationButtonBar>
          <WorkStationModal
            closeIcon
            workStation={workStation}
            trigger={
              <Button disabled={disabled} color={buttonColor}
                data-test-work-station-button={workStation.id || workStation.cid}
              >
                <React.Fragment>
                  <LabelDot color={workStation.color} />
                  {workStation.name || (
                    <i>unnamed</i>
                  )}
                </React.Fragment>
              </Button>
            }
            disabled={disabled}
            defaultOpen={workStation._adding}
            onClose={action((e) => {
              workStation._adding = false // don't open automatically again
            })}
          />
          <Button
            icon="paint brush"
            active={workStation === this.selectedWorkStation}
            onClick={(e) => this.toggleWorkstation(workStation)}
            disabled={disabled}
          />
          <Button
            icon="delete"
            onClick={() => this.version.workStations.remove(workStation)}
            disabled={disabled}
          />
        </WorkStationButtonBar>
      </List.Item>
    )
  }

  /** Start creating a new workstation in a modal. */
  addWorkStation() {
    // get a new default color
    const count = this.version.workStations.length
    var color = ''
    if (count === 0) {
      // just pick first color for first work station
      color = Object.keys(COLORS)[0]
    } else {
      // get color based on color of present work stations
      // just get next color in COLORS object
      const current = this.version.workStations.models[count - 1].color
      const index = (Object.keys(COLORS).indexOf(current) + 1) % Object.keys(COLORS).length
      color = Object.keys(COLORS)[index]
    }

    var ws = this.version.workStations.add({})
    ws._adding = true // ensures that the edit modal opens automatically
    // set new default color
    ws.color = color
  }

  renderMap() {
    return (
      <div style={{ display: 'flex', height: '100%' }}>
        <ProductionLineMap
          editable={this.version.draft}
          productionLineVersion={this.version}
          dragging={!!this.dragging}
          onMouseDown={this.selectedWorkStation && this.onMapMouseDown}
          onMouseMove={this.selectedWorkStation && this.onMapMouseMove}
        />
      </div>
    )
  }

  getInstructions = async (name, code) => {
    const template = await getGlobalValue('workstation_code_print')
    const instructions = (
      template
        .replace(/\{\{workstation_name\}\}/g, name)
        .replace(/\{\{workstation_code\}\}/g, code)
    )

    return instructions
  }

  renderWorkStationCode(workStation) {
    const codes =
      workStation.productionStrategy === 'together'
        ? [
            {
              key: workStation.cid,
              name: workStation.name,
              code: workStation.code,
            },
          ]
        : workStation.locations.map((location) => ({
            key: `${workStation.cid}-${location.cid}`,
            name: `${workStation.name} ${location.ordering + 1}`,
            code: location.code,
          }))

    return codes.map(({ key, name, code }) => (
      <CodeContainer key={key}>
        <span>
          <LabelDot color={workStation.color} />
          {name}
        </span>
        <div style={{ flex: '1 1 0' }} />
        <Barcode value={code} width={1.5} height={30} fontSize={10} />
        <PrintButtonModal
          key={key}
          title={t('login.workstation')}
          getInstructions={() => this.getInstructions(name, code)}
          primary={false}
          style={{ margin: '0 0 0 1rem !important' }}
        />
      </CodeContainer>
    ))
  }

  renderCodes() {
    return <CodesContainer>{this.version.workStations.map(this.renderWorkStationCode)}</CodesContainer>
  }

  leaveCalendarStore = new LeaveCalendarStore()

  renderAvailability() {
    const { productionLine } = this.props

    if (!productionLine._latestVersion) {
      return null
    }

    return (
      <SmallForm>
        <FormGroup
          bgColor="#FFF"
          label={t('productionLine.edit.baseFields.label')}
          info={t('productionLine.edit.baseFields.info')}
        >
          <WorkCalendar operator={productionLine} />
          <WorkSchedules
            label={t('productionLine.field.workSchedules.label')}
            workSchedules={productionLine._latestVersion.workSchedules}
          />
          <TargetMultiPick
            remote
            target={productionLine}
            name="leaveCalendars"
            store={this.leaveCalendarStore}
            toOption={(leaveCalendar) => ({
              value: leaveCalendar.id,
              text: leaveCalendar.name,
            })}
            searchKey=".name:icontains"
          />
        </FormGroup>
      </SmallForm>
    )
  }

  factoryStore = new FactoryStore()

  render() {
    const { productionLine, view, baseUrl } = this.props

    // check if there are validation errors
    const errors = toJS(this.version.backendValidationErrors)
    var work_schedule_error = 'workSchedules' in errors && errors.workSchedules[0].includes('have a work schedule')
    if (this.version.workSchedules && this.version.workSchedules.length > 0) {
      // reset error when the user adds a schedule
      work_schedule_error = false
    }

    // use this to disable global settings when drawing a work station
    var drawing = this.selectedWorkStation != null

    return (
      <Body>
        <ContentContainer>
          <Sidebar>
            <Form>
              <FormGroup
                label={t('productionLine.edit.baseFields.label')}
                info={t('productionLine.edit.baseFields.info')}
              >
                <TargetTextInput target={productionLine} name="name" disabled={drawing} />
                <TargetSelect remote
                  target={productionLine}
                  name="factory"
                  store={this.factoryStore}
                  toOption={(factory) => ({
                    value: factory.id,
                    text: factory.name,
                  })}
                  searchKey=".name:icontains"
                  disabled={drawing}
                />
                <TargetColorSelect target={productionLine} name="color" disabled={drawing} />
              </FormGroup>
              <TargetFile
                noPreview
                accept="image/jpeg, image/png"
                target={this.version}
                name="floorPlan"
                disabled={!this.version.draft || drawing}
              />
              <Form.Field data-test-workstations>
                <label>{t('productionLineVersion.field.workStations.label')}</label>
                <List>
                  {this.version.workStations.map(this.renderWorkStation)}
                </List>
                {this.version.draft && (
                  <Button
                    primary
                    fluid
                    data-test-add-workstation-button
                    labelPosition="left"
                    icon="add"
                    content={t('productionLineVersion.field.workStations.addButton')}
                    onClick={this.addWorkStation}
                    disabled={drawing}
                  />
                )}
              </Form.Field>
            </Form>
          </Sidebar>
          <Tabs
            maxWidth="100%"
            header={productionLine.id ? t('productionLine.edit.title') : t('productionLine.create.title')}
            afterHeader={this.version.draft && <DraftBanner>{t('productionLine.edit.draft')}</DraftBanner>}
            baseUrl={`${baseUrl}/`}
            defaultTab="map"
            tabs={[
              {
                key: 'map',
                label: t('productionLine.edit.tabs.map'),
                icon: 'map',
                render: this.renderMap,
                noPadding: true,
                bgColor: '#E0E0E2',
              },
              {
                key: 'codes',
                label: t('productionLine.edit.tabs.codes'),
                icon: 'barcode',
                render: this.renderCodes,
                'data-test-codes-tab': true,
              },
              {
                key: 'availability',
                label: t('productionLine.edit.tabs.availability'),
                icon: 'calendar',
                render: this.renderAvailability,
                error: work_schedule_error,
              },
            ]}
          />
        </ContentContainer>
        <Toolbar>
          <ProductionLineSelectModal
            trigger={
              <Button
                compact
                primary
                icon="copy"
                labelPosition="left"
                content={t('form.copyButton')}
                disabled={!this.version.draft}
              />
            }
            onSelect={this.onCopy}
          />
          <RightDivider />
          <Button.Group compact>
            <Button
              icon="chevron left"
              disabled={productionLine.isLoading || this.version.version === 1}
              as={Link}
              to={`/assets/production-line/${productionLine.id}/version/${this.version.version - 1}/edit/${view}`}
            />
            <Button
              loading={productionLine.isLoading}
              content={t('productionLine.edit.version', { version: this.version.version })}
            />
            <Button
              icon="chevron right"
              disabled={productionLine.isLoading || this.version.latest}
              as={Link}
              to={
                this.version.version + 1 === productionLine.latestVersion
                  ? `/assets/production-line/${productionLine.id}/edit/${view}`
                  : `/assets/production-line/${productionLine.id}/version/${this.version.version + 1}/edit/${view}`
              }
            />
          </Button.Group>
          {this.version.draft && (
            <Button
              primary
              compact
              data-test-finalize-button
              loading={productionLine.isLoading}
              disabled={productionLine.hasUserChanges}
              onClick={this.finalize}
              icon="check"
              labelPosition="left"
              content={t('productionLine.edit.finalizeButton')}
            />
          )}
          {this.version.version === this.lastVersion && !this.version.draft && (
            <Button
              primary
              compact
              data-test-next-version-button
              loading={productionLine.isLoading}
              disabled={productionLine.hasUserChanges}
              onClick={this.nextVersion}
              icon="add"
              labelPosition="left"
              content={t('productionLine.edit.nextVersionButton')}
            />
          )}
          <SaveButton primary compact loading={productionLine.isLoading} onClick={this.save} />
        </Toolbar>
      </Body>
    )
  }
}
