import { Component } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { Step } from 'store/Step'

@observer
export default class SubprocessesStepEdit extends Component {
  static propTypes = {
    step: PropTypes.instanceOf(Step).isRequired,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    disabled: false,
  }

  render() {
    return null
  }
}
