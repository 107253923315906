import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { Button, Form, Modal, Icon } from 'semantic-ui-react'
import { Operator, OperatorStore } from 'store/Operator'
import { CapabilityStore } from 'store/Capability'
import { RightDivider, TargetTextInput } from '@code-yellow/spider'
import { modalColors } from 'styles'

@observer
export default class OperatorModal extends Component {
  static propTypes = {
    onSelect: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    capabilities: PropTypes.instanceOf(CapabilityStore),
    scannedOperator: PropTypes.instanceOf(Operator),
  }

  constructor(...args) {
    super(...args)

    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)

    const { scannedOperator } = this.props
    if (scannedOperator) {
      this.onSelect(scannedOperator)
    } else {
      this.autoSelectedOperator = false
    }
  }

  @observable value = ''
  @observable error = null
  @observable autoSelectedOperator = true

  onChange(value) {
    this.value = value
  }

  onSelect(operator) {
    const { capabilities, onSelect } = this.props

    const missing = (capabilities || []).filter(
      (c) => !operator.operatorCapabilities.models.some((oc) => oc.capability.id === c.id)
    )

    if (missing.length > 0) {
      this.error = t(
        missing.length === 1
          ? 'workStation.production.operatorModal.error.missingCapabilities'
          : 'workStation.production.operatorModal.error.missingCapabilities_plural',
        { capabilities: missing.map((c) => c.name).join(', ') }
      )
      this.value = ''
      this.autoSelectedOperator = false
    } else {
      onSelect(operator)
    }
  }

  /**
   * Execute extra special callback if defined on `window`. This is specifically added to test if this modal is shown.
   * In some cases, the modal was shown very shortly, which was annoying for the user. See T45529.
   */
  cypressTestHookShowModal = () => {
    window.cypressTestHookOperatorModalShowModal?.()
  }

  onSubmit() {
    const store = new OperatorStore({ relations: ['operatorCapabilities.capability'] })
    store.params['.badge_id'] = this.value

    this.error = null
    store
      .fetch()
      .then(
        action(() => {
          if (store.length === 0) {
            this.error = t('workStation.production.operatorModal.error.noResults')
            this.value = ''
          } else if (store.length === 1) {
            const operator = store.at(0)
            this.onSelect(operator)
          } else {
            this.error = t('workStation.production.operatorModal.error.multipleResults')
            this.value = ''
          }
        })
      )
      .catch(
        action(() => {
          this.error = t('workStation.production.operatorModal.error.unexpected')
          this.value = ''
        })
      )
  }

  render() {
    const { onClose } = this.props

    return (
      !this.autoSelectedOperator &&
      <Modal open closeIcon data-test-operator-modal
        closeOnDimmerClick={false}
        onMount={this.cypressTestHookShowModal}
        onClose={onClose}
        size="tiny"
      >
        <Modal.Header style={{ backgroundColor:modalColors.skyBlue }}>{t('workStation.production.operatorModal.title')}</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.onSubmit} style={{ textAlign: 'center' }}>
            <TargetTextInput
              autoFocus
              label={t('operator.field.badgeId.label')}
              placeholder={t('operator.field.badgeId.badgeIdPlaceHolder')}
              name="badgeId"
              onChange={this.onChange}
              errors={this.error === null ? [] : [this.error]}
            />
            <Icon
              name='id badge'
              size='massive'
              color='grey'
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <RightDivider />
          <Button
            primary
            data-test-confirm-button
            icon="check"
            labelPosition="left"
            content={t('workStation.production.operatorModal.confirmButton')}
            onClick={this.onSubmit}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}
