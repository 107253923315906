import { Table } from 'semantic-ui-react';
import React, { Component } from 'react';
import { ScaleTime } from 'd3-scale';
import { observer } from 'mobx-react';
import { ProcessData } from './Interface';
import { FullHeightSvg } from './Components';
import { TimingIndications } from './TimingIndications';
import StepTimeBar from './StepTimeBar';

interface StepRowProps {
    scaleX: ScaleTime<number, number>;
    processData: ProcessData;
}

const PROGRESS_BAR_HEIGHT = 15;
const ROW_HEIGHT = 50;
const LEGEND_HEIGHT = 20;



@observer
export default class ProcessRow extends Component<StepRowProps> {


    render() {
        const { scaleX, processData } = this.props;

        const width = scaleX.range()[1] - scaleX.range()[0];
        const plannedStartX = scaleX(processData.plannedStartAt);
        const plannedEndx = scaleX(processData.plannedEndAt);

        return (
            <Table.HeaderCell>
                <FullHeightSvg width={width} height={63} viewBox={`0 0 1000 ${ROW_HEIGHT+LEGEND_HEIGHT}`}>
                    <rect
                        x={plannedStartX}
                        y={LEGEND_HEIGHT}
                        width={plannedEndx - plannedStartX}
                        height={50}
                        fill={'#BDBDBD'}
                    />

                    {processData.actualStartAt && (
                        <StepTimeBar
                            actualEnd={processData.actualEndAt}
                            actualStart={processData.actualStartAt}
                            plannedEnd={processData.plannedEndAt}
                            scaleX={scaleX}
                            rowHeight={ROW_HEIGHT}
                            barHeight={PROGRESS_BAR_HEIGHT}
                            yOffset={LEGEND_HEIGHT}
                        />
                    )}

                    <TimingIndications
                        scaleX={scaleX}
                        showLegend
                    />

                </FullHeightSvg>
            </Table.HeaderCell>
        )
    }

}
