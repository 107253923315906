import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { BatchType, BatchTypeStore, LOCAL_TYPES } from 'store/BatchType';
import { ProcessVersion } from 'store/ProcessVersion';
import { action, observable, reaction, runInAction } from 'mobx';
import { FactoryStore } from 'store/Factory';
import { ApplyButton, IconButton, RightDivider, TargetCheckbox, TargetSelect, TargetTextInput } from '@code-yellow/spider';
import { Form, Icon, Modal, Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { theme } from 'styles';
import { mix } from 'polished';

const BatchTypeContainer = styled.div`
  margin: 0 -25px 0.5em;
  padding: 0.75em 25px 0.25em 19px;
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
  .field > label {
    font-size: 0.8em !important;
    color: rgba(0, 0, 0, 0.5) !important;
    margin: -0.5em 0 0 !important;
  }
  > .field {
    margin-bottom: 0.5em !important;
  }
  > .field:last_child {
    margin-bottom: 0 !important;
  }
  border-left: 6px solid ${({ error }) => mix(error ? 0.1 : 0, '#db2828', '#c0c1c2')};
  background-color: ${({ error }) => mix(error ? 0.1 : 0, '#db2828', '#e0e1e2')};
  ${({ active, error }) =>
    active &&
    `
        border-left-color: ${error ? '#db2828' : theme.primaryColor};
        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: calc(50% - 0.75em);
            width: 0;
            height: 0;
            border-top: 0.75em solid transparent;
            border-right: 0.75em solid #fff;
            border-bottom: 0.75em solid transparent;
            z-index: 1;
        }
    `}
`

const BatchTypeDescriptionGroup = styled(Form.Group)`
  margin: 0 -0.25em !important;
  > .field {
    padding: 0 0.25em !important;
    margin: 0 !important;
    > .input > input {
      padding: 0 !important;
      border: unset !important;
      background-color: ${({ active }) => (active ? '#fff' : 'transparent')} !important;
      font-weight: bold;
      font-size: 1.1em !important;
      margin-bottom: 0.5em !important;
    }
  }
  > i.icon {
    color: rgba(0, 0, 0, 0.33);
    margin: 4px !important;
    line-height: 1;
    &:hover {
      color: rgba(0, 0, 0, 0.67);
    }
    transition: color 300ms ease;
  }
`

@observer
class BatchTypeSourceSelect extends Component {
  static propTypes = {
    batchType: PropTypes.instanceOf(BatchType).isRequired,
  }

  sourceStore = new BatchTypeStore()

  constructor(...args) {
    super(...args)

    const { batchType } = this.props
    this.sourceStore.params['.type'] = batchType.type + '_template'
  }

  componentDidMount() {
    this.typeReaction = reaction(
      () => this.props.batchType.type,
      action((type) => {
        this.sourceStore.clear()
        this.sourceStore.params['.type'] = type + '_template'
        this.sourceStore.fetch()
      }),
    )
  }

  componentWillUnmount() {
    this.typeReaction()
  }

  render() {
    const { batchType, ...props } = this.props
    return (
      <TargetSelect remote clearable
        target={batchType}
        name="source"
        store={this.sourceStore}
        toOption={(batchType) => ({
          value: batchType.id,
          text: batchType.description,
        })}
        {...props}
      />
    )
  }
}



@observer
export default class BatchTypesTable extends Component {
  static propTypes = {
    batchTypeStore: PropTypes.instanceOf(BatchTypeStore).isRequired,
    selectedBatchType: PropTypes.instanceOf(BatchType),
    onSelect: PropTypes.func.isRequired,
    types: PropTypes.array.isRequired,
    selectedVersion: PropTypes.instanceOf(ProcessVersion).isRequired,
  }

  @observable bomFieldsModal = null

  factoryStore = new FactoryStore()

  render() {
    const { types, batchTypeStore, selectedBatchType, selectedVersion, onSelect } = this.props

    return (
      <React.Fragment>
        {batchTypeStore
          .filter((batchType) => LOCAL_TYPES.includes(batchType.type) && !batchType.deleted)
          .map((batchType, i) => (
            <React.Fragment key={batchType.cid}>
              <BatchTypeContainer
                error={batchType.hasErrors}
                active={batchType === selectedBatchType}
                data-test-batch-type={batchType.id || batchType.cid}
                data-test-error={batchType.hasErrors}
              >
                <BatchTypeDescriptionGroup active={batchType === selectedBatchType} widths="equal">
                  <TargetTextInput
                    noLabel
                    fluid
                    target={batchType}
                    name="description"
                    mapErrors={(errors) => [...errors, ...(batchType.backendValidationErrors.__all__ || [])]}
                    placeholder={t('batchType.field.description.label')}
                    onFocus={batchType === selectedBatchType ? undefined : () => onSelect(batchType)}
                  />
                  {batchType.default && (
                    <Popup
                      position="top center"
                      trigger={<Icon name="star" />}
                      content={t('batchType.field.default.label')}
                    />
                  )}
                  <IconButton
                    data-test-edit-batch-type
                    name="edit"
                    active={batchType === selectedBatchType}
                    onClick={() => onSelect(batchType === selectedBatchType ? null : batchType)}
                  />
                  <IconButton
                    data-test-delete-batch-type
                    name="trash"
                    onClick={() => batchTypeStore.remove(batchType)}
                  />
                </BatchTypeDescriptionGroup>
                {batchType === selectedBatchType && (
                  <>
                    <TargetSelect fluid
                      target={batchType}
                      name="type"
                      disabled={selectedVersion.finalized || selectedVersion.version > 1}
                      options={types.map((type) => ({
                        value: type,
                        text: t(`batchType.field.type.value.${type}`),
                      }))}
                      onChange={async (type) => {
                        const bomFields = []
                        if (batchType.type === 'make' && type !== 'make') {
                          // eslint-disable-next-line
                          for (const step of selectedVersion.steps.models) {
                            // eslint-disable-next-line
                            for (const form of [step.formStep.form, step.splitStep.form]) {
                              // eslint-disable-next-line
                              for (const field of form.fields.models) {
                                if (field.type === 'bom') {
                                  bomFields.push({ form, field })
                                }
                              }
                            }
                          }
                        }

                        if (bomFields.length > 0) {
                          await new Promise((resolve, reject) => this.bomFieldsModal = { resolve, reject })
                        }

                        runInAction(() => {
                          batchType.setInput('type', type)
                          // eslint-disable-next-line
                          for (const { form, field } of bomFields) {
                            form.fields.remove(field)
                          }
                        })
                      }}
                      data-test-batch-type-select
                    />
                    <TargetCheckbox
                      target={batchType}
                      name="default"
                      onChange={action((value) => {
                        if (value) {
                          // eslint-disable-next-line
                          for (const batchType_ of batchTypeStore.models) {
                            if (batchType_.type === batchType.type) {
                              batchType_.setInput('default', batchType_ === batchType)
                            }
                          }
                        } else {
                          batchType.setInput('default', false)
                        }
                      })}
                    />
                    <BatchTypeSourceSelect fluid batchType={batchType} />
                    {batchType.type !== 'on_the_fly' && <TargetSelect remote
                      target={selectedVersion}
                      name="factory"
                      store={this.factoryStore}
                      toOption={(factory) => ({
                        value: factory.id,
                        text: factory.name,
                      })}
                      searchKey=".name:icontains"
                      disabled={selectedVersion.finalized || selectedVersion.version > 1}
                    />}
                  </>
                )}
              </BatchTypeContainer>
            </React.Fragment>
          ))}
        <Modal closeIcon
          closeOnDimmerClick={false}
          size="small"
          open={this.bomFieldsModal !== null}
          onClose={() => {
            const { reject } = this.bomFieldsModal
            this.bomFieldsModal = null
            reject()
          }}
        >
          <Modal.Header>{t('articleType.edit.bomFieldsModal.title')}</Modal.Header>
          <Modal.Content>{t('articleType.edit.bomFieldsModal.content')}</Modal.Content>
          <Modal.Actions>
            <RightDivider />
            <ApplyButton compact primary onClick={() => {
              const { resolve } = this.bomFieldsModal
              this.bomFieldsModal = null
              resolve()
            }} />
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    )
  }
}
