import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { OutShipmentLine } from './OutShipmentLine';
import { CarrierRequestRowStore } from './CarrierRequestRow';

export class CarrierRequestRowPickOrderLink extends Model {
  static backendResourceName = 'carrier_request_row_pick_order_link'

  @observable amount = null;

  relations() {
    return {
      carrierRequestRow: CarrierRequestRowStore,
      pickOrderLine: OutShipmentLine,
    }
  }
}

export class CarrierRequestRowPickOrderLinkStore extends Store {
  Model = CarrierRequestRowPickOrderLink
  static backendResourceName = 'carrier_request_row_pick_order_link'
}
