import { observable, computed } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { ExactGlobeSalesOrderLineStore } from './SalesOrderLine'

export class ExactGlobeProductionOrder extends Model {
  static backendResourceName = 'exact_globe_production_order'
  static idColor = 'red'
  static idIcon = 'exact'

  getLink(props = {}) {
    return super.getLink({
      target: '_blank',
      ...props,
    })
  }

  @observable id = null
  @observable productionOrderNumber = ''
  @observable quantity = 0
  @observable startDate = null
  @observable description = ''

  // Annotations
  @observable status = 'Open'
  @observable producedQuantity = 0

  @computed
  get _labelContent() {
    return this.productionOrderNumber
  }

  relations() {
    return {
      exactGlobeSalesOrderLines: ExactGlobeSalesOrderLineStore,
    }
  }

  casts() {
    return {
      startDate: Casts.date,
    }
  }
}

export class ExactGlobeProductionOrderStore extends Store {
  static backendResourceName = 'exact_globe_production_order'
  Model = ExactGlobeProductionOrder
}
