import { observer } from 'mobx-react';
import React, { Component } from 'react';
import moment, { Moment } from 'moment';
import { ScaleTime } from 'd3-scale';

export const COLOR_TOO_LATE = '#EF5350';
// export const COLOR_DELAY = '#FFA726';

export const COLOR_ON_TIME = '#66BB6A';

export const COLOR_END = '#212121';
export const WIDTH_END_INDICATION = 5;

interface StepTimeBarProps {

    scaleX: ScaleTime<number, number>;

    actualStart: Moment;

    actualEnd: Moment | null;

    plannedEnd: Moment;

    rowHeight: number;
    barHeight: number;

    yOffset?: number;
}

@observer
export default class StepTimeBar extends Component<StepTimeBarProps> {


    get color(): string {
        const { actualEnd, plannedEnd } = this.props;

        const endTimeToCalculateWith = actualEnd ?? moment();

        if (endTimeToCalculateWith > plannedEnd) {
            return COLOR_TOO_LATE;
        }

        return COLOR_ON_TIME;
    }

    render() {

        const { scaleX, actualStart, actualEnd, rowHeight, barHeight, yOffset } = this.props;

        const xStart = scaleX(actualStart);

        const xEnd = scaleX(actualEnd ?? moment());

        const actualYOffset = yOffset ? yOffset : 0


        return (
            <>
                <rect
                    x={xStart}
                    y={(rowHeight - barHeight) / 2 + actualYOffset}
                    width={xEnd - xStart}
                    height={barHeight}
                    fill={this.color}
                />
                <rect
                    x={xEnd - WIDTH_END_INDICATION}
                    y={(rowHeight - barHeight) / 2 + actualYOffset}
                    height={barHeight}
                    width={WIDTH_END_INDICATION}
                    fill={COLOR_END}
                />
            </>
        )
    }
}
