import { computed, observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { ProductionOrder } from 'store/ProductionOrder'
import { PurchaseOrderLine } from 'store/PurchaseOrderLine'
import { SalesOrderLine } from 'store/SalesOrderLine'
import { StockSurplus } from 'store/StockSurplus'
import { ArticleTypeWarehouse } from 'store/ArticleTypeWarehouse'
import { StockDeficit } from 'store/StockDeficit';
import Decimal from 'decimal.js';
import { ArticleType } from 'store/ArticleType';

const RESOURCE_ALLOCATION_SOURCE_WAREHOUSE = 'warehouse';
const RESOURCE_ALLOCATION_SOURCE_PRODUCTION_ORDER = 'production_order';
const RESOURCE_ALLOCATION_SOURCE_PURCHASE_ORDER_LINE = 'purchase_order_line';
const RESOURCE_ALLOCATION_SOURCE_STOCK_SURPLUS_LINE = 'stock_surplus';

const RESOURCE_ALLOCATION_TARGET_SALES_ORDER_LINE = 'sales_order_line';
const RESOURCE_ALLOCATION_TARGET_STOCK_DEFICIT = 'stock_deficit'


export class ResourceAllocation extends Model {
    static backendResourceName = 'resource_allocation'

    @observable id = null
    @observable quantity = new Decimal(0)

    @observable productionOrder = this.relation(ProductionOrder)
    @observable purchaseOrderLine = this.relation(PurchaseOrderLine)
    @observable salesOrderLine = this.relation(SalesOrderLine)
    @observable stockSurplus = this.relation(StockSurplus)
    @observable stockDeficit = this.relation(StockDeficit)
    @observable articleTypeWarehouse = this.relation(ArticleTypeWarehouse)
    @observable articleType = this.relation(ArticleType)

    casts() {
        return {
            quantity: Casts.decimal,
        }
    }


    @computed
    get source() {
        // @ts-ignore
        if (!this.articleTypeWarehouse.isNew) {
            return RESOURCE_ALLOCATION_SOURCE_WAREHOUSE;
        }

        // @ts-ignore
        if (!this.productionOrder.isNew) {
            return RESOURCE_ALLOCATION_SOURCE_PRODUCTION_ORDER
        }

        // @ts-ignore
        if (!this.stockSurplus.isNew) {
            return RESOURCE_ALLOCATION_SOURCE_STOCK_SURPLUS_LINE
        }

        // @ts-ignore
        if (!this.purchaseOrderLine.isNew) {
            return RESOURCE_ALLOCATION_SOURCE_PURCHASE_ORDER_LINE
        }

        // Probably missing one of the relations
        return 'no source'
    }

    @computed
    get target() {

        // @ts-ignore
        if (!this.salesOrderLine.isNew) {
            return RESOURCE_ALLOCATION_TARGET_SALES_ORDER_LINE;
        }

        // @ts-ignore
        if (!this.stockDeficit.isNew) {
            return RESOURCE_ALLOCATION_TARGET_STOCK_DEFICIT
        }

        return 'no target'

    }
}

export class ResourceAllocationStore extends Store {
    static backendResourceName = 'resource_allocation'
    Model = ResourceAllocation
}
