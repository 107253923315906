import { StepStore } from 'store/Step'

export default function sortSteps(source) {
    if (source instanceof StepStore) {
        source = source.models
    }

    function getSteps(endStep) {
        const prevSteps = source
            .filter(
                (step) => step.nextStep.cid === endStep.cid || (step.nextStep.id !== null && step.nextStep.id === endStep.id)
            )
            .map(getSteps)
            .sort((l, r) => l.pos - r.pos)

        const pos = Math.min(source.indexOf(endStep), ...prevSteps.map(({ pos }) => pos))

        if (prevSteps.length === 1) {
            return {
                branches: prevSteps[0].branches,
                steps: [...prevSteps[0].steps, endStep],
                pos,
            }
        } else {
            return {
                branches: prevSteps,
                steps: [endStep],
                pos,
            }
        }
    }

    const endSteps = source.filter(
        (step) => step.nextStep.id === null && source.every((s) => step.nextStep.cid !== s.cid)
    )

    if (endSteps.length === 1) {
        return getSteps(endSteps[0])
    } else {
        return {
            branches: endSteps.map(getSteps),
            steps: [],
        }
    }
}