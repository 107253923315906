import { Button, Card, Grid, Image } from 'semantic-ui-react';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styled from 'styled-components';
import PlannedTrajectory from 'image/process-timing/legend/planned_trajectory.svg'
import ExpectedTrajectory from 'image/process-timing/legend/expected_trajectory.svg'
import StepTrajectory from 'image/process-timing/legend/step_trajectory.svg'
import ProcessTrajectory from 'image/process-timing/legend/process_trajectory.svg'
import EndDateBeforePlanned from 'image/process-timing/legend/end_date_before_planned.svg'
import EndDateAfterPlanned from 'image/process-timing/legend/end_date_after_planned.svg'
import EndDateAfterERP from 'image/process-timing/legend/end_date_after_erp.svg'
import StepFinished from 'image/process-timing/legend/step_finished.svg'
// import Today from 'image/process-timing/legend/today.svg'
import ErpEndDate from 'image/process-timing/legend/erp_end_date.svg'
// import PositiveSlack from 'image/process-timing/legend/positive_slack.svg'
// import NegativeSlack from 'image/process-timing/legend/negative_slack.svg'


const LegendImage = styled(Image)`
    margin-right: 0.5em;
`

@observer
export default class GanttLegend extends Component {
    @observable open = false

    renderButton() {
        return (
            <>
                {/* The button currently goes green when toggled on. This is default behaviour, but should be changed, however no current way to do that */}
                <Button
                    toggle
                    active={this.open}
                    icon='question'
                    onClick={() => this.open = !this.open}
                />
            </>
        )
    }

    renderLegend() {
        if (!this.open) {
            return (<></>)
        }

        return (
            <Card style={{ 'min-width': '620px', 'margin-bottom': '0px', 'margin-right': '7px' }}>
                <Card.Content style={{ 'min-width': '620px' }}>
                    <Grid>
                        <Grid.Column style={{ 'min-width': '240px' }}>
                            <Grid.Row>Lines</Grid.Row>
                            <Grid.Row><LegendImage src={PlannedTrajectory}/>Planned trajectory</Grid.Row>
                            <Grid.Row><LegendImage src={ExpectedTrajectory}/>Expected trajectory</Grid.Row>
                            <Grid.Row><LegendImage src={StepTrajectory}/>Actual step trajectory</Grid.Row>
                            <Grid.Row><LegendImage src={ProcessTrajectory}/>Actual process trajectory</Grid.Row>
                        </Grid.Column>
                        <Grid.Column style={{ 'min-width': '230px' }}>
                            <Grid.Row>Colors</Grid.Row>
                            <Grid.Row><LegendImage src={EndDateBeforePlanned}/>End-date before planned</Grid.Row>
                            <Grid.Row><LegendImage src={EndDateAfterPlanned}/>End-date after planned</Grid.Row>
                            <Grid.Row><LegendImage src={EndDateAfterERP}/>End-date after ERP deadline</Grid.Row>
                        </Grid.Column>
                        <Grid.Column style={{ 'min-width': '150px' }}>
                            <Grid.Row>Other</Grid.Row>
                            <Grid.Row><LegendImage src={StepFinished}/>Step finished</Grid.Row>
                            {/* <Grid.Row><LegendImage src={Today}/>Today</Grid.Row> */}    {/* Uncomment when implemented */}
                            <Grid.Row><LegendImage src={ErpEndDate}/>Erp end-date</Grid.Row>
                            {/* <Grid.Row><LegendImage src={PositiveSlack}/>Positive slack</Grid.Row> */}    {/* Uncomment when implemented */}
                            {/* <Grid.Row><LegendImage src={NegativeSlack}/>Negative slack</Grid.Row> */}    {/* Uncomment when implemented */}
                        </Grid.Column>
                    </Grid>
                </Card.Content>
            </Card>
        )

    }

    render() {
        return (
            <div
                style={{
                    //@ts-ignore
                    'min-width': '1000px',
                    position: 'absolute',
                    bottom: '8px',
                    right: '8px',
                    display: 'flex',
                    'align-items': 'flex-end',
                    'flex-direction': 'row-reverse',
                }}
            >
                {this.renderButton()}
                {this.renderLegend()}
            </div>
        )
    }
}