import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { ExactSalesReturnLineStore } from 'store/ExactSalesReturnLine'

export class ExactSalesReturn extends Model {
  static backendResourceName = 'exact_sales_return'

  @observable id = null
  @observable uuid = null
  @observable status = 0
  @observable description = ''
  @observable remarks = ''
  @observable returnDate = null
  @observable returnNumber = null
  @observable deliverAt = null
  @observable created = null
  @observable modified = null

  relations() {
    return {
      lines: ExactSalesReturnLineStore,
    }
  }

  casts() {
    return {
      returnDate: Casts.date,
      deliverAt: Casts.date,
      created: Casts.datetime,
      modified: Casts.datetime,
    }
  }
}

export class ExactSalesReturnStore extends Store {
  Model = ExactSalesReturn
  static backendResourceName = 'exact_sales_return'
}
