import { subscribe } from 'store/Base';
import { Component } from 'react';
interface Subscription {
  unsubscribe: () => void;
}
type HighTemplarRoom = {
  target: string;
} & Record<string, string>;

/**
 * Adds a `this.subscribe` function so that websockets are automatically
 * unsubscribed when the Component unmounts. Example usage:
 *
 * class UserWithSubscribe extends subscribe(UserWithoutSubscribe) {
 *    componentDidMount() {
 *        this.subscribe({ target: 'some room' }, payload => console.log('-------->', payload))
 *    }
 * }
 */
export default function <T extends new (...args: any[]) => Component>(SomeComponent: T) {
  return class extends SomeComponent {
    /**
     * Contains all websocket subscriptions.
     */
    subscriptions: Subscription[] = [];
    subscribe = (room: HighTemplarRoom, callback: (data: any) => void) => {
      const subscription = subscribe(room, callback);
      this.subscriptions.push(subscription);
      return subscription;
    };
    unsubscribe = () => {
      this.subscriptions.forEach(s => s.unsubscribe());
      this.subscriptions = [];
    };
    componentWillUnmount() {
      super.componentWillUnmount?.();
      this.unsubscribe();
    }
  };
}