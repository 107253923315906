import styled from 'styled-components'
import Scrollbars from 'react-custom-scrollbars'
import CYToolbar from 'component/Toolbar'


export const StyledToolbar = styled(CYToolbar)`
position: fixed;
z-index: 500;
bottom: 0px;
left: 0;
right: 0;
height: 50px;
`

export const Container = styled(Scrollbars)`
padding: 1em;
> div:first-child {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.25em;
  background-color: #FFFFFF;
  padding: 0 calc(2em * 3);
}
> div:last-child {
  margin-bottom: 70px;
}
`

export const Stats = styled.div`
  font-size: 1.25em;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  column-gap: 0.875rem;
  margin-left: 5rem;
  color: #757575;
  > span {
    font-size: 20px;
    > span {
      font-size: 24px;
      font-weight: bold;
    }
  }
`

export const LAST_USED_KEY = 'last-used-document-printer'