import { observable, } from 'mobx'
import { Model, Store, } from 'store/Base'
import { Step } from './Step'
import { Batch } from './Batch'
import { ProductionRequest } from './ProductionRequest'

export class ProductionRequestLink extends Model {
  static backendResourceName = 'production_request_link'

  @observable id = null

  relations() {
    return {
      superStep: Step,
      superProductionRequestBatch: Batch,
      subProductionRequest: ProductionRequest
    }
  }
}

export class ProductionRequestLinkStore extends Store {
  Model = ProductionRequestLink
  static backendResourceName = 'production_request_link'
}