// Hacky helper to ease working viewStore. Basically we need refactor that some
// views require a viewStore. For the meantime, this helper can ease the burden
// of passing the viewStore by making it globally available.
let viewStore = null;

export function configureViewStore(givenViewStore) {
    viewStore = givenViewStore;
}

export function getViewStore() {
    return viewStore;
}
