import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { Batch } from './Batch'
import Decimal from 'decimal.js'

export class BatchUsage extends Model {
  static backendResourceName = 'batch_usage'

  @observable id = null
  @observable quantity = Decimal(0)

  relations() {
    return {
      usingBatch: Batch,
      usedBatch: Batch,
    }
  }

  casts() {
    return {
      quantity: Casts.decimal,
    }
  }
}

export class BatchUsageStore extends Store {
  static backendResourceName = 'batch_usage'

  Model = BatchUsage
}
