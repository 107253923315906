import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ArticleType } from 'store/ArticleType'
import { ResourceAllocationStore } from 'feature/Allocation/store/ResourceAllocation'

export class StockSurplus extends Model {
  static backendResourceName = 'stock_surplus'

  @observable id = null
  @observable quantity = 0

  relations() {
    return {
      articleType: ArticleType,
      resourceAllocations: ResourceAllocationStore,
    }
  }
}

export class StockSurplusStore extends Store {
  static backendResourceName = 'stock_surplus'
  Model = StockSurplus
}
