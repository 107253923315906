import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Header, Form, Table } from 'semantic-ui-react'
import styled from 'styled-components'
import { ArticleType } from 'store/ArticleType'
import { ScrollModal } from '@code-yellow/spider'
import { humanReadable } from 'helpers/decimal'

const ScaledForm = styled(Form)`
  max-width: ${({ scale }) => 1000 * scale}px !important;
  margin: 0 auto;
  &.ui.form {
    font-size: ${({ scale }) => scale}rem;
    > label {
      font-size: ${({ scale }) => 0.9 * scale}rem;
    }
  }
`

@observer
export class TargetInfo extends Component {
  static propTypes = {
    articleType: PropTypes.instanceOf(ArticleType).isRequired,
    steps: PropTypes.object.isRequired,
    globalValueDecimalRounding: PropTypes.string.isRequired,
  }

  render() {
    const { articleType, globalValueDecimalRounding } = this.props

    return (
      <React.Fragment>
        <Form.Field>
          <label>{t('exactItem.field.classification.label')}</label>
          {articleType.exactItem.classification}

          <br />
          <br />
          <label>{t('exactItem.field.code.label')}</label>
          <span data-test-article-code>{articleType.exactItem.code}</span>

          <br />
          <br />
          <label>{t('exactItem.field.name.label')}</label>
          {articleType.exactItem.name}

          <br />
          <br />
          <label>{t('articleType.field.storageLocations.short')}</label>
          <Table style={{ width: '200px' }}>
            <Table.Header>
              <Table.HeaderCell>Code</Table.HeaderCell>
              <Table.HeaderCell>#</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {articleType.storageLocations.map((sl) => (
                <Table.Row>
                  <Table.Cell collapsing>{sl.storageLocation.code}</Table.Cell>
                  <Table.Cell collapsing data-test-storage-stock>{humanReadable(sl.stock, globalValueDecimalRounding)}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>

          <br />
          <br />
        </Form.Field>
      </React.Fragment>
    )
  }
}

/**
 * Shows info for a specific article type. Refetches article type with correct
 * relations.
 */
@observer
export default class ArticleTypeInfoModal extends Component {
  static propTypes = {
    /**
     * Article type to display info of. A mirror articleType will be created
     * and fetched, so you don't have to worry about relations.
     */
    articleType: PropTypes.instanceOf(ArticleType).isRequired,
    globalValueDecimalRounding: PropTypes.string.isRequired,

    trigger: PropTypes.node,
    open: PropTypes.bool,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    scale: PropTypes.number,
  }

  static defaultProps = {
    onOpen: () => { },
    onClose: () => { },

    scale: 1,
  }

  @observable open = false

  /**
   * Local mirror model, to load correct data.
   *
   * @type {Batch}
   */
  @observable articleType = new ArticleType(
    { id: this.props.articleType.id },
    {
      relations: ['exactItem', 'storageLocations.storageLocation.warehouse'],
    }
  )

  constructor(...args) {
    super(...args)

    this.onOpen = this.onOpen.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onMount = this.onMount.bind(this)

    if (this.props.open) {
      this.onOpen()
    }
  }

  onOpen() {
    const { onOpen } = this.props

    this.open = true

    onOpen()
  }

  onMount() {
    this.articleType.fetch()
  }

  onClose() {
    const { onClose } = this.props

    this.open = false

    onClose()
  }

  render() {
    const { open, trigger, scale, globalValueDecimalRounding } = this.props

    return (
      <ScrollModal
        closeIcon
        data-test-article-type-info-modal
        trigger={trigger}
        open={open === undefined ? this.open : open}
        onOpen={this.onOpen}
        onClose={this.onClose}
        onMount={this.onMount}
        onUnmount={this.onUnmount}
        size="fullscreen"
      >
        <ScrollModal.Header>
          <Header as="h1">{this.articleType && this.articleType.name}</Header>
        </ScrollModal.Header>
        <ScrollModal.Content>
          <ScaledForm scale={scale}>
            {this.articleType && <TargetInfo articleType={this.articleType} steps={this.steps} globalValueDecimalRounding={globalValueDecimalRounding} />}
          </ScaledForm>
        </ScrollModal.Content>
      </ScrollModal>
    )
  }
}
