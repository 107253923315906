import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observable, computed, action } from 'mobx'
import { observer } from 'mobx-react'
import { StepStore, TYPE_CARRIER } from 'store/Step'
import styled from 'styled-components'
import { theme } from 'styles'
import { Icon, Popup, Button } from 'semantic-ui-react'
import { Step } from 'store/Step'
import { getStepBatchSizes } from 'container/ArticleType/Edit'
import { isFeatureFlagEnabled } from 'helpers/featureFlags'
import NewStepLabel, { OldStepLabel as StepLabel } from './Steps/StepLabel';
import { getStepColor as getColor, STEP_TRANSITION } from './Steps/StepMarkup';
import sortSteps from 'helpers/sortSteps'

const STEP_TYPE_ICONS = {
  print: 'print',
  form: 'clipboard list',
  split: 'cubes',
  multiplier: 'times',
  subprocesses: 'boxes',
  carrier: 'shipping fast',
  byproduct: 'cut',
  nest: 'list',
  export: 'share square',
  import: 'download'
}



const StyledPopup = styled(Popup)`
  padding: 0 !important;
  > .ui.buttons {
    border: unset !important;
  }
  display: flex;
  flex-direction: column;
`

const ErrorPopup = styled(Popup)`
  padding: 0.5833em 0.833em !important;
  background-color: #FF5252 !important;
  border-color: #FF5252 !important;
  color: #FFF !important;
  font-weight: bold !important;
  font-size: 0.85714286rem !important;
  &::before {
    background-color: #FF5252 !important;
    border-color: #FF5252 !important;
  }
`

const StepsContainer = styled.div`
  width: 100%;
  padding: ${({ tops }) => (tops ? 0 : 1)}rem 0rem;
  height: ${({ tops, height, scale }) => (tops ? 8 * height : 6 * height + 2) * scale}rem;
  > * {
    transform-origin: 0 0;
    transform: scale(${({ scale }) => scale});
    width: ${({ scale }) => 100 / scale}%;
    height: ${({ scale }) => 100 / scale}%;
  }
`

const StepContainer = styled.div`
  width: 8rem;
  height: 6rem;
  position: relative;
  flex: 0 0 auto;
  transition: width ${STEP_TRANSITION};
  ${({ isNew }) =>
    isNew
      ? `
        width: 0;
    `
      : ''}
`

const StepDropzone = styled.div`
    width: 4rem;
    position: relative;
    flex: 0 0 auto;
    transition width ${STEP_TRANSITION};
    > div {
        position: absolute;
        left: ${({ first, last }) => {
          if (first) {
            return '3.25rem'
          }

          if (last) {
            return '-4rem'
          }

          return '0'
        }};
        top: 2.25rem;
        height: 1.5rem;
        width: ${({ first, last }) => (first || last ? '4.75rem' : '4rem')};
        border-radius: ${({ first, last }) => {
          if (first) {
            return '0.75rem 0 0 0.7rem'
          }

          if (last) {
            return '0 0.75rem 0.75rem 0'
          }

          return '0'
        }};
        background-color: ${({ color }) => color};
        transition: width ${STEP_TRANSITION};
    }
`

const StepBackground = styled.div`
  z-index: 0;
  height: 1rem;
  width: 4rem;
  background-color: ${({ color }) => color};
  position: absolute;
  left: ${({ right }) => (right ? '4rem' : 0)};
  top: 2.5rem;
  ${({ isNew, right }) =>
    isNew && right
      ? `
        transform: translate(-4rem);
    `
      : ''}
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: ${['transform', 'opacity'].map((property) => `${property} ${STEP_TRANSITION}`).join(', ')};
`


const StepContent = styled.div`
  z-index: 1;
  position: absolute;
  left: 2rem;
  top: 1rem;
  width: 4rem;
  height: 4rem;
  line-height: 4rem;
  background-color: ${getColor};
  ${({ isNew, selected }) => {
    if (isNew) {
      return 'transform: scale(0) translate(-4rem);'
    }

    if (selected) {
      return 'transform: scale(1.25);'
    }

    return ''
  }}
  border-radius: 2rem;
  border-width: 0.25em;
  border-style: dashed;
  ${({ isLeadingBatch }) => !isLeadingBatch ? 'border-color: transparent': 'border-color: orange;'} 

  ${({ onClick }) =>
    onClick
      ? `
        cursor: pointer;
    `
      : ''}
  color: #fff;
  i.icon {
    margin: 0 !important;
    font-size: 1.5em !important;
  }
  transition: ${['background-color', 'transform'].map((property) => `${property} ${STEP_TRANSITION}`).join(', ')};
  ${({ multiplier }) => multiplier ? `
    font-size: 1.25rem;
    font-weight: bold;
    left: 2.5rem;
    top: 1.5rem;
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    border-radius: 1.5rem;
  ` : ''}
`

const StepLineTo = styled.div`
  background-color: ${({ color }) => color};
  position: absolute;
  top: ${({ tops }) => (tops ? 3.5 : 2.5)}rem;
  right: ${({ dx, dy }) => -Math.hypot(dx, dy) + dx - 0.5}rem;
  width: ${({ dx, dy }) => Math.hypot(dx, dy) + 1}rem;
  height: 1rem;
  border-radius: 0.5rem;
  transform: rotate(${({ dx, dy }) => Math.atan2(dy, dx)}rad);
  transform-origin: 0.5rem 50%;
`

const StepLineToOverlay = styled.div`
  position: absolute;
  top: ${({ tops, height }) => (tops ? 4 : 3) + (height < 0 ? height : 0)}rem;
  right: 0;
  width: ${({ width }) => width}rem;
  height: ${({ height }) => Math.abs(height)}rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SmallRatio = styled.span`
  font-size: 70%;
  position: relative;
  bottom: 0.125em;
`

const WorkStationContent = styled.div`
  z-index: 1;
  position: absolute;
  left: 2.4rem;
  top: 1.4rem;
  width: 3.2rem;
  height: 3.2rem;
  line-height: 3.6rem;
  background-color: ${getColor};
  ${({ selected }) =>
    selected
      ? `
        transform: scale(1.25);
    `
      : ''}
  border-radius: 0.5rem;
  ${({ onClick }) =>
    onClick
      ? `
        cursor: pointer;
    `
      : ''}
  i.icon {
    color: #fff;
    margin: 0 !important;
  }
`

const EdgeContent = styled.div`
  z-index: 1;
  position: absolute;
  left: 2rem;
  top: 1rem;
  width: 4rem;
  height: 4rem;
  line-height: 4rem;
  ${({ selected }) =>
    selected
      ? `
        transform: scale(1.25);
    `
      : ''}
  ${({ onClick }) =>
    onClick
      ? `
        cursor: pointer;
    `
      : ''}
    i.icon {
    color: #fff;
    margin: 0 !important;
  }

  &::before {
    display: block;
    content: '';
    position: absolute;
    z-index: -1;
    left: 0.4rem;
    top: 0.4rem;
    width: 3.2rem;
    height: 3.2rem;
    transform: ${({ selected }) => (selected ? 'scale(1.25) ' : '')} rotate(45deg);
    border-radius: 0.5rem;
    background-color: ${getColor};
  }
`

const StepAdd = styled.div`
  position: absolute;
  left: 0;
  top: ${({ split }) => (split ? '3rem' : 0)};
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  background-color: ${getColor};
  border-radius: 1rem;
  cursor: pointer;
  i.icon {
    color: #fff;
    margin: 0 !important;
  }
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity ${STEP_TRANSITION};
`

const StepAddContainer = styled.div`
  z-index: 1;
  position: absolute;
  left: ${({ left, middle, isNew }) => {
    if (left || isNew) {
      return '-1rem'
    }

    if (middle) {
      return '3rem'
    }

    return '7rem'
  }};
  top: 2rem;
  width: 2rem;
  height: ${({ split }) => (split ? 5 : 2)}rem;
  opacity: ${({ visible, hidden, partiallyVisible, hasError }) => {
    if (hidden) {
      return 0
    }

    if (visible || hasError) {
      return 1
    }

    if (partiallyVisible) {
      return 0.3
    }

    return 0
  }};
  &:hover {
    opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  }
  transition: ${['opacity', 'left'].map((property) => `${property} ${STEP_TRANSITION}`).join(', ')};
  overflow: hidden;
`

const StepAddLine = styled.div`
  position: absolute;
  left: 0.5rem;
  top: 1rem;
  width: 1rem;
  height: 3rem;
  background-color: ${({ color }) => color};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity ${STEP_TRANSITION};
`


export function stepIcon(step) {
  switch (step.type) {
    case 'form':
      return step.formStep.icon
    case 'split':
      return step.splitStep.icon
    default:
      return STEP_TYPE_ICONS[step.type]
  }
}

function containsStep({ branches, steps }, step) {
  return steps.includes(step) || branches.some((branch) => containsStep(branch, step))
}

export function getHeight({ branches, before }) {
  return Math.max(1, branches.map(getHeight).reduce((a, b) => a + b, 0) + (before ? before.length : 0))
}

@observer
export default class Steps extends Component {
  static propTypes = {
    steps: PropTypes.instanceOf(StepStore).isRequired,
    tree: PropTypes.object,
    selected: PropTypes.instanceOf(Step),
    onSelect: PropTypes.func,
    canSelect: PropTypes.func,
    editable: PropTypes.bool,
    color: PropTypes.string,
    scale: PropTypes.number,

    renderTop: PropTypes.func,
    renderTopBefore: PropTypes.func,
    renderTopAfter: PropTypes.func,
    renderLineTo: PropTypes.func,
    onSelectBefore: PropTypes.func,
    onSelectAfter: PropTypes.func,

    afterSelectedColor: PropTypes.string,
    disableAfterSelected: PropTypes.bool,

    batchSize: PropTypes.number,

    // To lock in / outbound to single step.
    batchType: PropTypes.object,
    excludeStepTypes: PropTypes.arrayOf(PropTypes.string.isRequired),
    hasErrors: PropTypes.arrayOf(PropTypes.string.isRequired),
    addButtonErrors: PropTypes.object,
  }

  static defaultProps = {
    canSelect: () => true,
    editable: false,
    color: theme.primaryColor,
    scale: 1,

    afterSelectedColor: '#90A0A0',
    disableAfterSelected: false,

    batchSize: 1,
    excludeStepTypes: [],
    hasErrors: [],
    addButtonErrors: {},
  }

  @observable hovering = null
  @observable newStep = null
  @observable toRemove = false
  @observable dragging = null
  @observable draggingOver = null

  constructor(...args) {
    super(...args)
    this.renderSteps = this.renderSteps.bind(this)
    this.renderBefore = this.renderBefore.bind(this)
    this.onDrop = this.onDrop.bind(this)
  }

  @computed get steps() {
    const { steps, tree } = this.props
    if (tree) {
      return tree
    }
    return sortSteps(steps.models)
  }

  @action insertBetween(prev, next, data, select = true) {
    const { steps, onSelect, canSelect } = this.props

    const oldStepBatchSizes = getStepBatchSizes(this.steps, null)


    const step = steps.add({ ...data, nextStep: {} })

    // eslint-disable-next-line
    for (const p of prev) {
      p.nextStep = step
      p.markChanged('nextStep')
    }

    if (next) {
      step.nextStep = next
    }
    step.markChanged('nextStep')

    this.fixSerialNumberFormats(oldStepBatchSizes)

    if (select && onSelect && canSelect(step)) {
      onSelect(step)
    }

    if (prev.length === 1){
      step.isLeadingBatch = prev[0].isLeadingBatch
      prev[0].isLeadingBatch = false
      step.markChanged('isLeadingBatch')
      prev[0].markChanged('isLeadingBatch')
    }

    return step
  }

  @action remove(toRemove) {
    const { steps } = this.props

    const oldStepBatchSizes = getStepBatchSizes(this.steps, null)

    // Check all steps whos next step is the deleted step, then change
    // that step to the deleted step's next step.
    steps.filter((step) => step.nextStep.getInternalId() === toRemove.getInternalId()).forEach((step) => {
      step.nextStep = toRemove.nextStep
      step.markChanged('nextStep')
    })

    // Check how many other steps have the same next step as the deleted step
    const dependencies = steps.filter((step) => step.nextStep.getInternalId() === toRemove.nextStep.getInternalId()
                                              && step.getInternalId() !== toRemove.getInternalId())
    // If the branches colapesed (there is only 1 other step pointing to  the
    // next same step), we need to set leadingBatch to false.
    if (dependencies.length === 1){
      dependencies[0].isLeadingBatch = false
      dependencies[0].markChanged('isLeadingBatch')
    }

    steps.remove(toRemove)
    this.fixSerialNumberFormats(oldStepBatchSizes)
    // If deleting this step means there will be no more branches, we also need to
    // remove the leading batch properity
  }


  @action fixSerialNumberFormats(oldStepBatchSizes) {
    const { steps } = this.props;

    //Make sure all batch defining steps have a serial number format
    const newStepBatchSizes = getStepBatchSizes(this.steps, null)
    // eslint-disable-next-line
    for (const [cid, { syncTo }] of Object.entries(newStepBatchSizes)) {
      const target = steps.models.find((step) => step.cid === cid)
      // Search for a step that we sync to that used to be batch defining
      const source = syncTo.find((step) => oldStepBatchSizes[step.cid])
      if (source) {
        target.newBatchSerialNumberFormat = source.newBatchSerialNumberFormat
      } else if (!oldStepBatchSizes[cid]) {
        target.newBatchSerialNumberFormat = [
          { type: 'date', part: 'day', format: 'dd' },
          { type: 'date', part: 'month', format: 'mm' },
          { type: 'date', part: 'year', format: 'yy' },
          { type: 'code', alphabet: '0123456789', digits: 4, expand: true },
        ]
      }
    }
    // Resync all serial number formats
    // eslint-disable-next-line
    for (const step of steps.models) {
      if (!newStepBatchSizes[step.cid]) {
        step.newBatchSerialNumberFormat = null
      }
    }
    // eslint-disable-next-line
    for (const step of steps.models) {
      const batchSizes = newStepBatchSizes[step.cid]
      if (batchSizes) {
        const { syncTo } = batchSizes
        // eslint-disable-next-line
        for (const substep of syncTo) {
          substep.newBatchSerialNumberFormat = step.serialNumberFormat
        }
      }
    }
  }

  renderAddButton(prev, next, props = {}) {
    const { color, batchSize, addButtonErrors, excludeStepTypes } = this.props

    const types = Step.TYPES_ENABLED.filter(
      type => {
        if (type === TYPE_CARRIER) {
          return isFeatureFlagEnabled('carrier_integration')
        }
        return !excludeStepTypes.includes(type)

      }
    )
    const errors = addButtonErrors[`${prev.map((s) => s.cid + ',').join('')}${next ? next.cid : 'null'}`]

    const open =
      this.hovering &&
      this.hovering.next === next &&
      this.hovering.prev.length === prev.length &&
      this.hovering.prev.every((step, i) => step === prev[i])
    const split = prev.length !== 0 && next != null

    let addButton = (
      <Icon data-test-step-add-button
        name="add"
        onClick={() => this.hovering = { prev, next, split: false }}
      />
    )
    if (open && !this.hovering.split) {
      addButton = (
        <StyledPopup open
          on="click"
          position="bottom right"
          onClose={() => (this.hovering = null)}
          trigger={addButton}
          content={
            <React.Fragment>
              <Button.Group basic vertical>
                {types.map((type) => (
                  <Button
                    data-test-add-step-button={type}
                    icon={STEP_TYPE_ICONS[type]}
                    labelPosition="left"
                    content={t(`step.field.type.value.${type}`)}
                    disabled={type === 'subprocesses' && this.props.steps.models.some((step) => step.type === 'subprocesses')}
                    onClick={action(() => {
                      const data = { type }

                      if (type === 'multiplier') {
                        data.label = 'multiplier'
                      }

                      if (type === 'split') {
                        data.splitStep = { newBatchQuantity: batchSize }
                      }

                      this.hovering = null
                      this.newStep = this.insertBetween(prev, next, data)
                    })}
                  />
                ))}
              </Button.Group>
            </React.Fragment>
          }
        />
      )
    } else if (!!errors) {
      addButton = (
        <ErrorPopup position="top center" trigger={addButton}>
          {errors.map((error, i) => <div key={i}>{error}</div>)}
        </ErrorPopup>
      )
    }

    return (
      <StepAddContainer hasError={!!errors} visible={open} split={split} {...props}>
        {split && <StepAddLine color={color} visible={!open || this.hovering.split} />}
        <StepAdd color={color} visible={!open || !this.hovering.split} hasError={!!errors} {...props}>
          {addButton}
        </StepAdd>
        {split && (
          <StepAdd split visible={!open || this.hovering.split} color={color} {...props}>
            <StyledPopup
              on="click"
              position="top center"
              open={open && this.hovering.split}
              onOpen={() => (this.hovering = { prev, next, split: true })}
              onClose={() => (this.hovering = null)}
              trigger={<Icon data-test-fork-add-button name="add" />}
              content={
                <Button.Group basic vertical>
                  {types.map((type) => (
                    <Button
                      data-test-add-fork-button={type}
                      icon={STEP_TYPE_ICONS[type]}
                      labelPosition="left"
                      content={t(`step.field.type.value.${type}`)}
                      disabled={type === 'subprocesses' && this.props.steps.models.some((step) => step.type === 'subprocesses')}
                      onClick={action(() => {
                        const data = { type }

                        if (type === 'multiplier') {
                          data.label = 'multiplier'
                        }

                        if (type === 'split') {
                          data.splitStep = { newBatchQuantity: batchSize }
                        }

                        this.hovering = null
                        this.newStep = this.insertBetween([], next, data)
                      })}
                    />
                  ))}
                </Button.Group>
              }
            />
          </StepAdd>
        )}
      </StepAddContainer>
    )
  }

  @action onDrop() {
    const { selected } = this.props
    const { prev, next } = this.draggingOver
    this.draggingOver = null

    const select = selected === this.dragging
    this.remove(this.dragging)
    this.newStep = this.insertBetween(prev, next, this.dragging.toJS(), select)
    this.dragging = null
  }

  renderBefore(before) {
    const { color, renderTopBefore, onSelectBefore } = this.props

    return (
      <StepContainer data-test-step-container-begin>
        <StepBackground visible right color={color} />
        {before ? (
          <WorkStationContent color={color} onClick={onSelectBefore ? () => onSelectBefore(before) : undefined} />
        ) : (
          <EdgeContent color={color} />
        )}
        {before && renderTopBefore && (
          <StepLabel top color={color} data-test-step-label-top-before>
            {renderTopBefore(before)}
          </StepLabel>
        )}
        <StepLabel color={color} data-test-step-label-start>
          {before ? before.workStation.name : t('step.edit.start')}
        </StepLabel>
      </StepContainer>
    )
  }

  renderStepsList = (steps, before, rootSteps, branches, editable, disableAfterSelected, selected, hasErrors, afterSelectedColor, color, onSelect, canSelect, renderTop) => {
    if (steps.length > 0) {
      return (
          steps.map((step, i) => {
            const first =
                !before &&
                rootSteps.models.every(
                    (s) => s.nextStep.cid !== step.cid && (s.nextStep.id === null || s.nextStep.id !== step.id)
                )
            const last =
                !before &&
                rootSteps.models.every(
                    (s) =>
                        s === step ||
                        !rootSteps.models.every(
                            (s2) => s.nextStep.cid !== s2.cid && (s.nextStep.id === null || s.nextStep.id !== s2.id)
                        )
                )

            const wasFirst =
                !before &&
                !first &&
                rootSteps.models.every(
                    (s) =>
                        (s.nextStep.cid !== step.cid && (s.nextStep.id === null || s.nextStep.id !== step.id)) ||
                        s === this.newStep ||
                        s === this.dragging
                )
            const wasLast =
                !before &&
                !last &&
                rootSteps.models.every(
                    (s) =>
                        s === step ||
                        !rootSteps.models.every(
                            (s2) => s.nextStep.cid !== s2.cid && (s.nextStep.id === null || s.nextStep.id !== s2.id)
                        ) ||
                        s === this.newStep ||
                        s === this.dragging
                )

            let isNew = step === this.newStep
            const isSelected = isNew || step === selected
            const isDragging = step === this.dragging
            if (isDragging) {
              isNew = true
            }

            const afterSelected =
                disableAfterSelected && selected && containsStep({ branches, steps: steps.slice(0, i) }, selected)

            const hasError = hasErrors.includes(step.cid) || (
                step.hasErrors &&
                (
                    Object.keys(step.actuallyUsefulErrors).some((key) => key !== '__all__') ||
                    (step.actuallyUsefulErrors.__all__ || []).some(({ code }) => code !== 'no_details') ||
                    (
                        step.__activeCurrentRelations
                            .filter((rel) => !step.constructor.ignoreErrors.includes(rel))
                            .some((rel) => step[rel].hasErrors)
                    )
                )
            )
            const isLed = rootSteps.models.some((prevStep) => prevStep.nextStep.getInternalId() === step.getInternalId() && prevStep.isLeadingBatch)

            return (
                <div data-test-step={step.id || step.cid} key={step.cid}>
                  {((first && !isNew) || wasFirst) && this.draggingOver && this.draggingOver.next === step && (
                      <StepDropzone
                          first
                          color={afterSelected ? afterSelectedColor : color}
                          key={`${step.cid}-l-dropzone`}
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={this.onDrop}
                      >
                        <div/>
                      </StepDropzone>
                  )}

                  <StepContainer
                      data-test-step-container-middle={i + 1}
                      isNew={isNew}
                      onDragOver={(e) => {
                        e.preventDefault()

                        const rect = e.currentTarget.getBoundingClientRect()
                        const right = e.clientX >= (rect.left + rect.right) / 2

                        let prev, next
                        if (right) {
                          prev = step
                          next = last ? null : steps[i + 1]
                        } else {
                          prev = first ? null : steps[i - 1]
                          next = step
                        }

                        this.draggingOver = { prev, next }
                      }}
                      onDrop={this.onDrop}
                  >
                    {editable &&
                        i === 0 &&
                        this.renderAddButton(
                            rootSteps.filter((s) => s.nextStep.getInternalId() === step.getInternalId()),
                            step,
                            { hidden: !!this.dragging, left: true, partiallyVisible: first, isNew }
                        )}
                    <StepBackground
                        color={afterSelected ? afterSelectedColor : color}
                        isNew={isNew}
                        visible={!wasFirst && !first && (!isNew || !last)}
                    />
                    <StepBackground
                        right
                        color={afterSelected || (disableAfterSelected && isSelected) ? afterSelectedColor : color}
                        isNew={isNew}
                        visible={!wasLast && !last && (!isNew || !first)}
                    />

                    <StepContent
                        draggable={editable}
                        data-test-step-icon
                        selected={isSelected}
                        isLeadingBatch={step.isLeadingBatch}
                        onClick={onSelect && canSelect(step) && (() => onSelect(step))}
                        onDragStart={(e) => {
                          this.dragging = step
                          e.dataTransfer.setData('dragContent', true)
                        }}
                        onDragEnd={action(() => {
                          this.dragging = null
                          this.draggingOver = null
                        })}
                        color={afterSelected ? afterSelectedColor : color}
                        isNew={isNew}
                        hasError={hasError}
                        multiplier={step.type === 'multiplier'}
                    >
                      {step.type === 'multiplier' ? (
                          <React.Fragment>
                            {step.multiplierStep.multiplier}
                            <SmallRatio>:1</SmallRatio>
                          </React.Fragment>
                      ) : (
                          <Icon name={stepIcon(step)}/>
                      )}
                    </StepContent>
                    {editable &&
                        this.renderAddButton([step], rootSteps.models.find((s) => s.getInternalId() === step.nextStep.getInternalId()) || null, {
                          hidden: !!this.dragging,
                          partiallyVisible: last,
                          isNew,
                        })}
                    {renderTop && !isLed && (
                        <StepLabel
                            top
                            data-test-step-label-top
                            selected={step === selected}
                            color={afterSelected ? afterSelectedColor : color}
                            isNew={isNew}
                            multiplier={step.type === 'multiplier'}
                        >
                          {renderTop(step)}
                        </StepLabel>
                    )}
                    <NewStepLabel
                        data-test-step-label
                        selected={step === selected}
                        color={afterSelected ? afterSelectedColor : color}
                        isNew={isNew}
                        hasError={hasError}
                        multiplier={step.type === 'multiplier'}
                        icon={editable && isSelected && (
                            <Icon
                                data-test-delete-button
                                name="trash alternate"
                                color="red"
                                onClick={action(() => {
                                  onSelect(null)
                                  step._removed = true
                                  this.remove(step)
                                })}
                            />
                        )}
                    >
                      {step.type !== 'multiplier' && step.label}
                    </NewStepLabel>
                  </StepContainer>
                  {this.draggingOver && this.draggingOver.prev === step && (
                      <StepDropzone
                          last={last}
                          key={`${step.cid}-r-dropzone`}
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={this.onDrop}
                          color={afterSelected ? afterSelectedColor : color}
                      >
                        <div/>
                      </StepDropzone>
                  )}
                </div>
            )
          })
      )
    }
    if (editable) {
      return (
        <StepContainer data-test-step-container-edit>
          {this.renderAddButton(
            branches.map(({ steps }) => steps[steps.length - 1]),
            null,
            branches.length === 0
              ? {
                middle: true,
                visible: true,
              }
              : {
                left: true,
                hidden: !!this.dragging,
              }
          )}
        </StepContainer>
      )
    }

    return null;
  }

  renderSteps({ branches, steps, before, after, baseAfter }, i, superbranches, superbefore) {
    const {
      editable,
      color,
      renderTopAfter,
      onSelect,
      canSelect,
      selected,
      renderTop,
      steps: rootSteps,
      renderLineTo,
      onSelectAfter,
      afterSelectedColor,
      disableAfterSelected,
      hasErrors,
    } = this.props

    let lineTo = null

    if (superbranches) {
      let leftY = getHeight({ branches, before }) / 2
      let rightY = getHeight({ branches: superbranches, before: superbefore }) / 2

      for (let j = 0; j < i; j++) {
        leftY += getHeight(superbranches[j])
      }

      lineTo = <StepLineTo tops={!!renderTop} color={color} dx={3} dy={(rightY - leftY) * (renderTop ? 8 : 6)} />

      if (renderLineTo && steps.length > 0) {
        lineTo = (
          <React.Fragment>
            {lineTo}
            <StepLineToOverlay tops={!!renderTop} width={3} height={(rightY - leftY) * (renderTop ? 8 : 6)}>
              {renderLineTo(steps[steps.length - 1])}
            </StepLineToOverlay>
          </React.Fragment>
        )
      }
    }

    const padding = {}

    if (renderTop) {
      padding.paddingTop = '1rem'
      padding.paddingBottom = '1rem'
    }

    if (lineTo) {
      padding.paddingRight = '3rem'
    }

    let stepsNode = (
      //            Not ready for this.
      //            <Transition.Group data-test-steps-branch
      //                as="div"
      //                animation="zoom"
      //                duration={500}
      //                style={{
      //                    display: 'flex',
      //                    justifyContent: 'center',
      //                    position: 'relative',
      //                    ...padding,
      //                }}
      //            >
      <div
        data-test-steps-branch
        key={i}
        style={{
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
          ...padding,
        }}
      >
        {lineTo}
        {before && before.length === 1 && branches.length === 0 && this.renderBefore(before[0])}
        {this.renderStepsList(steps, before, rootSteps, branches, editable, disableAfterSelected, selected, hasErrors, afterSelectedColor, color, onSelect, canSelect, renderTop)}
        {(after || (after === null && !superbranches)) && (
          <StepContainer data-test-step-container-end>
            <StepBackground visible color={color} />
            {after ? (
              <WorkStationContent color={color} onClick={onSelectAfter ? () => onSelectAfter(after) : undefined} />
            ) : (
              <EdgeContent color={color} />
            )}
            {after && renderTopAfter && (
              <StepLabel top color={color} data-test-step-label-top-after>
                {renderTopAfter(after, baseAfter)}
              </StepLabel>
            )}
            <StepLabel color={color} data-test-step-label-end>
              {after ? after.workStation.name : t('step.edit.end')}
            </StepLabel>
          </StepContainer>
        )}
      </div>
    )

    if (branches.length > 0 || (before && before.length > 1)) {
      stepsNode = (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} key={i}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            {branches.map((branch, i) => this.renderSteps(branch, i, branches, before))}
            {before &&
              before.map((b, i) => {
                const height = getHeight({ branches, before })
                let leftY = height - i - 0.5
                let rightY = height / 2

                return (
                  <div
                    key={`before_${i}`}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      position: 'relative',
                      padding: `${renderTop ? '1rem' : 0} 3rem ${renderTop ? '1rem' : 0} 0`,
                    }}
                  >
                    {this.renderBefore(b)}
                    <StepLineTo tops={!!renderTop} color={color} dx={3} dy={(rightY - leftY) * (renderTop ? 8 : 6)} />
                    {renderLineTo && (
                      <StepLineToOverlay tops={!!renderTop} width={3} height={(rightY - leftY) * (renderTop ? 8 : 6)}>
                        {renderLineTo(b)}
                      </StepLineToOverlay>
                    )}
                  </div>
                )
              })}
          </div>
          {stepsNode}
        </div>
      )
    }

    return stepsNode
  }

  render() {
    const { renderTop, scale, ...props } = this.props

    // Toggle this.newStep for animation purposes.
    if (this.newStep) {
      setTimeout(
        action(() => {
          this.newStep = null
        }),
        100
      )
    }

    return (
      <StepsContainer tops={!!renderTop} scale={scale} height={getHeight(this.steps)} {...props}>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: '1 1 auto', padding: 0, minWidth: 0 }} />
          {this.renderSteps(this.steps)}
          <div style={{ flex: '1 1 auto', padding: 0, minWidth: 0 }} />
        </div>
      </StepsContainer>
    )
  }
}
