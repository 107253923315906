import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { Form, Message, Loader } from 'semantic-ui-react'
import { ArticleType } from 'store/ArticleType'
import { Warehouse } from 'store/Warehouse'
import { StorageLocation } from 'store/StorageLocation'
import { TargetSelect } from '@code-yellow/spider'
import { humanReadable } from 'helpers/decimal'
import Decimal from 'decimal.js'
import { StorageLocationSuggestionStore } from '../../../../store/StorageLocationSuggestion'
import { StockRuleset } from '../../../../store/StockRuleset'


@observer
export default class StorageLocationSelect extends Component {
    static propTypes = {
        articleType: PropTypes.instanceOf(ArticleType).isRequired,
        warehouses: PropTypes.arrayOf(PropTypes.instanceOf(Warehouse).isRequired).isRequired,
        onChange: PropTypes.func,
        onChangeModel: PropTypes.func,
        storageLocation: PropTypes.instanceOf(StorageLocation),
        defaultWarehouse: PropTypes.instanceOf(Warehouse),
        showWarehouse: PropTypes.bool,
        suggestLocation: PropTypes.bool,
        positiveStock: PropTypes.bool,
        stockRuleset: PropTypes.instanceOf(StockRuleset),
        quantity: PropTypes.number,
        rounding: PropTypes.number,
    }

    static defaultProps = {
        positiveStock: false,
        defaultWarehouse: null,
        showWarehouse: true,
        storageLocation: null,
        suggestLocation: true,
        stockRuleset: null,
        quantity: Decimal(1),
    }

    @observable suggestedStorageLocationStore = new StorageLocationSuggestionStore({
        relations: ['failedRules', 'passedRules'],
    })

    @observable locationSuggestion = null
    @observable warehouse = this.props.defaultWarehouse && this.props.defaultWarehouse.id
    @observable selectedStorageLocation = new StorageLocation()
    @observable suggestLocation = this.props.suggestLocation && this.props.stockRuleset

    refetchLocationSuggestions(warehouse) {
        const { articleType, quantity, positiveStock, stockRuleset } = this.props
        this.suggestedStorageLocationStore.params = {
            article_type: articleType.id,
            batch_size: humanReadable(quantity),
            positive_stock: positiveStock,
            stock_ruleset: this.suggestLocation ? stockRuleset.id : null,
        }
        if (warehouse) {
            this.suggestedStorageLocationStore.params['.warehouse.id'] = warehouse
        }

        this.suggestedStorageLocationStore.fetch().then(() => {
            if (this.suggestedStorageLocationStore.models.length > 0){
            this.locationSuggestion = this.suggestedStorageLocationStore.models[0]
            }
            else{
                this.locationSuggestion.clear()
            }
        })
    }

    componentDidMount() {
        const { defaultWarehouse } = this.props
        this.refetchLocationSuggestions(defaultWarehouse?.id)

    }


    focus() {
        if (this.warehouse) {
            this.storageLocationSelect.handleFocus()
        } else {
            this.warehouseSelect.handleFocus()
        }
    }

    render() {
        const { articleType, warehouses, onChange, onChangeModel, defaultWarehouse, showWarehouse, rounding, storageLocation } = this.props
        return (
            <div style={{ width:'100%' }}>
                {
                    this.suggestLocation && this.suggestedStorageLocationStore.isLoading &&
                    <Message
                        size='mini' icon color={'green'} >
                        <Loader active inline='centered' size='small'/>
                    </Message>
                }
                {this.locationSuggestion &&
                    <Message
                        size='tiny' icon color={'green'}
                        hidden={!this.suggestLocation || !this.locationSuggestion} >
                        <h4 data-test-suggested-location style={{ margin: '0px' }}>{t('storageLocation.suggestedLocation')} {this.locationSuggestion.code}</h4>
                    </Message>
                }
                <Form.Group widths="equal" style={{ margin:'0px' }}>
                {showWarehouse && (
                    <TargetSelect data-test-warehouse-select noLabel
                        type="int"
                        value={this.warehouse}
                        onChange={action((warehouse) => {
                            this.warehouse = warehouse
                            this.selectedStorageLocation.clear()
                            this.refetchLocationSuggestions(warehouse)
                            this.storageLocationSelect.handleFocus()
                        })}
                        options={warehouses.map((warehouse) => ({
                            value: warehouse.id,
                            text: `${warehouse.code}${warehouse.code !== '' && warehouse.name !== '' ? ' ' : ''}${warehouse.name}`,
                        }))}
                        disabled={!!defaultWarehouse}
                        contentProps={{ ref: (node) => this.warehouseSelect = node }}
                    />
                )}
                <TargetSelect data-test-storage-location-select remote search noLabel skipFetch
                    type="int"
                    store={this.suggestedStorageLocationStore}
                    value={this.selectedStorageLocation.isNew ? storageLocation?.id : this.selectedStorageLocation.id}
                    onChange={action((storageLocationId) => {
                        this.selectedStorageLocation = this.suggestedStorageLocationStore.models.find((sl) => sl.id === storageLocationId)
                        if (onChange){
                            onChange(storageLocationId)
                        }
                        if (onChangeModel){
                            onChangeModel(this.selectedStorageLocation)
                        }
                    })}
                    toOption={storageLocation => {
                        const slx = articleType.storageLocations.find(asl => asl.storageLocation.id === storageLocation.id)
                        const inStockCountSuffix = slx ? ` (${humanReadable(slx.stock, rounding)})` : ''
                        return {
                            value: storageLocation.id,
                            text: `${storageLocation.code}${storageLocation.code !== '' && storageLocation.name !== '' ? ' ' : ''}${storageLocation.name}${inStockCountSuffix}`
                        }
                    }}
                        contentProps={{ ref: (node) => this.storageLocationSelect = node }}
                    />
                </Form.Group>
            </div>
        )
    }
}
