import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { Operator } from './Operator'
import { User } from './User'

export class ExtraTimeBalance extends Model {
  static backendResourceName = 'extra_time_balance'

  @observable id = null
  @observable year = null
  @observable balance = 0
  @observable isAbsence = false
  @observable remarks = ''

  relations() {
    return {
      user: User,
      operator: Operator,
    }
  }
}

export class ExtraTimeBalanceStore extends Store {
  Model = ExtraTimeBalance
  static backendResourceName = 'extra_time_balance'
}
