import React, { Component } from 'react';
import { ViewProps } from '../../helpers/types';
import { extractWorkStation } from '../../helpers/workStation';
import { observable } from 'mobx';
import { WorkStation } from '../../store/WorkStation';

import { observer } from 'mobx-react';
import { getWorkstationView } from '../../helpers/workStationView';

/**
 * The progress overview contains of two parts
 *
 * (1) The operator that is doing a overview. This results in a workStation
 * (2) The View that is displayed to the operator, e.g. what do they see
 *
 * Operators get a
 */
@observer
export default class Overview extends Component<ViewProps> {

    /**
     * Workstation for which we need to show the view
     *
     * null = Not yet loaded
     * undefined = no workstation
     */
    @observable workStation: WorkStation | null | undefined = undefined;

    updateWorkStation = async () => {
        this.workStation = await extractWorkStation(this.props)
    }

    constructor(props) {
        super(props);
        this.updateWorkStation()
    }

    render() {
        const { props } = this;
        if (this.workStation === undefined) {
            return <></>
        }

        const ProgresssView = getWorkstationView(this.workStation);

        // @ts-ignore
        return <ProgresssView key={props.match.workStation} workStation={this.workStation}  {...props} />
    }

}
