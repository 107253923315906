import { observable } from 'mobx'
import { Store } from 'store/Base';
import { CarrierIntegration } from './CarrierIntegration';
import { Integration } from './Integration';
import { ShippingUnitStore } from './ShippingUnit';

export class TransmissionIntegration extends CarrierIntegration {
    static backendResourceName = 'transmission_integration';
    static omitFields = ['active'];

    @observable id = null;
    @observable active = false;

    @observable active = false;
    @observable username = null;
    @observable password = null;
    @observable depot = null;
    @observable shipper = null;
    @observable shippingLabelTemplate = '';
    @observable contentLabelTemplate = '';


    relations() {
      return {
        integration: Integration,
        shippingUnits: ShippingUnitStore,
      }
    }

}

export class TransmissionIntegrationStore extends Store {
    static backendResourceName = 'transmission_integration';
    Model = TransmissionIntegration;
  }