import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'semantic-ui-react'
import { observable, reaction, computed } from 'mobx'
import { Editor, convertFromRaw, convertToRaw, EditorState, RichUtils } from 'draft-js'
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js'
import { TargetBase } from '@code-yellow/spider'
import styled from 'styled-components'
import { theme } from 'styles'

const EditorContainer = styled.div`
  position: relative;

  > .DraftEditor-root {
    background: transparent;
    margin: 0;
    padding: 0;
    -webkit-appearance: none;
    tap-highlight-color: rgba(255, 255, 255, 0);
    outline: none;
    color: rgba(0, 0, 0, 0.87);
    -webkit-box-shadow: 0em 0em 0em 0em transparent inset;
    box-shadow: 0em 0em 0em 0em transparent inset;
    -webkit-transition: color 0.1s ease, border-color 0.1s ease;
    transition: color 0.1s ease, border-color 0.1s ease;
    font-size: 1em;
    line-height: 1.2857;
    resize: vertical;

    > .DraftEditor-editorContainer {
      background: transparent;
    }
  }

  ${({ hasFocus }) =>
    hasFocus
      ? `
        .DraftEditor-root {
            color: rgba(0, 0, 0, 0.95);
            -webkit-box-shadow: 0px 0em 0em 0em rgba(34, 36, 38, 0.35) inset;
            box-shadow: 0px 0em 0em 0em rgba(34, 36, 38, 0.35) inset;
            -webkit-appearance: none;
        }
    `
      : ''}
`

const StylesContainer = styled.div`
  display: inline-block;
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 0.25em 0.375em;
  background-color: #fff;
  border-radius: 999px;
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 0;
  bottom: -0.25rem;
  transform: translateY(100%);
  transition: opacity 300ms ease;
  z-index: 10;
  ${({ visible }) =>
    visible
      ? `
        opacity: 1;
    `
      : `
        opacity: 0;
        pointer-events: none;
    `}
`

const StyleButton = styled.span`
  display: inline-block;
  padding: 0 0.375em;
  font-weight: bold;
  font-size: 0.8em;
  ${({ disabled, active }) => {
    if (disabled) {
      return `
        color: #909090;
    `;
    }
    if (active) {
      return `
        color: ${theme.primaryColor};
        cursor: pointer;
      `
    }
    return `
        color: #707070;
        cursor: pointer;
    `
  }}
`

const STYLE_TYPES = {
  'header-one': 'block',
  'header-two': 'block',
  'header-three': 'block',
  'header-four': 'block',
  'header-five': 'block',
  'header-six': 'block',
  blockquote: 'block',
  'unordered-list-item': 'block',
  'ordered-list-item': 'block',
  'code-block': 'block',

  BOLD: 'inline',
  ITALIC: 'inline',
  UNDERLINE: 'inline',
}

const STYLE_ICONS = {
  blockquote: 'quote left',
  'unordered-list-item': 'list ul',
  'ordered-list-item': 'list ol',
  'code-block': 'code',

  BOLD: 'bold',
  ITALIC: 'italic',
  UNDERLINE: 'underline',
}

const STYLES = [
  'header-one',
  'header-two',
  'header-three',
  'header-four',
  'header-five',
  'header-six',
  'blockquote',
  'unordered-list-item',
  'ordered-list-item',
  'code-block',
  'BOLD',
  'ITALIC',
  'UNDERLINE',
]

const INLINE_STYLES = STYLES.filter((style) => STYLE_TYPES[style] === 'inline')

export default class TargetMarkdown extends TargetBase {
  static propTypes = {
    ...TargetBase.propTypes,
    value: PropTypes.string,
    inline: PropTypes.bool,
    styles: PropTypes.arrayOf(PropTypes.string.isRequired),
    disabled: PropTypes.boolean,
  }

  static defaultProps = {
    ...TargetBase.defaultProps,
    inline: false,
    disabled: false,
  }

  @computed get styles() {
    const { styles, inline } = this.props
    if (styles) {
      return styles
    } else if (inline) {
      return INLINE_STYLES
    } else {
      return STYLES
    }
  }

  constructor(...args) {
    super(...args)

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onKeyCommand = this.onKeyCommand.bind(this)
    this.renderStyleButton = this.renderStyleButton.bind(this)
  }

  @observable editorState = EditorState.createWithContent(this.value)
  @observable hasFocus = false
  ignoreReaction = false

  @computed get blockStyle() {
    return this.editorState.getCurrentContent().getBlockForKey(this.editorState.getSelection().getStartKey()).getType()
  }

  @computed get inlineStyle() {
    return this.editorState.getCurrentInlineStyle()
  }

  onFocus() {
    this.hasFocus = true
  }

  onBlur() {
    this.hasFocus = false
  }

  componentDidMount() {
    this.valueChangeReaction = reaction(
      () => this.value,
      (value) => {
        const currentValue = this.toTarget(this.editorState.getCurrentContent())
        const targetValue = this.getValueBase()
        if (currentValue !== targetValue) {
          this.editorState = EditorState.push(this.editorState, value, 'insert-fragment')
        }
      }
    )
  }

  componentWillUnmount() {
    this.valueChangeReaction()
  }

  onChange(editorState) {
    this.editorState = editorState
    super.onChange(editorState.getCurrentContent())
  }

  onKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      this.onChange(newState)
      return 'handled'
    } else {
      return 'not-handled'
    }
  }

  fromStore(value) {
    return value || ''
  }

  toStore(value) {
    return value === '' ? undefined : value
  }

  fromTarget(value) {
    return convertFromRaw(markdownToDraft(super.fromTarget(value)))
  }

  toTarget(value) {
    return super.toTarget(draftToMarkdown(convertToRaw(value)))
  }

  onStyleToggle(style) {
    if (STYLE_TYPES[style] === 'block') {
      this.onChange(RichUtils.toggleBlockType(this.editorState, style))
    } else if (STYLE_TYPES[style] === 'inline') {
      this.onChange(RichUtils.toggleInlineStyle(this.editorState, style))
    }
  }

  renderStyleButton(style) {
    const { disabled } = this.props

    const active = this.hasFocus && (
      (STYLE_TYPES[style] === 'block' && this.blockStyle === style) ||
      (STYLE_TYPES[style] === 'inline' && this.inlineStyle.has(style)))

    const props = {}

    if (!disabled) {
      props.onMouseDown = (e) => {
        e.preventDefault()
        this.onStyleToggle(style)
      }
    }

    return (
      <StyleButton key={style} active={active} disabled={disabled} {...props}>
        {STYLE_ICONS[style] ? <Icon name={STYLE_ICONS[style]} /> : t(`form.draftJs.style.${style}.label`)}
      </StyleButton>
    )
  }

  renderContent({ disabled, ...props }) {
    delete props.styles
    return (
      <EditorContainer hasFocus={this.hasFocus}>
        <Editor
          editorState={this.editorState}
          onChange={this.onChange}
          handleKeyCommand={this.onKeyCommand}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          readOnly={disabled}
          {...props}
        />
        <StylesContainer visible={this.hasFocus}>{this.styles.map(this.renderStyleButton)}</StylesContainer>
      </EditorContainer>
    )
  }
}
