import { observable, computed } from 'mobx'
import { Model, Store } from 'store/Base'
import { ExactGlobeAccount } from './Account'
import { ExactGlobeSalesOrderLineStore } from './SalesOrderLine'

export class ExactGlobeSalesOrder extends Model {
  static backendResourceName = 'exact_globe_sales_order_header'
  static idColor = 'red'
  static idIcon = 'exact'

  getLink(props = {}) {
    return super.getLink({
      target: '_blank',
      ...props,
    })
  }

  getTarget() {
    return '_blank'
  }

  @observable id = null
  @observable salesOrderNumber = null;

  // Annotations
  @observable resourceFullName = ''

  @computed
  get _labelContent() {
    return this.salesOrderNumber
  }


  toBackend(...args) {
    const res = super.toBackend(...args)
    delete res.resourceFullName
    return res
  }

  relations() {
    return {
      lines: ExactGlobeSalesOrderLineStore,
      orderedBy: ExactGlobeAccount,
    }
  }

  casts() {
    return {
    }
  }
}

export class ExactGlobeSalesOrderStore extends Store {
  static backendResourceName = 'exact_globe_sales_order_header'
  Model = ExactGlobeSalesOrder
}
