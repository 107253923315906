import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observable, action, reaction, computed } from 'mobx'
import { observer } from 'mobx-react'
import { RightDivider, TargetTextInput, TargetSelect, TargetMultiTextInput, TargetNumberInput, TargetCheckbox, InfoIcon } from '@code-yellow/spider'
import { Icon, Form, Button, Popup, Dropdown } from 'semantic-ui-react'
import styled from 'styled-components'
import { t } from 'i18n'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { getAllowedKeys } from './Print'
import { getFakeProductionRequest } from 'container/ArticleType/Edit'

// components
import { EmptyMessageContainer } from 'component/AdminOverview'
import TargetIcon from 'component/TargetIcon'
import TargetSerialNumberFormat from 'component/TargetSerialNumberFormat'
import { MetafieldsProvider, MetafieldsContext } from 'component/Metafields'
// end components

// helpers
import { isFeatureFlagEnabled } from 'helpers/featureFlags'
import InfoPopup from '../../../helpers/infoPopup'
// end helpers

// stores
import { Model } from 'store/Base'
import { Step } from 'store/Step'
import { Form as FormModel } from 'store/Form'
import { FormField, FormFieldStore } from 'store/FormField'
import { BatchType } from 'store/BatchType'
import { ArticleTypeStore } from 'store/ArticleType'
import { DataSourceStore } from 'store/DataSource'
import { ScanConstraint } from 'store/ScanConstraint'
import { ArticleType } from '../../../store/ArticleType'
import StockRuleSelect from '../../../component/Process/StockRuleSelect'
// end stores

class SpecialTargetSelect extends TargetSelect {
  getOptions(props) {
    return [
      ...props.isTemplate ? [{
        value: -1,
        text: (
          <>
            {t('formField.field.articleType.self')}
            <Popup hoverable
              trigger={<RightInfoIcon name="info circle" />}
              content={t('formField.field.articleType.selfInfo')}
            />
          </>
        ),
      }] : [],

      // Remove the dynamic option with value -1.
      ...super.getOptions(props).filter(option => option.value !== -1),
    ];
  }
}

const ButtonContainer = styled.div`
  padding-top: 0.5rem;
  text-align: center;
`

const ButtonFormField = styled(Form.Field)`
  width: auto !important;
  flex: 0 0 auto !important;
  height: calc(2 * 0.78571429em + 1em);
  display: flex;
  align-items: center;
  > .ui.button {
    margin: 1px 0 !important;
  }
  > i.icon {
    line-height: 1;
  }
`

const StyledFormGroup = styled(Form.Group)`
  > .field > label {
    font-weight: normal !important;
    font-size: 0.8em !important;
    height: 1.2em;
    line-height: 1.2em;
  }
  margin: 0 -0.5em !important;
  padding-bottom: 1em;
  &:focus {
    outline: unset;
    border: unset;
  }

  align-items: start;
`

const SlugTargetTextInput = styled(TargetTextInput)`
  > .ui.input > input {
    font-family: monospace !important;
  }
`

const VariableName = styled.span`
  font-family: monospace;
`


const RightInfoIcon = styled(InfoIcon)`
  position: absolute;
  right: 0.75em;
`

const WideTextSelect = styled(SpecialTargetSelect)`
  .dropdown > .text {
    position: relative;
    width: 100%;
    > ${RightInfoIcon} {
      right: 0;
    }
  }
`

const AlignedTargetCheckbox = styled(TargetCheckbox)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(2 * 0.78571429em + 1em);
`

@observer
export class MetafieldEdit extends Component {
  static contextType = MetafieldsContext

  static propTypes = {
    includeParentLevels: PropTypes.bool.isRequired,
    target: PropTypes.instanceOf(Model).isRequired,
    name: PropTypes.string.isRequired,
  }

  @computed get metafields() {
    const { includeParentLevels } = this.props
    return (
      includeParentLevels
        ? [...this.context.parentMetafields, ...this.context.metafields]
        : this.context.metafields
    )
  }

  render() {
    const { target, name } = this.props

    return (
      <TargetSelect
        options={this.metafields.map((metafield) => ({
          value: metafield.getInternalId(),
          text: metafield.name,
        }))}
        type="int"
        value={target[name].getInternalId()}
        onChange={action((metafieldId) => {
          const metafield = this.metafields.find((metafield) => metafield.getInternalId() === metafieldId)
          target[name] = metafield
          target.markChanged(name)
        })}
        name={name}
        {...this.props}
      />
    )
  }
}

const OPERATORS = [
  'eq',
  'neq',
  'lt',
  'lte',
  'gt',
  'gte',
]
export const OPERATOR_ICONS = {
  'eq': 'equals',
  'neq': 'not-equal',
  'lt': 'less-than',
  'lte': 'less-than-equal',
  'gt': 'greater-than',
  'gte': 'greater-than-equal',
}

const FINAL_ORDERING_DIRECTIONS = ['ascending', 'descending'].map((key) => ({
  key,
  text: t(`formField.field.storageLocation.finalOrdering.direction.${key}.label`),
  value: key === 'ascending',
}))

const FINAL_ORDERING_OPTIONS = ['free_capacity', 'sequence_number'].map((key) => ({
  key,
  text: t(`formField.field.storageLocation.finalOrdering.${key}.label`),
  value: key,
}))

@observer
class ScanConstraintEdit extends Component {
  static propTypes = {
    field: PropTypes.instanceOf(FormField).isRequired,
    scanConstraint: PropTypes.instanceOf(ScanConstraint).isRequired,
    disabled: PropTypes.bool,
  }

  @observable productionRequest = getFakeProductionRequest(this.props.field.articleType)

  componentDidMount() {
    this.articleTypeReaction = reaction(
      () => this.props.field.articleType,
      (articleType) => this.productionRequest = getFakeProductionRequest(articleType),
    )
  }

  componentWillUnmount() {
    this.articleTypeReaction()
  }

  render() {
    const { field, scanConstraint, disabled } = this.props
    return (
      <StyledFormGroup data-test-constraint widths="equal">
        <MetafieldsProvider includeParentLevels model={this.productionRequest}>
          <MetafieldEdit includeParentLevels noLabel target={scanConstraint} name="leftMetafield" disabled={disabled} />
        </MetafieldsProvider>
        <ButtonFormField>
          <Popup
            on="click"
            trigger={
              <Button data-test-operator
                type="button"
                icon={OPERATOR_ICONS[scanConstraint.operator]}
                disabled={disabled}
              />
            }
            content={
              <Button.Group>
                {OPERATORS.map((operator) => (
                  <Popup
                    trigger={
                      <Button
                        icon={OPERATOR_ICONS[operator]}
                        active={scanConstraint.operator === operator}
                        onClick={() => scanConstraint.setInput('operator', operator)}
                      />
                    }
                    content={t(`scanConstraint.field.operator.value.${operator}`)}
                  />
                ))}
              </Button.Group>
            }
          />
        </ButtonFormField>
        <MetafieldEdit includeParentLevels noLabel target={scanConstraint} name="rightMetafield" disabled={disabled} />
        <ButtonFormField>
          <Button data-test-delete-constraint
            type="button"
            icon="delete"
            onClick={() => field.scanConstraints.remove(scanConstraint)}
            disabled={disabled}
          />
        </ButtonFormField>
      </StyledFormGroup>
    )
  }
}

@observer
class FormFieldEdit extends Component {
  static propTypes = {
    batchType: PropTypes.instanceOf(BatchType).isRequired,
    field: PropTypes.instanceOf(FormField).isRequired,
    fields: PropTypes.instanceOf(FormFieldStore).isRequired,
    onRemove: PropTypes.func,
    disabled: PropTypes.bool,
    machineEnabled: PropTypes.bool,
    dragHandleProps: PropTypes.object.isRequired,
    isTemplate: PropTypes.bool,
    variable: PropTypes.shape({
      before: PropTypes.bool.isRequired,
      after: PropTypes.bool.isRequired,
    }).isRequired,
    allowedKeys: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired
      })
    ).isRequired,
  }

  static defaultProps = {
    isTemplate: false,
  }

  articleTypeStore = new ArticleTypeStore()
  dataSourceStore = new DataSourceStore()
  @observable isOpen = false

  /**
   * Filter to determine which form fields to show in the
   * @param field
   * @param type
   * @returns {boolean|*|boolean}
   */
  formFieldFilter = (type) => {
    const { batchType, field, variable, fields } = this.props;

    switch (type) {
      case field.type:
        return true;
      case 'bom':
        return batchType.type === 'make' || batchType.type === 'make_template';
      case 'bom_template':
        return batchType.type === 'make' || batchType.type === 'make_template';
      case 'quantity':
        return variable.before && !fields.models.some((f) => f.type === 'quantity');
      default:
        return true;
    }
  }

  addStockRule(field){
    const newRule = field.stockRuleset.rules.add()
    newRule.setInput('order', field.stockRuleset.rules.models.length)
  }

  render() {
    const { machineEnabled, field, disabled, onRemove, dragHandleProps, allowedKeys, isTemplate, variable, batchType } = this.props

    const targetProps = {
      key: field.type,
      noLabel: true,
      disabled,
      errors: (field.actuallyUsefulErrors.__all__ || [])
        .filter(({ code }) => code === 'two_targets')
        .map(({ message }) => message),
      width: 8,
    }

    let label
    if (field.type === 'metafield') {
      label = (
        <TargetTextInput noLabel disabled
          key="metafieldLabel"
          name="label"
          value={field.metafield.name}
          errors={(field.actuallyUsefulErrors.__all__ || [])
            .filter(({ code }) => code === 'unique_together')
            .map(({ message }) => message)}
          width={5}
        />
      )
    } else {
      label = (
        <TargetTextInput noLabel
          key="fieldLabel"
          placeholder={t('formField.label.placeholder')}
          target={field}
          name="label"
          disabled={disabled}
          errors={(field.actuallyUsefulErrors.__all__ || [])
            .filter(({ code }) => code === 'unique_together')
            .map(({ message }) => message)}
          width={5}
        />
      )
    }

    let fieldContent = (
      <Form.Field width={targetProps.width}>
        {!targetProps.noLabel && <label>{targetProps.label}</label>}
        <input disabled />
      </Form.Field>
    )
    if (field.type === 'article_type') {
      fieldContent = (
        <>
          <WideTextSelect data-test-article-type-select
            remote
            {...targetProps}
            isTemplate={isTemplate}
            target={field}
            name="articleType"
            store={this.articleTypeStore}
            alwaysConvertValue
            toOption={articleType => ({
              value: articleType.id,
              text: `${articleType.code} | ${articleType.name}`,
            })}
            value={field.articleTypeSelf ? new ArticleType({ id: -1 }) : field.articleType}
            onChange={action((articleType) => {
              if (articleType) {
                field.setInput('articleType', articleType)
                field.setInput('articleTypeSelf', false)
              } else {
                field.setInput('articleType', null)
                field.setInput('articleTypeSelf', true)
              }
            })}
          />
          <TargetCheckbox
            rightLabel
            {...targetProps}
            name="articleTypeBatchSize"
            target={field}
            disabled={disabled}
          />
          <ButtonFormField>
            <Popup
              trigger={<Button data-test-add-constraint type="button" icon="question" onClick={() => field.scanConstraints.add()} disabled={disabled} />}
              content={t('formField.field.scanConstraints.add')}
            />
          </ButtonFormField>
        </>
      )
    } else if (field.type === 'choice') {
      fieldContent = <TargetMultiTextInput {...targetProps} target={field} name="options" />
    } else if (field.type === 'measure') {
      fieldContent = (
        <React.Fragment>
          <TargetNumberInput
            allowDecimal
            {...targetProps}
            width={targetProps.width / 2}
            target={field}
            name="measureMin"
            placeholder={t('formField.field.measureMin.label')}
          />
          <TargetNumberInput
            allowDecimal
            {...targetProps}
            width={targetProps.width / 2}
            target={field}
            name="measureMax"
            placeholder={t('formField.field.measureMax.label')}
          />
        </React.Fragment>
      )
    } else if (field.type === 'format') {
      fieldContent = <TargetSerialNumberFormat
        allowArticleType={batchType.type.endsWith('_template')}
        allowAnything {...targetProps}
        target={field} name="valueFormat"
      />
    } else if (field.type === 'variable') {
      fieldContent = (
        <TargetSelect
          {...targetProps}
          target={field}
          name="variableName"
          options={allowedKeys.map(({ key }) => ({
            value: key,
            text: <VariableName>{key}</VariableName>,
          }))}
        />
      )
    } else if (field.type === 'quantity') {
      fieldContent = (
        <TargetNumberInput
          {...targetProps}
          target={field}
          name="quantityUnitWeight"
          errors={field.backendValidationErrors?.quantity_unit_weight_unit}
          placeholder={t('formField.field.quantityUnitWeight.label')}
          contentProps={{
            labelPosition: 'right',
            label: (
              <Dropdown
                value={field.quantityUnitWeightUnit}
                onChange={(e, { value }) => field.setInput('quantityUnitWeightUnit', value)}
                options={FormField.QUANTITY_UNIT_WEIGHT_UNITS.map((value) => ({ value, text: value }))}
                disabled={disabled}
              />
            ),
          }}
        />
      )
    } else if (field.type === 'metafield') {
      fieldContent = <MetafieldEdit target={field} name="metafield" {...targetProps} />
    } else if (field.type === 'best_before_date') {
      fieldContent = <TargetNumberInput
        {...targetProps}
        target={field}
        name="bestBeforePeriod"
        placeholder={t('formField.field.bestBefore.period')}
        viewTo={
          <React.Fragment>
            <RightDivider />
            <InfoPopup content={t('formStepField.field.type.bestBeforePeriodInfo')}/>
          </React.Fragment>
        }
      />
    } else if (field.type === 'material_plan_task' && isFeatureFlagEnabled('oee_track_operator_time')) {
      fieldContent = <TargetCheckbox
        rightLabel
        {...targetProps}
        name="materialPlanTaskIsTimed"
        target={field}
        disabled={disabled}
      />
    }
    else if (field.type === 'storage_location') {
      fieldContent = (
        <>
          <TargetSelect
            data-test-ruleset-final-ordering
            noLabel
            value={field.stockRuleset.finalOrdering}
            options={FINAL_ORDERING_OPTIONS}
            onChange={(value) => field.stockRuleset.setInput('finalOrdering', value)}
            disabled={disabled}
          />
          <TargetSelect
            data-test-ruleset-final-ordering-direction
            noLabel
            value={field.stockRuleset.finalOrdering ? field.stockRuleset.finalOrderingAscending : null}
            options={FINAL_ORDERING_DIRECTIONS}
            onChange={(value) => field.stockRuleset.setInput('finalOrderingAscending', value)}
            disabled={!field.stockRuleset.finalOrdering || disabled}
          />
          <ButtonFormField>
            <Popup
              trigger={<Button data-test-add-stock-rule type="button" icon="boxes" onClick={() => this.addStockRule(field)} disabled={disabled} />}
              content={t('formField.field.storageLocation.addRule')}
            />
          </ButtonFormField>
        </>
      )
    }

    let printButton
    if (field.type === 'metafield') {
      printButton = (
        <ButtonFormField>
          <Button disabled type="button" icon="print" />
        </ButtonFormField>
      )
    } else {
      const forceOpen = 'slug' in field.backendValidationErrors
      printButton = (
        <Popup
          onOpen={() => {this.isOpen = true}}
          onClose={() => {this.isOpen = false}}
          open={this.isOpen || forceOpen}
          on="click"
          trigger={
            <ButtonFormField error='wuw'>
              <Button type="button" icon="print" />
            </ButtonFormField>
          }
          content={
            <Form>
              <SlugTargetTextInput
                autoFocus
                target={field}
                name="slug"
                disabled={disabled}
                fromTarget={(value) => (value === null ? '' : value)}
                toTarget={(value) => (value === '' ? null : value)}
                onChange={(value) => {
                  this.isOpen = true // afterChange backendValidationErrors are gone. Keep open afterchange
                  field.setInput('slug', value)
                }}
                viewTo={
                  <React.Fragment>
                    <RightDivider />
                    <InfoPopup content={t('formStepField.field.slug.info')}/>
                  </React.Fragment>
                }
              />
            </Form>
          }
        />
      )
    }

    return (
      <React.Fragment>
        <StyledFormGroup data-test-form-field-edit={field.cid}>
          {label}
          <WideTextSelect data-test-select-formfield
            noLabel
            target={field}
            name="type"
            options={
              FormField.TYPES
                .filter(this.formFieldFilter)
                .map((type) => ({
                  value: type,
                  text: (
                    <>
                      {t(`formStepField.field.type.value.${type}`)}
                      {type === 'quantity' && (
                        <Popup hoverable
                          trigger={<RightInfoIcon name="info circle" />}
                          content={t('formField.field.type.quantityInfo')}
                        />
                      )}
                      {type === 'best_before_date' && (
                        <Popup hoverable
                          trigger={<RightInfoIcon name="info circle" />}
                          content={t('formField.field.type.bestBeforePeriodInfo')}
                        />
                      )}
                    </>
                  ),
                  'data-test-add-form-type': type,
                }))
            }
            disabled={disabled}
            width={3}
            style={{ minWidth: 'unset' }}
            afterChange={(value) => {
              if (value === 'choice' && field.options === null) {
                field.options = []
              }
              if (value === 'format' && field.valueFormat === null) {
                field.valueFormat = []
              }
            }}
            errors={[
              ...field.type === 'quantity' && !variable.before ? [
                t('articleType.edit.quantityFieldWithoutVariableQuantity'),
              ] : [],
            ]}
          />
          <React.Fragment key={field.type}>{fieldContent}</React.Fragment>
          {machineEnabled && (
            <React.Fragment>
              <TargetSelect
                noLabel
                remote
                search
                target={field}
                name="dataSource"
                store={this.dataSourceStore}
                options={[
                  { value: null, text: '-' },
                  ...this.dataSourceStore.map((ds) => ({
                    value: ds.id,
                    text: ds.name,
                  })),
                ]}
                width={5}
                style={{ minWidth: 'unset' }}
                disabled={disabled}
              />
              <TargetSelect
                noLabel
                target={field}
                name="dataName"
                options={
                  field.dataSource
                    ? field.dataSource.dataNames.map((name) => ({
                      value: name,
                      text: name,
                    }))
                    : []
                }
                width={3}
                style={{ minWidth: 'unset' }}
                disabled={disabled}
              />
            </React.Fragment>
          )}
          {printButton}
          <Popup
            trigger={
              <AlignedTargetCheckbox noLabel
                target={field}
                name="required"
              />
            }
            content={t('formField.field.required.label')}
          />
          <ButtonFormField>
            <Button type="button" icon="delete" onClick={onRemove} disabled={disabled} />
          </ButtonFormField>
          <ButtonFormField>
            <Icon name="arrows alternate vertical" {...dragHandleProps} />
          </ButtonFormField>
        </StyledFormGroup>
        {field.scanConstraints.map((scanConstraint) => (
          <ScanConstraintEdit
            key={scanConstraint.cid}
            field={field}
            scanConstraint={scanConstraint}
            disabled={disabled}
          />
        ))}
        <StockRuleSelect target={field} disabled={disabled}/>
      </React.Fragment>
    )
  }
}

@observer
export class FormEdit extends Component {
  static propTypes = {
    batchType: PropTypes.instanceOf(BatchType).isRequired,
    form: PropTypes.instanceOf(FormModel).isRequired,
    disabled: PropTypes.bool,
    machineEnabled: PropTypes.bool,
    isTemplate: PropTypes.bool,
    variable: PropTypes.shape({
      before: PropTypes.bool.isRequired,
      after: PropTypes.bool.isRequired,
    }).isRequired,
    allowedKeys: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired
      })
    ).isRequired,
    step: PropTypes.instanceOf(Step).isRequired,
    label: PropTypes.string,
  }

  static defaultProps = {
    disabled: false,
    machineEnabled: false,
    isTemplate: false,
  }

  constructor(...args) {
    super(...args)

    this.renderField = this.renderField.bind(this)
    this.addField = this.addField.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)
  }

  componentDidMount() {
    const { step } = this.props
    this.setOrderingReaction = reaction(
      () => step.formStep.fields.map(({ cid }) => cid).join(','),
      action(() => {
        let i = 0
        // eslint-disable-next-line
        for (const field of step.formStep.fields.models) {
          field.setInput('ordering', i++)
        }
      })
    )
  }

  componentWillUnmount() {
    this.setOrderingReaction()
  }

  renderField(field, i) {
    const { machineEnabled, form, batchType, disabled, allowedKeys, isTemplate, variable } = this.props

    return (
      <Draggable key={field.cid} draggableId={`form_field_${field.cid}`} index={i}>
        {(provided, snapshot) => (
          <div data-test-form-field={field.id} ref={provided.innerRef} {...provided.draggableProps}>
            <FormFieldEdit
              key={field.cid}
              batchType={batchType}
              field={field}
              fields={form.fields}
              disabled={disabled}
              onRemove={() => form.fields.remove(field)}
              machineEnabled={machineEnabled}
              dragHandleProps={provided.dragHandleProps}
              allowedKeys={allowedKeys}
              isTemplate={isTemplate}
              variable={variable}
            />
          </div>
        )}
      </Draggable>
    )
  }

  addField() {
    const { form } = this.props
    form.fields.add({ type: 'text' })
  }

  @action onDragEnd(result) {
    if (!result.destination) {
      return
    }

    const { form } = this.props

    // Juggle models.
    const [field] = form.fields.models.splice(result.source.index, 1)
    form.fields.models.splice(result.destination.index, 0, field)

    // Update ordering attr.
    form.fields.forEach((ff, index) => ff.setInput('ordering', index))
  }

  render() {
    const { label, form, disabled } = this.props

    return (
      <Form.Field data-test-step-form={form.id}>
        {label && <label>{label}</label>}
        {form.fields.length === 0 ? (
          <EmptyMessageContainer>{t('form.field.fields.empty')}</EmptyMessageContainer>
        ) : (
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId={`form_${form.cid}`}>
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {form.fields.map(this.renderField)}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        {!disabled && (
          <ButtonContainer>
            <Button
              primary
              data-test-add-field-button
              icon="add"
              labelPosition="left"
              type="button"
              content={t('form.edit.addFieldButton')}
              onClick={this.addField}
            />
          </ButtonContainer>
        )}
      </Form.Field>
    )
  }
}

@observer
export default class FormStepEdit extends Component {
  static propTypes = {
    step: PropTypes.instanceOf(Step).isRequired,
    steps: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    machineEnabled: PropTypes.bool,
    batchType: PropTypes.instanceOf(BatchType).isRequired,
    variable: PropTypes.shape({
      before: PropTypes.bool.isRequired,
      after: PropTypes.bool.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    disabled: false,
    machineEnabled: false,
  }

  static contextType = MetafieldsContext

  @computed get allowedKeys() {
    const { step, steps } = this.props
    return getAllowedKeys(step, steps, this.context)
  }

  render() {
    const { batchType, step, disabled, machineEnabled, variable } = this.props

    return (
      <React.Fragment>
        <TargetIcon target={step.formStep} name="icon" disabled={disabled} />
        <FormEdit
          label={t('formStep.field.form.label')}
          batchType={batchType}
          form={step.formStep.form}
          disabled={disabled}
          machineEnabled={machineEnabled}
          allowedKeys={this.allowedKeys}
          isTemplate={batchType.type.endsWith('_template')}
          variable={variable}
        />
      </React.Fragment>
    )
  }
}
