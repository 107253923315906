import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ExactIntegration } from 'store/ExactIntegration'
import { ItemAssortmentValue } from '../ItemAssortmentValue'
import { ExactItemAssortment } from './ItemAssortment'

export class ExactItemAssortmentProperty extends Model {
  static backendResourceName = 'exact_item_assortment_property'

  @observable id = null
  @observable uuid = null
  @observable code = ''
  @observable description = ''

  relations() {
    return {
      integration: ExactIntegration,
      itemAssortmentValue: ItemAssortmentValue,
      exactItemAssortment: ExactItemAssortment,
    }
  }
}

export class ExactItemAssortmentPropertyStore extends Store {
  static backendResourceName = 'exact_item_assortment_property'
  Model = ExactItemAssortmentProperty
}
