import { StorageLocation, StorageLocationStore } from './StorageLocation'
import { StockRuleStore } from './StockRule'

export class StorageLocationSuggestion extends StorageLocation {

  relations() {
    return Object.assign({}, {
        failedRules: StockRuleStore,
        passedRules: StockRuleStore,
    }, super.relations());
  }
}

export class StorageLocationSuggestionStore extends StorageLocationStore {
  Model = StorageLocationSuggestion

  url() {
    return 'storage_location/suggested_locations/';
  }
}
