import { observable } from 'mobx'
import { Model, Store } from 'store/Base';


export class Factory extends Model {
  static backendResourceName = 'factory'

  @observable id = null
  @observable name = ''
  @observable timezone = 'Europe/Amsterdam'
  @observable utcOffset = null
}

export class FactoryStore extends Store {
  static backendResourceName = 'factory'
  Model = Factory
}
