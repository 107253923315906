import Decimal from 'decimal.js';

export const DAYS = 'days'
export const MINUTES = 'minutes'
export const HOURS = 'hours'
export const SECONDS = 'seconds'
export const MILLISECONDS = 'milliseconds'

/**
 * function to convert input time into minutes
 * paramenter baseTime: time given as input
 * paramenter baseUnit: time unit given as input
 * return value: converted time in minutes as output */
export function convertToMinutes(baseTime, baseUnit) {

  switch (baseUnit) {
    case SECONDS:
      return baseTime / 60
    case MINUTES:
      return baseTime
    case HOURS:
      return baseTime * 60
    case DAYS:
      return baseTime * 1440
    default:
      return null
  }

}

export function toMinutes(valueInCustomTimeUnit, currentTimeUnit) {

  if (!Decimal.isDecimal(valueInCustomTimeUnit)) {
    valueInCustomTimeUnit = new Decimal(valueInCustomTimeUnit)
  }

  switch (currentTimeUnit) {
    case HOURS:
      return valueInCustomTimeUnit.mul(60)
    case MINUTES:
      return valueInCustomTimeUnit
    case SECONDS:
      return valueInCustomTimeUnit.div(60)
    case MILLISECONDS:
      return valueInCustomTimeUnit.div(60).div(1000)
    default:
      return null
  }
}

