import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ProductionRequest } from './ProductionRequest'
import { PurchaseOrderLine } from './PurchaseOrderLine'
import { InShipment } from './InShipment'

export class InShipmentLine extends Model {
  static backendResourceName = 'in_shipment_line'

  @observable id = null

  relations() {
    return {
      inShipment: InShipment,
      purchaseOrderLine: PurchaseOrderLine,
      productionRequest: ProductionRequest,
    }
  }
}

export class InShipmentLineStore extends Store {
  static backendResourceName = 'in_shipment_line'

  Model = InShipmentLine
}
