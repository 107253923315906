import { observable, computed } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { ExactIntegration } from './ExactIntegration'
import moment from 'moment'
import { ProductionRequest } from './ProductionRequest'
import { Batch } from './Batch'
import { Warehouse } from './Warehouse'

export const EXACT_SYNCER_OPTIONS = [
  'shop_order', 'shop_order_receipt',
  'goods_receipt', 'goods_delivery',
  'shop_order_reversal', 'sales_return',
  'stock_count', 'stock_count_reversal',
  'warehouse_transfer', 'process_warehouse_transfer',
  'material_issue', 'byproduct_receipt',
  'shop_order_release', 'byproduct_reversal',
  'stock_serial_number', 'purchase_order_line',
  'purchase_return', 'warehouse',
]

export class ExactSyncRequest extends Model {
  static backendResourceName = 'exact_sync_request'

  static omitFields = ['erpId']
  static idPrefix = 'SR'
  static idColor = 'red'
  static idIcon = 'download'

  @observable id = null
  @observable syncer = null
  @observable performedAt = null
  @observable failedAt = null;
  @observable createdAt = null
  @observable errorReason = null
  @observable payload = null
  @observable status = null;
  @observable update = false;
  @observable erpId = '';

  getUrl() {
    return `/assets/sync-request/overview?.exact_sync_request.id=${this.id}`
  }

  casts() {
    return {
      createdAt: Casts.datetime,
      performedAt: Casts.datetime,
      failedAt: Casts.datetime,
    }
  }

  relations() {
    return {
      integration: ExactIntegration,
      productionRequest: ProductionRequest,
      batch: Batch,
      warehouse: Warehouse,
    }
  }

  @computed get status() {
    if (this.performedAt) {
      return 'done';
    }
    if (this.failedAt) {
      return 'failed';
    }
    return 'open';
  }

  async retry() {
    this.failedAt = null;
    this.performedAt = null;
    this.errorReason = null;
    return this.save()
  }

  async finish() {
    this.failedAt = null;
    this.performedAt = moment();
    return this.save()
  }
}

export class ExactSyncRequestStore extends Store {
  static backendResourceName = 'exact_sync_request'
  Model = ExactSyncRequest
}
