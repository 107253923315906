import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { NestRequest } from '../store/Workstation/NestRequest'
import { Duration } from 'luxon'
import { Caption1 } from './PerformanceStyles'
import { NestRequestDrawingImage } from 'component/ProductionRequest/DrawingImage'

/**
 * Displays the details of the nest which are listed in the NestRequest
 * The default render will be compatible with a perform view, where it slots into an existing <tbody> element;
 * use the helper function below this class to render it as a standalone table
 */
export default class NestRequestDetails extends Component {
  static propTypes = {
    nestRequest: PropTypes.instanceOf(NestRequest).isRequired,
    isSmallDescription: PropTypes.instanceOf(Boolean) // you can set this if you want the condensed version
  }

  static defaultProps = {
    isSmallDescription: false,
 }


  render() {
    const { nestRequest, isSmallDescription } = this.props
    const details = ['material', 'thickness', 'dimensions', 'runTime']
    const duration = Duration.fromMillis(nestRequest.runTime * 1000)
    const runTime = duration.toFormat('hh:mm:ss')
    const detailsLength = details.length

    // This is the standard functionality that is used in the step
    const normalVersion =
    <tr>
      <td>
        <b>{t('nestRequest.label')}</b>
      </td>
      <td>
        <tbody>
          {details.map(detail => (
            <tr>
              <td style={{ 'min-width':'100px' }}>
                <b>{t(`nestRequest.details.${detail}`)}</b>
              </td>
              <td data-test-nest-request-detail={detail}>
                {detail === 'runTime' ? runTime : nestRequest[detail]}
              </td>
            </tr>
          ))}
        </tbody>
        <NestRequestDrawingImage nestRequest={nestRequest} size='large'></NestRequestDrawingImage>
      </td>
    </tr>

    // this is the condensed version that is used in the overview
    const condensedVersion =
    (
      <>
        <Caption1>
          {details.map((detail, i) => (
            //This makes sure that the '|' dividers are only added if the detail actually exists
            //We also check if we are at the last detail we don't add an extra divider
            !! nestRequest[detail] ?
              (
              <span>
                <span data-test-nest-request-detail={detail}>{detail === 'runTime' ? runTime : nestRequest[detail]}</span>
                <span>{i+1 !== detailsLength ? ' |' : ''}&nbsp;</span>
              </span>
              )
            : (null)
        ))}
        </Caption1>
        <NestRequestDrawingImage nestRequest = {nestRequest} size = 'small' ></NestRequestDrawingImage>
      </>
    )
  return isSmallDescription ? condensedVersion : normalVersion
  }
}


// function getNestRequestDetailsTable(nestRequest) {
//   return (
//     <tbody>
//       <NestRequestDetails
//         nestRequest={nestRequest}
//       />
//     </tbody>
//   )
// }