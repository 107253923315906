import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { Operator } from './Operator'
import { User } from './User'

export class WorkTime extends Model {
  static backendResourceName = 'work_time'

  @observable id = null
  @observable startTime = null
  @observable endTime = null
  @observable automaticallyClockedOut = false

  relations() {
    return {
      user: User,
      operator: Operator,
    }
  }

  casts() {
    return {
      startTime: Casts.naiveDatetime,
      endTime: Casts.naiveDatetime,
    }
  }
}

export class WorkTimeStore extends Store {
  Model = WorkTime
  static backendResourceName = 'work_time'
}
