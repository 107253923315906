import { ViewProps } from './types';
import { WorkStation, WorkStationStore } from '../store/WorkStation';

/**
 * Extract the workstation from the ViewProps.
 *
 * Either (1) Matches the workstation id in the props
 * Or (2) reads the work station from the viewstore
 *
 * @param props
 */
export async function extractWorkStation(props: ViewProps): Promise<WorkStation | null> {
    const workStationId = props.match.params.workstation;


    if (workStationId) {
        const workStation = new WorkStation({
          id: workStationId
        })

        await workStation.fetch()

        return workStation;
    }

    // @ts-ignore
    const workStationCode: string | null = props.viewStore.workStationCode;

    if (!workStationCode) {
        return null;
    }

    // @ts-ignore
    return await WorkStationStore.findWorkStationFromCode(workStationCode);

}
