
export function diffFilterOptions(filter) {
    // Options for a filter that compares differences of a field.
    // E.g. show all where quantity >= 12

    return [
        { value: `${filter}:gte`, text: t('form.greaterThanOrEqual') },
        { value: `${filter}:lte`, text: t('form.lowerThanOrEqual') },
        { value: `${filter}:gt`, text: t('form.greaterThanSign') },
        { value: `${filter}:lt`, text: t('form.lowerThanSign') },
        { value: `${filter}`, text: t('form.equal') },
    ]
}
