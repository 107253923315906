import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { NavisionCustomer } from 'store/NavisionCustomer'
import { ExactAccount } from 'store/ExactAccount'
import { Unit4Customer } from 'store/Unit4Customer'
import { ExactGlobeAccount } from 'store/ExactGlobe/Account'

export class BusinessRelation extends Model {
  static backendResourceName = 'business_relation'

  @observable id = null
  @observable name = ''
  @observable email = ''
  @observable phone = null
  @observable phoneExtension = null
  @observable searchCode = ''
  @observable isSupplier = false
  @observable isCustomer = false

  relations() {
    return {
      navisionCustomer: NavisionCustomer,
      exactAccount: ExactAccount,
      unit4Customer: Unit4Customer,
      exactGlobeAccount: ExactGlobeAccount,
    }
  }
}

export class BusinessRelationStore extends Store {
  static backendResourceName = 'business_relation'
  Model = BusinessRelation
}
