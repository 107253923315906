import { ScaleTime, scaleTime } from 'd3-scale';
import { MetaData } from './Interface';

/**
 * Create a d3 scale based upon the date
 *
 * @param width
 * @param steps
 */
export function createXScale(width: number, meta: MetaData): ScaleTime<number, number> {

    return scaleTime([
        0, width
    ]).domain([
        meta.firstTimeStamp, meta.lastTimeStamp
    ])
}
