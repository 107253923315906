import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { Integration } from './Integration'

export class Unit4Integration extends Model {
  static backendResourceName = 'unit4_integration'
  static omitFields = ['active']

  @observable id = null
  @observable administration = ''
  @observable active = false

  /** START Topic flags */
  @observable goodsReceipt = true
  @observable product = true
  @observable productGroup = true
  @observable productDetails = true
  @observable supplier = true
  @observable purchaseOrder = true
  @observable customer = true
  @observable order = true
  /** END Topic flags */

  relations() {
    return {
      integration: Integration,
    }
  }
}

export class Unit4IntegrationStore extends Store {
  static backendResourceName = 'unit4_integration'
  Model = Unit4Integration
}
