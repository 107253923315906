import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ExactGlobeSalesOrderLine } from './SalesOrderLine'
import { ExactGlobePurchaseOrder } from './PurchaseOrder'

export class ExactGlobePurchaseOrderLine extends Model {
  static backendResourceName = 'exact_globe_purchase_order_line'

  @observable id = null
  @observable lineNumber = ''

  relations() {
    return {
      header: ExactGlobePurchaseOrder,
      exactGlobeSalesOrderLine: ExactGlobeSalesOrderLine,
    }
  }
}

export class ExactGlobePurchaseOrderLineStore extends Store {
  static backendResourceName = 'exact_globe_purchase_order_line'
  Model = ExactGlobePurchaseOrderLine
}
