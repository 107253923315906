import Decimal from 'decimal.js'

// Calculates per bom item in the assembly bom how many allocations to the article type it can fulfil,
// based on the current resource allocations of a sales order line
export function getBomAllocationValues(articleType, assemblyBom, resourceAllocations) {
    const bomItemValues = {}
    assemblyBom.items.models.forEach((item) => bomItemValues[item.articleType.id] = Decimal(0))

    // Look at all the allocations, and see to how many items of the salesorderline it can fulfil
    resourceAllocations.models
      .filter(allocation => !allocation.articleType.isNew)
      .filter(allocation => allocation.articleType.id !== articleType.id)
      .forEach(allocation => {
        const allocationArticleType = allocation.articleType
        if (!(allocationArticleType.id in bomItemValues)) {
          return
        }

        // The quantity of the article type on the BOM
        const bomQuantity = assemblyBom.items?.models.find(item => item?.articleType?.id === allocationArticleType.id)?.quantity

        // The amount of actual allocations this allocation quantity can fulfil
        const allocationEquivalent = bomQuantity ? allocation.quantity/bomQuantity : 0
        bomItemValues[allocationArticleType.id] = Decimal.add(bomItemValues[allocationArticleType.id], allocationEquivalent)
      })

    return bomItemValues
  }