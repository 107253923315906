import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { WorkStationStore } from './WorkStation'

export class DataSource extends Model {
  static backendResourceName = 'data_source'

  @observable id = null
  @observable identifier = ''
  @observable name = ''
  @observable description = ''
  @observable deviceName = ''
  @observable dataNames = []
  @observable dataTypes = []

  getUrl() {
    return `/assets/data-source/${this.id}/edit`
  }

  relations() {
    return {
      workStations: WorkStationStore,
    }
  }
}

export class DataSourceStore extends Store {
  Model = DataSource
  static backendResourceName = 'data_source'
}
