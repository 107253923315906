import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { CarrierRequest } from './CarrierRequest'

export class CarrierRequestDocument extends Model {
  static backendResourceName = 'carrier_request_document'
  static fileFields = ['file'];

  @observable documentType = '';
  @observable externalId = '';
  @observable file = null;

  //Annotations
  @observable filename = '';

  relations() {
    return {
      carrierRequest: CarrierRequest,
    }
  }
}

export class CarrierRequestDocumentStore extends Store {
  Model = CarrierRequestDocument
  static backendResourceName = 'carrier_request_document'
}
