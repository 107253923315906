import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { User } from './User'
import { Operator } from './Operator'
import { LeaveSlot } from './LeaveSlot'

export class LeaveSlotComment extends Model {
  static backendResourceName = 'leave_slot_comment'

  @observable id = null
  @observable message = ''
  @observable createdAt = null
  @observable updatedAt = null

  relations() {
    return {
      leaveSlot: LeaveSlot,
      user: User,
      operator: Operator,
    }
  }

  casts() {
    return {
      createdAt: Casts.datetime,
      updatedAt: Casts.datetime,
    }
  }
}

export class LeaveSlotCommentStore extends Store {
  Model = LeaveSlotComment
  static backendResourceName = 'leave_slot_comment'
}
