import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { ExactGoodsDeliveryLineStore } from 'store/ExactGoodsDeliveryLine'

export class ExactGoodsDelivery extends Model {
  static backendResourceName = 'exact_goods_delivery'

  @observable id = null
  @observable uuid = null
  @observable description = ''
  @observable remarks = ''
  @observable created = null
  @observable modified = null
  @observable deliveryDate = null
  @observable deliveryNumber = 0
  @observable trackingNumber = ''

  relations() {
    return {
      lines: ExactGoodsDeliveryLineStore,
    }
  }

  casts() {
    return {
      created: Casts.datetime,
      modified: Casts.datetime,
      deliveryDate: Casts.date,
    }
  }
}

export class ExactGoodsDeliveryStore extends Store {
  Model = ExactGoodsDelivery
  static backendResourceName = 'exact_goods_delivery'
}
