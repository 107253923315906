import { observable, computed } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { Warehouse } from './Warehouse'
import { WarehouseTransferLineStore } from './WarehouseTransferLine';
import { ProductionRequest, ProductionRequestStore } from './ProductionRequest'

export class WarehouseTransfer extends Model {
  static backendResourceName = 'warehouse_transfer'
  static SOURCES = ['manual_entry', 'import', 'transfer_advice', 'web_service', 'production_request']
  static STATUSES = ['draft', 'processed']
  static idPrefix = 'TR'
  static idIcon = 'swap_horizontal_circle_white'

  getMiniTag() {
    return window.viewStore.tracyTagSize === 'mini'
  }

  getIdColor() {
    /**
     * green:  processed and succeeded to sync
     * orange: failed to sync
     * red:    draft
     */
    const latestSyncRequest = this.getLatestPerformedSyncRequest()
    if (latestSyncRequest !== undefined && latestSyncRequest !== null && latestSyncRequest?.errorReason !== null) {
      return 'orange'
    } else if (this.status === 'processed') {
      return 'green'
    } else {
      return 'red'
    }
  }

  @observable id = null
  @observable description = ''
  @observable status = 'draft'
  @observable source = 'manual_entry'
  @observable entryDate = null
  @observable plannedDeliveryDate = null
  @observable completedOnDate = null

  @computed
  get type() {
    return this.warehouseFrom.id === this.warehouseTo.id
      ? 'Location transfer' : 'Warehouse transfer'
  }

  getUrl() {
    return `/warehouse/warehouse-transfer?.id=${this.id}`
  }

  // should return latest exactSyncRequest which has a performed_at
  getLatestPerformedSyncRequest() {
    let exactSyncRequest = null

    this.productionRequest?.batches?.find(b => {
      const perfomed = b.exactSyncRequests.filter(sr => sr.performedAt !== 0)

      if (perfomed.length > 0) {
        exactSyncRequest = perfomed[0]
        return exactSyncRequest
      }
    })

    if (!exactSyncRequest) {
      exactSyncRequest = this.productionRequest?.getLatestPerformedSyncRequest()
    }

    return exactSyncRequest
  }

  relations() {
    return {
      warehouseFrom: Warehouse,
      warehouseTo: Warehouse,
      lines: WarehouseTransferLineStore,
      productionRequest: ProductionRequest,
      targetProductionRequests: ProductionRequestStore,
    }
  }

  casts() {
    return {
      entryDate: Casts.datetime,
      plannedDeliveryDate: Casts.datetime,
      completedOnDate: Casts.datetime,
    }
  }
}

export class WarehouseTransferStore extends Store {
  Model = WarehouseTransfer
  static backendResourceName = 'warehouse_transfer'
}
