// Hacky helper to ease working with modals.
let viewStore = null

export function configureModal(givenViewStore) {
  viewStore = givenViewStore
}

export default function show(Modal, props = {}) {
  viewStore.setModal({
    render: Modal,
    ...props,
  })
}

export function clear() {
  viewStore.setModal(null)
}
