import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ExactIntegration } from './ExactIntegration'
import { ExactShopOrder } from './ExactShopOrder'
import { BillOfMaterialItem } from './BillOfMaterialItem'
import { ExactWorkcenter } from './ExactWorkcenter'

export class ExactShopOrderRoutingStep extends Model {
  static backendResourceName = 'exact_shop_order_routing'

  @observable id = null
  @observable lineNumber
  @observable operationCode = ''
  @observable operationDescription = ''
  @observable backflush
  @observable plannedRunHours = 0
  @observable plannedSetupHours = 0
  @observable plannedTotalHours = 0
  @observable run = 0
  @observable setup = 0

  relations() {
    return {
      integration: ExactIntegration,
      exactShopOrder: ExactShopOrder,
      billOfMaterialItem: BillOfMaterialItem,
      exactWorkcenter: ExactWorkcenter,
    }
  }
}

export class ExactShopOrderRoutingStepStore extends Store {
  Model = ExactShopOrderRoutingStep
  static backendResourceName = 'exact_shop_order_routing'
}
