import React, { Component } from 'react';
import styled from 'styled-components';
import { COLOR_RUN, COLOR_SETUP, COLOR_WAIT } from './StepTimePreview';
import moment from 'moment';
import { TIME_UNITS_ABBREVIATIONS } from '../../../../store/Step';
import { MINUTES, SECONDS } from '../../../../helpers/timeConversion';
import Decimal from 'decimal.js';
interface StepTimeCircleProps {
  waitMinutes: Decimal;
  setupMinutes: Decimal;
  runMinutes: Decimal;
}
interface CircleBackgroundProps {
  size: number;
  borderWidth: number;
}
const CircleBackGround = styled.div<CircleBackgroundProps & StepTimeCircleProps & {
  totalMinutes: Decimal;
  colorWait: string;
  colorSetup: string;
  colorRun: string;
}>`
    width: ${props => props.size}px !important;
    height: ${props => props.size}px !important;
    border-radius: 50%;
    background: conic-gradient(${props => props.colorWait} 0deg,
    ${props => props.colorWait} ${props => props.waitMinutes.toNumber() / props.totalMinutes.toNumber() * 360}deg,
    ${props => props.colorSetup} ${props => props.waitMinutes.toNumber() / props.totalMinutes.toNumber() * 360}deg,
    ${props => props.colorSetup} ${props => (props.setupMinutes.toNumber() + props.waitMinutes.toNumber()) / props.totalMinutes.toNumber() * 360}deg,
    ${props => props.colorRun} ${props => (props.setupMinutes.toNumber() + props.waitMinutes.toNumber()) / props.totalMinutes.toNumber() * 360}deg,
    ${props => props.colorRun} 360deg);
    padding: ${props => props.borderWidth}px;

`;
const CircleContent = styled.div<CircleBackgroundProps>`
    width: ${props => props.size - 2 * props.borderWidth}px !important;
    height: ${props => props.size - 2 * props.borderWidth}px !important;
    border-radius: 50%;
    background-color: white;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: ${props => props.size - 2 * props.borderWidth}px;
`;
export default class StepTimeCircle extends Component<StepTimeCircleProps> {
  render() {
    const {
      waitMinutes,
      setupMinutes,
      runMinutes
    } = this.props;
    const totalMinutes = waitMinutes.plus(setupMinutes).plus(runMinutes);
    const totalDuration = moment.duration(totalMinutes.toNumber(), 'minutes');
    const durationInSeconds = totalDuration.as('seconds');
    const durationInMinutes = totalDuration.as('minutes');
    let totalMinutesReadable = '0 ' + TIME_UNITS_ABBREVIATIONS[MINUTES];
    if (durationInSeconds > 0 && durationInSeconds < 1) {
      totalMinutesReadable = '<1 ' + TIME_UNITS_ABBREVIATIONS[SECONDS];
    } else if (durationInSeconds >= 1 && durationInSeconds < 60) {
      totalMinutesReadable = moment.utc(totalDuration.asMilliseconds()).format('s [s]');
    } else if (durationInSeconds >= 60 && durationInMinutes < 60 && totalDuration.seconds() === 0) {
      totalMinutesReadable = moment.utc(totalDuration.asMilliseconds()).format('m [min]');
    } else if (durationInSeconds >= 60 && durationInMinutes < 60) {
      totalMinutesReadable = moment.utc(totalDuration.asMilliseconds()).format('m [min] s [s]');
    } else if (durationInMinutes >= 60) {
      totalMinutesReadable = moment.utc(totalDuration.asMilliseconds()).format('h [h] m [min]');
    }
    const circleProps = {
      size: 130,
      borderWidth: 10
    };
    return <CircleBackGround {...circleProps} {...this.props} totalMinutes={totalMinutes} colorWait={COLOR_WAIT} colorSetup={COLOR_SETUP} colorRun={COLOR_RUN} data-sentry-element="CircleBackGround" data-sentry-component="StepTimeCircle" data-sentry-source-file="StepTimeCircle.tsx">
                <CircleContent data-test-step-duration-total {...circleProps} data-sentry-element="CircleContent" data-sentry-source-file="StepTimeCircle.tsx">
                    {totalMinutesReadable}
                </CircleContent>

            </CircleBackGround>;
  }
}