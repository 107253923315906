import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Toolbar as BaseToolbar } from 're-cy-cle'
import styled from 'styled-components'
import { isFeatureFlagEnabled } from 'helpers/featureFlags'

const StyledToolbar = styled(BaseToolbar)`
  position: relative;
  padding-left: 70px;
  background-color: ${({ theme }) => theme.toolbarColor};
`

const CyLink = styled.a`
  position: absolute;
  left: 5px;
  top: -15px;
  z-index: 1001;

  transition: filter 600ms ease;

  #cy-logo-bg {
    fill: ${({ theme }) => theme.toolbarColor};
    transition: fill 600ms ease;
  }
  #cy-logo-c {
    fill: rgba(0, 0, 0, 0.2);
    transition: fill 600ms ease;
  }
  #cy-logo-y {
    fill: rgba(0, 0, 0, 0.2);
    transition: fill 600ms ease;
  }
  #cy-logo-fm {
    fill: rgba(0, 0, 0, 0.2);
    transition: fill 600ms ease;
  }

  &:hover {
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));

    #cy-logo-bg {
      fill: #fff;
    }
    #cy-logo-c {
      fill: #ebbb12;
    }
    #cy-logo-y {
      fill: #2d303b;
    }
    #cy-logo-fm {
      fill: #2d303b;
    }
  }
`

export default class Toolbar extends Component {
  static propTypes = {
    children: PropTypes.node,
  }

  static defaultProps = {
    children: null,
  }

  brandingCY() {
    return (
      <svg width="60" height="60" viewBox="0 0 1 1">
        <g transform="scale(0.8) translate(0.1, 0.1)">
          <path
            id="cy-logo-bg"
            d="
              M 0.40625,-0.01875
              a 0.13257,0.13527 1 0 1 0.1875,0
              l 0.4375,0.4375
              a 0.13257,0.13527 1 0 1 0,0.1875
              l -0.4375,0.4375
              a 0.13257,0.13527 1 0 1 -0.1875,0
              l -0.4375,-0.4375
              a 0.13257,0.13527 1 0 1 0,-0.1875
              Z
            "
          />
          <path
            id="cy-logo-c"
            d="
              M 0.25,0.25 l -0.21875,0.21875 a 0.04419,0.04419 1 0 0 0,0.0625
              l 0.28125,0.28125
              l 0.125,-0.125
              l -0.15625,-0.15625 a 0.04419,0.04419 1 0 1 0,-0.0625
              l 0.1875,-0.1875 a 0.04419,0.04419 1 0 1 0.0625,0
              l 0.15625,0.15625
              l 0.125,-0.125
              l -0.28125,-0.28125 a 0.04419,0.04419 1 0 0 -0.0625,0 Z
            "
          />
          <path
            id="cy-logo-y"
            d="
              M 0.46875,0.96875 a 0.04419,0.04419 1 0 0 0.0625,0
              l 0.4375,-0.4375 a 0.04419,0.04419 1 0 0 0,-0.0625
              l -0.09375,-0.09375
              l -0.15625,0.15625 a 0.04419,0.04419 1 0 1 -0.0625,0
              l -0.15625,-0.1562
              l -0.125,0.125
              l 0.15625,0.15625 a 0.04419,0.04419 1 0 1 0,0.0625
              l -0.15625,0.15625
            "
          />
        </g>
      </svg>
    )
  }

  brandingFlowmasters() {
    return (
      <svg width="60" height="60" viewBox="0 0 25 25">
        <g transform="scale(0.8) translate(0.1, 0.1)">
          <ellipse id="cy-logo-bg" cx="14" cy="14" rx="14" ry="13.8" fill="#EEEEEE"/>
          <path
            id="cy-logo-fm" fill="#BDBDBD" d="
              M11.9084 2.65526C9.52299 3.01307 7.42728 4.1376 5.58714 6.097C3.45735 8.32902 2.55432 10.6122 2.55432 13.7983C2.55432 15.3999 2.62247 15.894 3.03139 17.1719C3.66181 19.0972 5.12711 21.3804 5.74049 21.3804C5.85975 21.3804 6.38794 20.8692 6.91613 20.2729L7.88731 19.1484L7.22282 18.2624C3.45735 13.3212 5.41676 6.26739 11.1587 4.00129C13.067 3.2516 16.3042 3.21753 18.3148 3.9161C19.4052 4.30798 19.3371 4.06944 18.2125 3.57533C16.2531 2.70638 13.8678 2.34857 11.9084 2.65526Z"
          />
          <path
            id="cy-logo-fm" fill="#BDBDBD" d="
              M20.3594 7.22158L19.32 8.38018L19.8993 9.12987C20.6661 10.1522 21.3135 11.4641 21.6202 12.6398C21.9099 13.7643 21.9439 16.1667 21.6884 17.2912C21.0068 20.2559 18.3489 22.9309 15.1456 23.868C13.2033 24.4303 9.523 24.1236 7.9214 23.2717C7.73398 23.1524 7.5636 23.1183 7.5636 23.1524C7.5636 23.3057 9.25039 24.1236 10.2897 24.4814C12.4366 25.2311 15.5035 25.1629 17.5992 24.328C21.2624 22.8798 23.9033 19.5744 24.5508 15.6215C24.8064 14.0028 24.8064 13.7643 24.5508 12.1968C24.176 9.87956 23.4263 7.9883 22.4551 6.89785C22.0291 6.43781 21.6202 6.04593 21.535 6.04593C21.4669 6.04593 20.9387 6.57412 20.3594 7.22158Z"
          />
          <path
            id="cy-logo-fm" fill="#BDBDBD" d="
              M12.2492 6.77856C7.30809 8.07347 5.26349 13.8835 8.34742 17.9387C8.77338 18.4839 8.79042 18.5009 9.13119 18.1942C9.31861 18.0238 9.6253 17.6831 9.79568 17.4445L10.1194 16.9845L9.54011 16.0304C8.80746 14.8206 8.51781 13.6109 8.63708 12.316C8.94377 8.85723 12.5559 6.40371 15.9976 7.30674C16.5429 7.44305 17.037 7.54528 17.071 7.5112C17.1051 7.47713 16.6792 7.27267 16.1339 7.06821C14.9242 6.60817 13.3737 6.48891 12.2492 6.77856Z"
          />
          <path
            id="cy-logo-fm" fill="#BDBDBD" d="
              M17.9911 9.50475C17.6844 9.81144 17.4458 10.1522 17.4458 10.2715C17.4458 10.3737 17.6844 10.8337 17.957 11.2767C18.9282 12.8102 19.1837 14.2414 18.7407 15.8771C18.2466 17.7513 16.7643 19.2847 14.9412 19.847C13.5781 20.273 13.1011 20.273 11.4824 19.864C10.7838 19.6766 10.1875 19.5574 10.1534 19.5744C10.1364 19.6085 10.5794 19.83 11.1417 20.0855C12.4195 20.6648 13.5611 20.8011 14.9242 20.5285C20.0186 19.5403 22.3529 13.5769 19.2689 9.41955C18.8089 8.80617 18.6555 8.82321 17.9911 9.50475Z"
          />
        </g>
      </svg>
    )
  }

  cyLinkOnClick(e) {
    if (window.viewStore.isWorkStation) {
      // prevent link from working
      e.preventDefault()
    } else {
      e.stopPropagation()
    }
  }

  render() {
    const { children, ...props } = this.props
    return (
      <StyledToolbar {...props}>
        {isFeatureFlagEnabled('flowmasters') ? (
          <CyLink data-test-flowmasters-toolbar-link
            href="https://www.flowmasters.it/"
            target="_blank"
            onClick={(e) => this.cyLinkOnClick(e)}
          >
              {this.brandingFlowmasters()}
          </CyLink>
        ) : (
          <CyLink data-test-codeyellow-toolbar-link
            href="https://www.codeyellow.nl/"
            target="_blank"
            onClick={(e) => this.cyLinkOnClick(e)}
          >
              {this.brandingCY()}
          </CyLink>
        )}

        {children}
      </StyledToolbar>
    )
  }
}
