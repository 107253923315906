import i18next, { Resource as AppTranslations } from 'i18next';
import en from './i18n/translation/en';
import nl from './i18n/translation/nl';
import { ModuleRepository } from './_iae/module/repository';
import { Translation } from './_iae/module/module';
import { merge } from 'lodash';
import { configureModules } from './_iae/module/repository';

import { systemModule } from './_iae/system'
type ModuleTranslations = {
    [language: string]: Translation[]
}


/**
 * Sort all the translations from the modules by language.
 *
 * Returns a dict, with the keys being the language and the values being a list of all the module translations
 * @param modules
 */
function getModuleTranslations(modules: ModuleRepository): ModuleTranslations {
    const moduleTranslations: ModuleTranslations = {}

    modules.modules.forEach(module => {
        Object.entries(module.translations).forEach(
            ([language, translation]) => {
                if (!(language in moduleTranslations)) {
                    moduleTranslations[language] = []
                }
                moduleTranslations[language].push(translation)
            }
        )
    })

    return moduleTranslations;
}

/**
 * Merge the translations from the modules to the translations to the app
 *
 * Done in a way that the app translations always overwrite the module translations
 *
 * @param appTranslation
 * @param moduleTranslations
 */
function mergeTranslations(appTranslation: AppTranslations, moduleTranslations: ModuleTranslations): AppTranslations {

    const languages = Object.keys(appTranslation);

    const mergedTranslations = {}


    languages.forEach(language => {
        mergedTranslations[language] = {
            'description': appTranslation[language]['description'],
            'translation': {}
        };
        merge(
            mergedTranslations[language]['translation'],
            ...(moduleTranslations[language] || []),
            // App translations always as last, this allows the end user always to override the standard module translatiosn
            appTranslation[language]['translation'],
        )
    })

    return mergedTranslations;
}

/**
 *
 * @param modules
 */
export async function configureTranslations(modules: ModuleRepository) {

    const appTranslations: AppTranslations = {
        en: {
            description: 'English',
            translation: en
        },
        nl:
            {
                description: 'Nederlands',
                translation: nl
            },
    }
    getModuleTranslations(modules)
    const moduleTranslations = getModuleTranslations(modules)
    const mergedTranslation = mergeTranslations(appTranslations, moduleTranslations);

    await i18next.init({
        // Some tests rely that default lang is 'en'. One of those tests is
        // resetPassword.T31471.cy.js. There the page will rerender after
        // bootstrap is fetched if the lang is different than the default. This
        // causes the test to be flaky, depending if the bootstrap happens before
        // or after rendering the page.
        // defaultNS: 'tracy',
        lng: 'en',
        resources: mergedTranslation,
        // interpolation: {
        //     escapeValue: false, // not needed for react as it does escape per default to prevent xss!
        // }
    });
}

/**
 * Returns all registered languages in the system.
 *
 * Returns a dictionary where the keys are the language abbreviation, and the values are the languages names (in the
 * respective language)
 *
 *
 */
export function getAvailableLanguages(): {
    [abbreviation: string]: string;
} {

    if (!i18next.options.resources) {
        throw new Error('No languages available. Did you call configureTranslations?')
    }

    const languages = {}
    Object.entries(i18next.options.resources).forEach(([language, resource]) => {
        // @ts-ignore
        languages[language] = resource.description
    })


    return languages
}




export default i18next;
// @ts-ignore
window.t =  i18next.t.bind(i18next);
export const t = i18next.t.bind(i18next);
// @ts-ignore
window.t = t
export const moduleRepository = configureModules([
    systemModule
])

configureTranslations(moduleRepository)
// @ts-ignore
