import { observable } from 'mobx'
import { Model, Store } from './Base'
import { FormFieldStore } from './FormField'

export class Form extends Model {
  static backendResourceName = 'form'

  @observable id = null

  relations() {
    return {
      fields: FormFieldStore,
    }
  }
}

export class FormStore extends Store {
  Model = Form
  static backendResourceName = 'form'
}
