import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { DownloadButton, SubmitButton } from '@code-yellow/spider';
import { observable } from 'mobx';
import { t } from 'i18n';
import moment from 'moment';

// components
// end components

// helpers
import { showSaveNotification } from 'helpers/notification';
import { getBatchesForStep } from 'helpers/productionRequest';
// end helpers

// stores
import { Integration } from 'store/Integration';
import { Batch, BatchStore } from 'store/Batch';
import { ProductionRequest } from 'store/ProductionRequest';
import { Step } from 'store/Step';
import { Operator } from 'store/Operator';
// end stores

interface RadanExportStepProps {
    integration: Integration;
    batches: Batch[];
    step?: Step;
    steps?: Map<Step, ProductionRequest[]>;
    operator: Operator;

    afterPerform: () => void;
}

@observer
export default class RadanExportStep extends Component<RadanExportStepProps> {

    @observable integration;

    componentDidMount() {
        const { integration } = this.props;
        this.integration = new Integration({ id: integration.id }, {
            relations: ['radanIntegration']
        });
        this.integration.fetch()

    }

    @observable isDownloading = false

    @observable downloadDone = false;

    downloadXml = async () => {
        const { batches } = this.props;
        this.isDownloading = true;

        const batchStore = new BatchStore();
        batches.forEach(batch => batchStore.add(batch.toJS()))

        // Note that Radan Integration is currently stateless, meaning that we do not have to fetch extra data right now
        const radanIntegration = this.integration.radanIntegration;
        const response = await radanIntegration.createExportXml(batchStore)


        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        const now = moment()
        const filename = `${now.format('YYYY-MM')}-${radanIntegration.nextNestNumber}`
        link.setAttribute('download', `${filename}.rpd`);
        document.body.appendChild(link);
        link.click();

        this.isDownloading = false;
        this.downloadDone = true;
    }

    markAsDone = async () => {
        const { step, steps, operator, batches, afterPerform } = this.props
        if (steps) {
            // @ts-ignore
            for (const [step, productionRequests] of steps) {
                const stepBatches = getBatchesForStep(step, productionRequests)
                await step.multiPerform(operator, stepBatches)
            }
        } else {
            // @ts-ignore
            await step.multiPerform(operator, batches)
        }
        showSaveNotification()
        afterPerform()

    }

    renderDownloadDone = () => {
        return (
            <SubmitButton
                content="Mark export as done"
                data-mark-export-done={true}
                onClick={this.markAsDone}
            />
        )

    }


    render() {
        if (this.downloadDone) {
            return (
                <>
                    {this.renderDownloadDone()}

                </>
            )
        }

        return (
            <>
                <DownloadButton compact primary data-test-export-radan-button
                                icon="upload" labelPosition="right"
                                content={t('radan.export')}
                                onClick={this.downloadXml}
                                disabled={this.isDownloading}
                />
            </>
        )
    }

}
