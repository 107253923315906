import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { PurchaseOrderLineStore } from './PurchaseOrderLine'
import { SalesOrderLineStore } from './SalesOrderLine'

export class Project extends Model {
  static backendResourceName = 'project'

  @observable id = null
  @observable code = ''
  @observable description = ''
  @observable notes = ''
  @observable startDate = null
  @observable endDate = null
  @observable createdAt = null
  @observable updatedAt = null
  @observable deleted = false

  relations() {
    return {
      purchaseOrderLines: PurchaseOrderLineStore,
      salesOrderLines: SalesOrderLineStore,
    }
  }
}

export class ProjectStore extends Store {
  static backendResourceName = 'project'
  Model = Project
}
