import { observable, computed } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { PurchaseOrder } from './PurchaseOrder'
import { ArticleType } from './ArticleType'
import { BusinessRelation } from './BusinessRelation'
import { InShipmentLineStore } from './InShipmentLine'
import { Project } from './Project'
import { ExactPurchaseOrderLine } from './ExactPurchaseOrderLine'
import { ResourceAllocationStore } from '../feature/Allocation/store/ResourceAllocation'
import { NavisionPurchaseOrderLine } from './NavisionPurchaseOrderLine'
import { SalesOrderLine } from './SalesOrderLine'
import { ProductionOrder } from './ProductionOrder'
import Decimal from 'decimal.js'

export class PurchaseOrderLine extends Model {
  static backendResourceName = 'purchase_order_line'
  static omitFields = ['quantityReceivedErp', 'quantityReturnedErp', 'unallocated']

  @observable id = null
  @observable receiptDate = null
  @observable quantity = Decimal(0)
  @observable quantityInPurchaseUnits = Decimal(0)
  @observable _toReceive = 0 // Small hack to let user decide how many to receive.
  @observable unitPrice = 0
  @observable netPrice = 0
  @observable number = 0
  @observable itemCode = null
  @observable description = ''
  @observable notes = ''
  @observable autoCreatedByVariableBatches = false

  // Annotations
  @observable quantityReceivedErp = Decimal(0)
  @observable quantityReturnedErp = Decimal(0)
  @observable unallocated = Decimal(0)
  @observable unallocatedFuture = Decimal(0)
  @observable quantityStatus = 'open'
  @observable quantityPlanned = Decimal(0)
  @observable quantityPlannedAndNotSynced = Decimal(0)

  @observable purchaseOrder = this.relation(PurchaseOrder)
  @observable articleType = this.relation(ArticleType)
  @observable businessRelation = this.relation(BusinessRelation)
  @observable inShipmentLines = this.relation(InShipmentLineStore)
  @observable project = this.relation(Project)
  @observable exactPurchaseOrderLine = this.relation(ExactPurchaseOrderLine)
  @observable resourceAllocations = this.relation(ResourceAllocationStore)
  @observable navisionPurchaseOrderLine = this.relation(NavisionPurchaseOrderLine)
  @observable salesOrderLine = this.relation(SalesOrderLine)
  @observable productionOrder = this.relation(ProductionOrder)

  casts() {
    return {
      quantity: Casts.decimal,
      quantityInPurchaseUnits: Casts.decimal,
      receiptDate: Casts.date,
      quantityReceivedErp: Casts.decimal,
      unallocated: Casts.decimal,
      unallocatedFuture: Casts.decimal,
    }
  }

  getLinkedAllocatedSalesOrders(progressViewLink = false) {
    const linkedSas = []

    if (!this.salesOrderLine?.salesOrder?.isNew) {
      linkedSas.push(this.salesOrderLine.salesOrder)
    }

    this.resourceAllocations.filter(ra => ra.salesOrderLine && !ra.salesOrderLine.isNew && ra.salesOrderLine.salesOrder.orderNumberErp !== null).forEach((resourceAlloc) => {
      if (!linkedSas.map(sa => sa.id).includes(resourceAlloc.salesOrderLine.salesOrder.id)) {
        linkedSas.push(resourceAlloc.salesOrderLine.salesOrder)
      }
    })

    return linkedSas.map(sa => sa.getIdLabel(false, { progressViewLink: true }))
  }

  /**
   * Get salesOrderLinks for all allocations made to this line from inside tracy
   * Requires featureflag 'annotations'
   *
   * @returns array of erp labels
   */
  getAllocatedSalesOrderErpLabels() {
    const linkedResourceAllocSAs = []
    this.resourceAllocations.filter(ra => ra.salesOrderLine && !ra.salesOrderLine.isNew && ra.salesOrderLine.salesOrder.orderNumberErp !== null).forEach((resourceAlloc) => {
      if (!linkedResourceAllocSAs.map(sa => sa.id).includes(resourceAlloc.salesOrderLine.salesOrder.id)) {
        linkedResourceAllocSAs.push(resourceAlloc.salesOrderLine.salesOrder)
      }
    })

    return linkedResourceAllocSAs.map(sa => sa.getIdLabel())
  }

  getLinkedSalesOrderLabel() {
    const salesOrder = this.salesOrderLine.salesOrder

    if (salesOrder.isNew) {
      return null
    }

    return salesOrder.getIdLabel()
  }

  getInShipments() {
    const inShipments = []
    this.inShipmentLines.filter(inShipmentLine=> !inShipmentLine.inShipment.isNew).forEach((inShipmentLine)=> {
      if (!inShipments.map(inShipment => inShipment.id).includes(inShipmentLine.inShipment.id)) {
        inShipments.push(inShipmentLine.inShipment)
      }
    })
    return inShipments
  }

  @computed get purchaseUnit() {
    if (this.quantityInPurchaseUnits === 0) {
      return 0
    }
    return this.quantity / this.quantityInPurchaseUnits
  }

  /**
   * Whether there is at least one BatchType 'buy' for this line's article,
   * which is necessary for creating InShipment orders.
   */
  @computed get hasBuyProcess() {
    return this.articleType.batchTypes.filter(({ type }) => type === 'buy').length > 0
  }

}

export class PurchaseOrderLineStore extends Store {
  static backendResourceName = 'purchase_order_line'
  Model = PurchaseOrderLine
}
