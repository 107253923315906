import { observable } from 'mobx'
import { Store } from 'store/Base';
import { CarrierIntegration } from './CarrierIntegration';
import { Integration } from './Integration';
import { ShippingUnitStore } from './ShippingUnit';

export const LABEL_SIZES = ['200 dpi', '300 dpi', '600 dpi']

export class PostnlIntegration extends CarrierIntegration {
    static backendResourceName = 'postnl_integration';
    static omitFields = ['active'];

    @observable id = null;
    @observable active = false;

    @observable apiKey = null;
    @observable customerCode = null;
    @observable customerNumber = null;
    @observable contentLabelTemplate = '';

    @observable senderName = '';
    @observable senderStreet = '';
    @observable senderHouseNumber = '';
    @observable senderCountryCode = '';
    @observable senderZipCode = '';
    @observable senderCity = '';

    @observable labelSize = '200 dpi';

    relations() {
      return {
        integration: Integration,
        shippingUnits: ShippingUnitStore,
      }
    }

}

export class PostnlIntegrationStore extends Store {
    static backendResourceName = 'postnl_integration';
    Model = PostnlIntegration;
}
