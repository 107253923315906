// Hacky helper to ease working with feature flags.
let viewStore = null

export function configureFeatureFlags(givenViewStore) {
  viewStore = givenViewStore
}

export function getFeatureFlags() {
  return viewStore?.featureFlags
}

export function isFeatureFlagEnabled(...flags) {
  const enabledFlags = getFeatureFlags()
  return flags.every((flag) => enabledFlags?.includes(flag))
}
