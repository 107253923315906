import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { Integration } from './Integration'

export class SyncError extends Model {
  static backendResourceName = 'sync_error'

  @observable id = null
  @observable message = ''
  @observable createdAt = null

  relations() {
    return {
      integration: Integration,
    }
  }

  casts() {
    return {
      createdAt: Casts.datetime,
    }
  }
}

export class SyncErrorStore extends Store {
  static backendResourceName = 'sync_error'
  Model = SyncError
}
