import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { computed, action, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { Button, Form, Modal, Popup } from 'semantic-ui-react'
import { RightDivider, ScrollModal, SubmitButton, TargetTextInput, TargetNumberInput, ErrorLabel } from '@code-yellow/spider'
import { InlineMetavalues } from 'screen/Batch/Overview'
import Decimal from 'decimal.js'
import { ItemButton } from '@code-yellow/spider'

// components
import AdminOverview, { PaginationControls } from 'component/AdminOverview'
import { BatchQrCode } from 'component/BatchQRModal'
import StorageLocationSelect from './StorageLocationSelect'
// end components

// helpers
import { isFeatureFlagEnabled } from 'helpers/featureFlags'
import { onEnter } from 'helpers'
import { humanReadable } from 'helpers/decimal'
// end helpers

// stores
import { ArticleType } from 'store/ArticleType'
import { BatchStore } from 'store/Batch'
import { ScanConstraintStore } from 'store/ScanConstraint'
import { Warehouse } from 'store/Warehouse';
import { StorageLocation } from '../../../../store/StorageLocation'
// end stores


const SOURCE_TYPES = ['make', 'buy', 'on_the_fly', 'stock_count', 'transfer_line']

class PickFromListModal extends AdminOverview {
    static propTypes = {
        articleType: PropTypes.instanceOf(ArticleType).isRequired,
        constraints: PropTypes.instanceOf(ScanConstraintStore),
        exclude: PropTypes.arrayOf(PropTypes.number.isRequired),
        resolve: PropTypes.func.isRequired,
        reject: PropTypes.func.isRequired,
    }

    static defaultProps = {
        exclude: [],
    }

    store = new BatchStore({
        relations: [
            'storageLocation.warehouse',
            'flatMetavalues.metafield',
        ]
    })
    bindUrlParams = false
    modal = true

    params = {
        '.quantity_remaining:gt': 0,
        '.batch_type.type:in': SOURCE_TYPES.join(','),
        order_by: '-quantity_remaining',
    }

    getDefaultParams() {
        const { articleType, constraints, allMetavalues, exclude } = this.props

        const params = {
            ...super.getDefaultParams(),
            '.batch_type.article_type': articleType.id,
        }

        if (exclude.length > 0) {
            params['.id:not:in'] = exclude.join(',')
        }

        // eslint-disable-next-line
        for (const constraint of constraints?.models ?? []) {
            const prefix = ENTRY_LEVEL_PREFIXES[constraint.leftMetafield.entryLevel]
            const field = `.metafield(${constraint.leftMetafield.id})`
            const quantifier = OPERATOR_QUANTIFIERS[constraint.operator]
            const filter = prefix + field + quantifier

            let value = constraint.rightValue ?? allMetavalues[constraint.rightMetafield.id]
            if (Array.isArray(value)) {
                value = value.join(',')
            } else {
                value = value.toString()
            }

            params[filter] = value
        }

        return params
    }

    @action componentDidMount() {
        this.store.params = this.getDefaultParams()
        return super.componentDidMount()
    }

    settings = [
        'serialNumber',
        {
            label: t('batch.field.metavalues.label'),
            attr: (batch) => <InlineMetavalues metavalues={batch.flatMetavalues} />,
        },
        {
            label: t('batch.field.storageLocation.label'),
            attr: (batch) => !batch.storageLocation.isNew && (
                <span>
                    {batch.storageLocation.code} ({batch.storageLocation.warehouse.name})
                </span>
            ),
        },
        {
            label: t('batch.field.quantityRemaining.label'),
            attr: ({ quantityRemaining, quantity }) => `${quantityRemaining}/${quantity}`,
            sortKey: 'quantity_remaining',
        },
        { collapsing: true },
    ]

    buttons = [
        (batch, i) => (
            <ItemButton data-test-select-button
                key={i}
                icon="add" label={t('tooltips.select')}
                onClick={() => this.props.resolve(batch.serialNumber)}
                {...this.itemButtonProps}
            />
        ),
    ]

    renderBody() {
        const { articleType, reject } = this.props
        return (
            <ScrollModal open closeIcon data-test-pick-from-list-modal
                onClose={reject}
            >
                <ScrollModal.Header>
                    {t('workStation.production.pickFromListModal.title', { articleType: articleType.name })}
                </ScrollModal.Header>
                <ScrollModal.Content noScrollbars noPadding>
                    {super.renderBody()}
                </ScrollModal.Content>
                <ScrollModal.Actions>
                    <RightDivider />
                    <PaginationControls store={this.store} />
                    <RightDivider />
                </ScrollModal.Actions>
            </ScrollModal>
        )
    }
}

const ENTRY_LEVEL_PREFIXES = {
    classification: '.batch_type.article_type.classification',
    article_type: '.batch_type.article_type',
    production_request: '',
}
const OPERATOR_QUANTIFIERS = {
    eq: '',
    neq: ':not',
    lt: ':lt',
    lte: ':lte',
    gt: ':gt',
    gte: ':gte',
}

const OPERATOR_METHODS = {
    eq: (l, r) => (
        Array.isArray(l)
            ? Array.isArray(r) && r.length === l.length && r.every((item, i) => l[i] === item)
            : l === r
    ),
    neq: (l, r) => !OPERATOR_METHODS.eq(l, r),
    lt: (l, r) => l < r,
    lte: (l, r) => l <= r,
    gt: (l, r) => l > r,
    gte: (l, r) => l >= r,
}

@observer
class OnTheFlyQuantityModal extends Component {
    static propTypes = {
        resolve: PropTypes.func.isRequired,
        reject: PropTypes.func.isRequired,
    }

    @observable quantity = null

    render() {
        const { resolve, reject, ...props } = this.props
        return (
            <Modal data-test-on-the-fly-quantity-modal open closeIcon
                size="small"
                onClose={reject}
                closeOnDimmerClick={false}
                {...props}
            >
                <Modal.Header>{t('workStation.production.onTheFlyQuantityModal.title')}</Modal.Header>
                <Modal.Content>
                    <p>{t('workStation.production.onTheFlyQuantityModal.description')}</p>
                    <Form>
                        <TargetNumberInput noLabel autoFocus
                            placeholder={t('workStation.production.onTheFlyQuantityModal.placeholder')}
                            value={this.quantity === null ? '' : this.quantity.toString()}
                            onChange={(quantity) => this.quantity = quantity === '' ? null : parseInt(quantity)}
                            onKeyPress={onEnter(() => {
                                if (this.quantity) {
                                    resolve(this.quantity)
                                }
                            })}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <RightDivider />
                    <SubmitButton primary
                        disabled={!this.quantity}
                        onClick={() => resolve(this.quantity)}
                    />
                </Modal.Actions>
            </Modal>
        )
    }
}

@observer
class OnTheFlySourceModal extends Component {
    static propTypes = {
        articleType: PropTypes.instanceOf(ArticleType).isRequired,
        quantity: PropTypes.instanceOf(Decimal).isRequired,
        resolve: PropTypes.func.isRequired,
        reject: PropTypes.func.isRequired,
    }

    constructor(...args) {
        super(...args)
        this.onSubmit = this.onSubmit.bind(this)
    }

    @observable serialNumber = null
    @observable serialNumberError = null

    async onSubmit() {
        const { articleType, quantity, resolve } = this.props

        const batches = new BatchStore({
            params: { '.serial_number': this.serialNumber }, relations: ['batchType.articleType'],
        })
        await batches.fetch()
        if (batches.length === 0) {
            this.serialNumberError = t('workStation.production.onTheFlySourceModal.error.notFound')
            this.serialNumberInput.select()
            return
        }
        const batch = batches.at(0)
        if (batch.batchType.articleType.id !== articleType.id) {
            this.serialNumberError = t('workStation.production.onTheFlySourceModal.error.wrongArticleType')
            this.serialNumberInput.select()
            return
        }
        if (batch.quantityRemaining.lt(quantity)) {
            this.serialNumberError = t('workStation.production.onTheFlySourceModal.error.notEnough')
            this.serialNumberInput.select()
            return
        }
        resolve(batch.id)
    }

    render() {
        const { resolve, reject, ...props } = this.props
        return (
            <Modal data-test-on-the-fly-source-modal open closeIcon
                size="small"
                onClose={reject}
                {...props}
            >
                <Modal.Header>{t('workStation.production.onTheFlySourceModal.title')}</Modal.Header>
                <Modal.Content>
                    <p>{t('workStation.production.onTheFlySourceModal.description')}</p>
                    <Form>
                        <TargetTextInput noLabel autoFocus
                            placeholder={t('workStation.production.onTheFlySourceModal.placeholder')}
                            value={this.serialNumber}
                            onChange={action((serialNumber) => {
                                this.serialNumber = serialNumber
                                this.serialNumberChanged = null
                            })}
                            onKeyPress={onEnter(() => {
                                if (this.serialNumber) {
                                    this.onSubmit()
                                }
                            })}
                            errors={this.serialNumberError ? [this.serialNumberError] : []}
                            contentProps={{ ref: (node) => this.serialNumberInput = node }}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <RightDivider />
                    <SubmitButton primary
                        disabled={!this.serialNumber}
                        onClick={this.onSubmit}
                    />
                </Modal.Actions>
            </Modal>
        )
    }
}

@observer
export default class Batches extends Component {
    static propTypes = {
        articleType: PropTypes.instanceOf(ArticleType).isRequired,
        quantity: PropTypes.number.isRequired,
        onChange: PropTypes.func.isRequired,
        onConfirm: PropTypes.func.isRequired,
        autoFocus: PropTypes.bool,
        errors: PropTypes.array,
        constraints: PropTypes.instanceOf(ScanConstraintStore),
        currentWarehouse: PropTypes.instanceOf(Warehouse),
        allowStorageLocationSelection: PropTypes.bool,
        rounding: PropTypes.number,
        showWarehouse: PropTypes.bool,
        defaultStorageLocation: PropTypes.instanceOf(StorageLocation),
    }

    focus() {
        if (this._child) {
            this._child.focus()
        }
    }

    render() {
        const { articleType, ...props } = this.props

        return (
            articleType.trackBatchUsage
                ? <BatchesWithUsageTracking ref={(node) => this._child = node} articleType={articleType} {...props} />
                : <BatchesWithoutUsageTracking ref={(node) => this._child = node} articleType={articleType} allowMultipleScanning={true} {...props} />
        )
    }
}

@observer
class BatchesWithUsageTracking extends Component {
    static propTypes = {
        articleType: PropTypes.instanceOf(ArticleType).isRequired,
        quantity: PropTypes.number.isRequired,
        onChange: PropTypes.func.isRequired,
        onConfirm: PropTypes.func.isRequired,
        autoFocus: PropTypes.bool,
        errors: PropTypes.array,
        constraints: PropTypes.instanceOf(ScanConstraintStore),
        allowMultipleScanning: PropTypes.bool,
        allMetavalues: PropTypes.object.isRequired,
        currentWarehouse: PropTypes.instanceOf(Warehouse),
        allowStorageLocationSelection: PropTypes.bool,
        rounding: PropTypes.number,
        showWarehouse: PropTypes.bool,
        defaultStorageLocation: PropTypes.instanceOf(StorageLocation),
    }

    static defaultProps = {
        autoFocus: false,
        errors: [],
        allowMultipleScanning: false,
        allowStorageLocationSelection: false,
        showWarehouse: false,
        defaultStorageLocation: null
    }

    constructor(...args) {
        super(...args)
        this.focus = this.focus.bind(this)
        this.findBatch = this.findBatch.bind(this)
    }

    @observable value = []
    @observable serialNumber = ''
    @observable serialNumberChanged = false
    @observable serialNumberError = null
    @observable onTheFlyQuantityModal = null
    @observable onTheFlySourceModal = null
    @observable pickFromListModal = null

    @observable preselectedStorageLocation = new StorageLocation()

    @action setValue(value) {
        const { onChange } = this.props
        this.value = value
        onChange(this.value.map(({ batch, ...scan }) => ({ serial_number: batch.serialNumber, ...scan })))
    }

    componentDidMount() {
        const { autoFocus } = this.props
        if (autoFocus) {
            this.focus()
        }
    }

    focus() {
        if (this.serialNumberInput) {
            this.serialNumberInput.select()
        }
    }

    @computed get scannedQuantity() {
        return this.value.reduce((total, { usage }) => total.add(usage ? usage : 0), Decimal(0))
    }

    promiseModal(field, details = {}) {
        return new Promise((resolve, reject) => this[field] = {
            resolve: action((result) => {
                this[field] = null
                resolve(result)
            }),
            reject: action((error) => {
                this[field] = null
                reject(error)
            }),
            ...details,
        })
    }

    @action
    async scanValue(batch) {
        const { quantity, onConfirm, allowStorageLocationSelection } = this.props
        let scan = {
            batch,
            usage: Decimal.min(batch.quantityRemaining, new Decimal(quantity).sub(this.scannedQuantity)),
        }
        if (allowStorageLocationSelection && !batch.storageLocation.isNew){
            // Untill this was added the location of the batch was displayed but not actually selected
            // This makes sure that the default location (the one of the batch) is also sent to the backend.
            scan.storage_location = batch.storageLocation.id
        }
        if (batch.isNew && batch.batchType.onTheFlyType === 'flexible') {
            scan.quantity = batch.quantity
        }
        if (batch.isNew && batch.batchType.onTheFlySource === 'existing') {
            scan.source = await this.promiseModal('onTheFlySourceModal', { quantity: batch.quantity })
        }

        if (scan.usage.lte(0)) {
            runInAction(() => {
                this.serialNumberChanged = false
                this.serialNumberError = t('workStation.production.performModal.scan.error.fullyUsed')
                this.focus()
            })
            return
        }

        runInAction(() => {
            this.setValue([...this.value, scan])
            this.serialNumber = ''
            this.serialNumberChanged = false
            this.serialNumberError = null

            if (this.scannedQuantity.equals(quantity)) {
                onConfirm()
            } else {
                this.focus()
            }
        })
    }

    getStorageLocation(storageLocation) {
        const { articleType, rounding } = this.props

        if (!storageLocation || !articleType.storageLocations) {
            return ''
        }

        // // We are supposed to show the warehouse stock + name instead of storage location
        // if (showWarehouse && false) {
        //     const stock = articleType.storageLocations.models
        //         .filter(sl => sl.warehouse.code === storageLocation.warehouse.code)
        //         .reduce((sum, sl) => sum.add(sl.stock), Decimal(0))
        //     const stockSuffix = stock ? ` (${humanReadable(stock, rounding)})` : ''
        //     return storageLocation.warehouse.code + stockSuffix
        // }
        // else {
            const ats = articleType.storageLocations.find(asl => asl.storageLocation.id === storageLocation.id)
            const stockSuffix = ats ? ` (${humanReadable(ats.stock, rounding)})` : ''
            return storageLocation.code + stockSuffix
        // }
    }

    async findBatch() {
        const { articleType, constraints, allMetavalues, allowMultipleScanning } = this.props

        if (!this.serialNumberChanged) {
            return
        }

        const batchIds = (
            this.value
                .map(({ batch }) => batch.id)
                .filter((batchId) => batchId !== null)
        );
        const batches = new BatchStore({
            params: { '.serial_number': this.serialNumber },
            relations: [
                'batchType.articleType',
                'storageLocation.warehouse',
                ...constraints !== undefined && constraints.length > 0 ? ['flatMetavalues.metafield'] : [],
            ],
        })
        if (batchIds.length !== 0 && !allowMultipleScanning) {
            batches.params['.id:not:in'] = batchIds.join(',')
        }
        await batches.fetch()

        if (batches.length === 0) {
            const batchType = articleType.batchTypes.find(({ type }) => type === 'on_the_fly')
            if (!batchType || !batchType.onTheFlySerialNumberRegex.test(this.serialNumber)) {
                runInAction(() => {
                    this.serialNumberChanged = false
                    this.serialNumberError = t('workStation.production.performModal.scan.error.notFound')
                    this.focus()
                })
                return
            }

            if (batches.length === 0) {
                let batchQuantity = 0
                if (batchType.onTheFlyType === 'none') {
                    batchQuantity = Infinity
                } else if (batchType.onTheFlyType === 'fixed') {
                    batchQuantity = batchType.onTheFlySize
                } else if (batchType.onTheFlyType === 'flexible') {
                    batchQuantity = await this.promiseModal('onTheFlyQuantityModal')
                }

                batches.add({
                    serialNumber: this.serialNumber,
                    quantity: batchQuantity,
                    quantityRemaining: batchQuantity,
                    batchType: {
                        ...batchType.toJS(),
                        articleType: articleType.toJS(),
                    },
                })
            }
        }

        const batch = batches.at(0)

        if (batch.batchType.articleType.id !== articleType.id) {
            runInAction(() => {
                this.serialNumberChanged = false
                this.serialNumberError = t('workStation.production.performModal.scan.error.wrongArticleType')
                this.focus()
            })
            return
        }

        if (!SOURCE_TYPES.includes(batch.batchType.type)) {
            runInAction(() => {
                this.serialNumberChanged = false
                this.serialNumberError = t('workStation.production.performModal.scan.error.wrongBatchType')
                this.focus()
            })
            return
        }

        if (constraints !== undefined) {
            const constraintErrors = []

            // eslint-disable-next-line
            for (const constraint of constraints.models) {
                const metavalue = batch.flatMetavalues.find((metavalue) => metavalue.metafield.id === constraint.leftMetafield.id)

                if (metavalue === undefined) {
                    constraintErrors.push(t('workStation.production.performModal.scan.error.constraint.noValue', { field: constraint.leftMetafield.name }))
                    continue
                }

                const leftValue = metavalue.value
                const rightValue = constraint.rightValue ?? allMetavalues[constraint.rightMetafield.id]
                if (!OPERATOR_METHODS[constraint.operator](leftValue, rightValue)) {
                    constraintErrors.push(t(`workStation.production.performModal.scan.error.constraint.doesNotHold.${constraint.operator}`, {
                        leftField: constraint.leftMetafield.name,
                        left: Array.isArray(leftValue) ? leftValue.join(', ') : leftValue.toString(),
                        right: Array.isArray(rightValue) ? rightValue.join(', ') : rightValue.toString(),
                    }))
                    continue
                }
            }

            if (constraintErrors.length > 0) {
                runInAction(() => {
                    this.serialNumberChanged = false
                    this.serialNumberError = constraintErrors.join('\n')
                    this.focus()
                })
                return
            }
        }

        if (!batch.isNew && batch.batchType.onTheFlyType === 'none') {
            batch.quantityRemaining = Infinity
        }

        await this.scanValue(batch)
    }

    renderNewInput() {
        const { quantity } = this.props

        return (
            this.scannedQuantity.lt(quantity) && (
                <>
                <Form.Group widths="equal">
                    <TargetTextInput noLabel data-test-serial-number
                        width={7}
                        placeholder={t('workStation.production.performModal.scan.serialNumber')}
                        value={this.serialNumber}
                        onChange={action((serialNumber) => {
                            this.serialNumber = serialNumber
                            this.serialNumberChanged = true
                            this.serialNumberError = null
                        })}
                        onKeyPress={onEnter((e) => e.target.blur())}
                        onBlur={this.findBatch}
                        contentProps={{ ref: (node) => this.serialNumberInput = node }}
                        errors={this.serialNumberError ? [this.serialNumberError] : []}
                    />
                    <TargetNumberInput width={7} noLabel disabled placeholder={t('workStation.production.performModal.scan.quantity')} />
                    {this.renderPreselectLocation()}
                    {isFeatureFlagEnabled('pick_from_list') ? (
                        <Button data-test-pick-from-list-button icon="search" onClick={async () => {
                            const serialNumber = await this.promiseModal('pickFromListModal')
                            runInAction(() => {
                                this.serialNumber = serialNumber
                                this.serialNumberChanged = true
                                this.serialNumberError = null
                                this.findBatch()
                            })
                        }} />
                    ) : (
                        <Button icon="delete" disabled />
                    )}
                </Form.Group>
                </>
            )
        );
    }

    renderPreselectLocation() {
        const { articleType, currentWarehouse, allowStorageLocationSelection, rounding, showWarehouse, defaultStorageLocation } = this.props

        if (!allowStorageLocationSelection && !showWarehouse) {
            return
        }

        else if (allowStorageLocationSelection){
            return(
                <StorageLocationSelect
                    storageLocation={this.preselectedStorageLocation}
                    articleType={articleType}
                    onChangeModel={(sl) => this.preselectedStorageLocation = sl}
                    warehouses={[]}
                    defaultWarehouse={currentWarehouse}
                    showWarehouse={false}
                    rounding={rounding}
                    suggestLocation={false}
                    positiveStock={true}
                />
            )
        }
        else if (defaultStorageLocation && !defaultStorageLocation.isNew){
            return(
                <TargetTextInput noLabel disabled
                value={this.getStorageLocation(defaultStorageLocation)}
                />
            )
        }
    }

    renderStorageLocationSelect(i, scan) {
        const { articleType, currentWarehouse, allowStorageLocationSelection, rounding, showWarehouse, defaultStorageLocation } = this.props

        if (!allowStorageLocationSelection && !showWarehouse) {
            return
        }

        if (!allowStorageLocationSelection && showWarehouse) {
            if (!scan.batch.storageLocation.isNew || defaultStorageLocation){
                return(
                    <TargetTextInput noLabel disabled
                    data-test-linked-storage-location={scan.batch.id}
                    // If the scanned batch doesn't have a location, then we show the default one
                    value={this.getStorageLocation(scan.batch.storageLocation.isNew ? defaultStorageLocation : scan.batch.storageLocation)}
                    />
                )
            }
            else{
                return
            }
        }

        return (
            <StorageLocationSelect
                storageLocation={ !this.preselectedStorageLocation.isNew ? this.preselectedStorageLocation : scan.batch.storageLocation }
                articleType={articleType}
                onChange={action(async (value) => {
                    this.setValue([
                        ...this.value.slice(0, i),
                        { ...scan, storage_location: value },
                        ...this.value.slice(i + 1),
                    ])
                })}
                warehouses={[]}
                defaultWarehouse={currentWarehouse}
                showWarehouse={false}
                rounding={rounding}
                suggestLocation={false}
                positiveStock={true}
            />
        )
    }

    renderAddBatchButton() {
        return;
    }

    renderScannedBatches(scan, i, serialNumberErrors, quantityErrors) {
        const { articleType, rounding } = this.props

        return (
            <React.Fragment key={scan.batch.cid}>
                <Form.Group widths="equal">
                    <TargetTextInput width={7} noLabel disabled value={scan.batch.serialNumber} errors={serialNumberErrors} />
                    <Button data-test-batch-quantity-minus={scan.batch.id}
                        icon='minus'
                        onClick={() => {
                            if (scan.usage && scan.usage.sub(Decimal(1)).gt(Decimal(0))) {
                                scan.usage = scan.usage.sub(Decimal(1))
                                this.setValue([
                                    ...this.value.slice(0, i),
                                    scan,
                                    ...this.value.slice(i + 1),
                                ])
                            }
                        }}
                    />
                    <TargetNumberInput noLabel allowDecimal data-test-quantity-field
                        width={5}
                        placeholder={t('workStation.production.performModal.scan.quantity')}
                        suffix={`    / ${scan.batch.quantityRemaining === Infinity ? '∞' : humanReadable(scan.batch.quantityRemaining, rounding)}`}
                        value={scan.usage === null ? '' : scan.usage.toString()}
                        onChange={(usage) => {
                            this.setValue([
                                ...this.value.slice(0, i),
                                { ...scan, usage: usage === '' ? null : Decimal(usage) },
                                ...this.value.slice(i + 1),
                            ])
                        }}
                        errors={quantityErrors}
                    />
                    <Button data-test-batch-quantity-plus={scan.batch.id}
                        icon='plus'
                        onClick={() => {
                            if (!scan.usage) {
                                scan.usage = Decimal(1)
                                this.setValue([
                                    ...this.value.slice(0, i),
                                    scan,
                                    ...this.value.slice(i + 1),
                                ])
                            } else if (scan.usage.plus(Decimal(1)).lte(scan.batch.quantity)) {
                                scan.usage = scan.usage.plus(Decimal(1))
                                this.setValue([
                                    ...this.value.slice(0, i),
                                    scan,
                                    ...this.value.slice(i + 1),
                                ])
                            }
                        }}
                    />
                    {isFeatureFlagEnabled('batch_qr_code') && (
                        <Popup
                            position="top left"
                            trigger={<Button data-test-batch-qr-code icon={'qrcode'} />}
                            content={<BatchQrCode batch={scan.batch} />}
                        />
                    )}
                    {this.renderStorageLocationSelect(i, scan)}
                    <Button data-test-delete-scanned-batch={articleType.id}
                        icon="delete"
                        onClick={() => this.setValue([...this.value.slice(0, i), ...this.value.slice(i + 1)])}
                    />
                </Form.Group>
            </React.Fragment>
        );
    }

    render() {
        const { articleType, errors, constraints, allMetavalues } = this.props
        const generalErrors = errors.filter(({ path }) => path.length === 0)
        return (
            <>
                {this.value.map((scan, i) => {
                    const serialNumberErrors = []
                    const quantityErrors = []
                    // eslint-disable-next-line
                    for (const error of errors) {
                        if (error.path.length === 0 || error.path[0] !== i) {
                            continue
                        }
                        if (error.path.length > 1 && error.path[1] === 'quantity') {
                            quantityErrors.push(error.message)
                        } else {
                            serialNumberErrors.push(error.message)
                        }
                    }
                    return this.renderScannedBatches(scan, i, serialNumberErrors, quantityErrors, i === this.value.length - 1)
                })}
                {this.renderNewInput()}
                {generalErrors.length > 0 && (
                    <ErrorLabel>
                        {generalErrors.map(({ message }, i) => <div key={i}>{message}</div>)}
                    </ErrorLabel>
                )}
                {this.onTheFlyQuantityModal !== null && <OnTheFlyQuantityModal {...this.onTheFlyQuantityModal} />}
                {this.onTheFlySourceModal !== null && <OnTheFlySourceModal articleType={articleType} {...this.onTheFlySourceModal} />}
                {this.pickFromListModal !== null && (
                    <PickFromListModal
                        articleType={articleType}
                        constraints={constraints}
                        allMetavalues={allMetavalues}
                        exclude={this.value.map((scan) => scan.batch.id)}
                        {...this.pickFromListModal}
                    />
                )}
            </>
        )
    }
}

@observer
class BatchesWithoutUsageTracking extends BatchesWithUsageTracking {

    @observable showInput = true

    @computed get scannedQuantity() {
        return this.value.length > 0 ? Infinity : 0
    }

    @action
    async scanValue(batch) {
        const { quantity, onConfirm } = this.props

        const scan = {
            batch,
            usage: quantity,
        }
        if (batch.isNew && batch.batchType.onTheFlyType === 'flexible') {
            scan.quantity = batch.quantity
        }
        if (batch.isNew && batch.batchType.onTheFlySource === 'existing') {
            scan.source = await this.promiseModal('onTheFlySourceModal', { quantity: batch.quantity })
        }

        runInAction(() => {
            this.setValue([...this.value, scan])
            this.serialNumber = ''
            this.serialNumberChanged = false
            this.serialNumberError = null
            if (this.scannedQuantity >= quantity) {
                this.showInput = false
                onConfirm()
            } else {
                this.showInput = true
                this.focus()
            }
        })
    }

    renderNewInput() {
        return (
            this.showInput && (
                <Form.Group widths="equal">
                    <TargetTextInput noLabel data-test-serial-number
                        placeholder={t('workStation.production.performModal.scan.serialNumber')}
                        value={this.serialNumber}
                        onChange={action((serialNumber) => {
                            this.serialNumber = serialNumber
                            this.serialNumberChanged = true
                            this.serialNumberError = null
                        })}
                        onKeyPress={onEnter((e) => e.target.blur())}
                        onBlur={this.findBatch}
                        contentProps={{ ref: (node) => this.serialNumberInput = node }}
                        errors={this.serialNumberError ? [this.serialNumberError] : []}
                    />
                    <Button icon="delete" disabled />
                </Form.Group>
            )
        );
    }

    renderAddBatchButton() {
        return (
            <Button style={{ whiteSpace: 'nowrap' }} primary data-test-add-another-batch
                size="small"
                icon="add"
                labelPosition="left"
                content={'Add another batch'}
                onClick={action(() => {
                    this.showInput = true
                    this.serialNumber = ''
                    this.serialNumberChanged = false
                    this.serialNumberError = null
                    this.focus()
                })}
            />
        )
    }

    renderScannedBatches(scan, i, serialNumberErrors, quantityErrors, addButton = false) {
        const { articleType } = this.props

        return (
            <React.Fragment key={scan.batch.cid} style={{ 'background-color': '#FF00' }}>
                <Form.Group widths="equal">
                    <TargetTextInput noLabel disabled value={scan.batch.serialNumber} errors={serialNumberErrors} />
                    {isFeatureFlagEnabled('batch_qr_code') && (
                        <Popup
                            position="top left"
                            trigger={<Button data-test-batch-qr-code icon={'qrcode'} />}
                            content={<BatchQrCode batch={scan.batch} />}
                        />
                    )}
                    <Button data-test-delete-scanned-batch={articleType.id}
                        icon="delete"
                        onClick={() => {
                            this.setValue([...this.value.slice(0, i), ...this.value.slice(i + 1)])
                            //If we have deleted the last input field, bring back the empty default one
                            if (this.value.length === 0)
                                this.showInput = true
                        }}
                    />
                    {addButton && this.renderAddBatchButton()}
                </Form.Group>
                {this.renderStorageLocationSelect(i, scan)}
            </React.Fragment>
        );
    }
}
