import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { BusinessRelation } from './BusinessRelation'

export class Unit4Customer extends Model {
  static backendResourceName = 'unit4_customer'

  @observable id = null
  @observable customerId = ''
  @observable name = ''
  @observable shortName = ''
  @observable contactPerson = ''

  relations() {
    return {
      businessRelation: BusinessRelation,
    }
  }
}

export class Unit4CustomerStore extends Store {
  static backendResourceName = 'unit4_customer'
  Model = Unit4Customer
}
