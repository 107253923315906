import sortSteps from './sortSteps'
import { getCounts } from './counts'

// TODO: Kill this frontend filter. Big wtf that data is fetched from backend,
// but not shown on frontend.
export function workStationActiveOrders(store, workStationCode) {

    function* flatSteps({ branches, steps }) {
        // eslint-disable-next-line
        for (const branch of branches) {
            yield* flatSteps(branch)
        }
        yield* steps
    }

    const orders = new Set()

    // eslint-disable-next-line
    for (const productionRequest of store.models) {
        const steps = sortSteps(productionRequest.processVersion.steps)
        const stepCounts = getCounts({
            steps: steps,
            quantity: productionRequest.quantity,
            batches: productionRequest.batches,
            superrequestAtSubprocesses: productionRequest.superrequestAtSubprocesses,
            subrequestsFinished: productionRequest.subrequestsFinished,
        })

        // eslint-disable-next-line
        for (const step of flatSteps(steps)) {
            if (
                (['multiplier', 'subprocesses'].includes(step.type) || step.workStation?.code === workStationCode) &&
                stepCounts[`pre_${step.id}`] > 0
            ) {
                orders.add(productionRequest.id)
                break // If we find just one upcoming step with work, that's ennough.
            }
        }
    }
    return store.models.filter((pr) => orders.has(pr.id))
}
