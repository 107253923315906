import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { Detail } from './Detail'

export class DetailImage extends Model {
  static backendResourceName = 'detail_image'

  @observable id = null
  @observable image = null

  relations() {
    return {
      detail: Detail,
    }
  }
}

export class DetailImageStore extends Store {
  Model = DetailImage
  static backendResourceName = 'detail_image'
}
