import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { ArticleType } from './ArticleType'
import { BillOfMaterialVersion } from './BillOfMaterialVersion'
import { ExternalWorkStation } from './ExternalWorkStation';
import { DetailMaterialTaskStore } from './DetailMaterialTask';
import { ExactShopOrderRoutingStep } from './ExactShopOrderRoutingStep';
import { OperatorTimeLogStore } from './OperatorTimeLog'
import Decimal from 'decimal.js';

export const BOM_ITEM_TYPE_TASK = 'task';
export const BOM_ITEM_TYPE_MATERIAL = 'material';

export class BillOfMaterialItem extends Model {
  static backendResourceName = 'bill_of_material_item'
  static omitFields = ['erpQuantityRemaining']

  @observable id = null
  @observable description = ''
  @observable quantity = 0
  @observable quantityBatch = null
  @observable backflush
  @observable handledByErp = false
  @observable number
  @observable type = BOM_ITEM_TYPE_MATERIAL;
  @observable isByproduct = false;
  @observable unit = ''
  @observable stock = 0
  @observable plannedIn = 0
  @observable plannedOut = 0
  @observable requiredQuantity = null

  // Annotations
  @observable erpQuantityRemaining = Decimal(0)

  relations() {
    return {
      articleType: ArticleType,
      billOfMaterialVersion: BillOfMaterialVersion,
      externalWorkStation: ExternalWorkStation,
      details: DetailMaterialTaskStore,
      shopOrderRoutingStep: ExactShopOrderRoutingStep,
      timeLogs: OperatorTimeLogStore,
    }
  }

  casts() {
    return {
      erpQuantityRemaining: Casts.decimal,
    }
  }
}

export class BillOfMaterialItemStore extends Store {
  Model = BillOfMaterialItem
  static backendResourceName = 'bill_of_material_item'
}
