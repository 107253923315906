import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { ExactPurchaseReturnLineStore } from 'store/ExactPurchaseReturnLine'

export class ExactPurchaseReturn extends Model {
  static backendResourceName = 'exact_purchase_return'

  @observable id = null
  @observable uuid = null
  @observable description = ''
  @observable returnDate = null
  @observable created = null
  @observable modified = null

  relations() {
    return {
      lines: ExactPurchaseReturnLineStore,
    }
  }

  casts() {
    return {
      returnDate: Casts.date,
      created: Casts.datetime,
      modified: Casts.datetime,
    }
  }
}

export class ExactPurchaseReturnStore extends Store {
  Model = ExactPurchaseReturn
  static backendResourceName = 'exact_purchase_return'
}
