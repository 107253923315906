import React, { Component } from 'react';
import { ScaleTime } from 'd3-scale';
import { observer } from 'mobx-react';
import moment, { Moment } from 'moment';

interface TimeIndicationProps {
    scaleX: ScaleTime<number, number>;
    showLegend?: boolean;
}


@observer
export class TimingIndications extends Component<TimeIndicationProps> {


    dateTicks = () => {
        const { scaleX } = this.props;

        const dates: [number, Moment][] = []

        const domain = scaleX.domain()

        const endDate = moment(domain[1])
        let currentDate = moment(domain[0]).endOf('day');

        while (currentDate < endDate) {
            const legendDay = currentDate.clone()
            legendDay.add(1, 'day')
            dates.push([
                scaleX(currentDate), legendDay
            ])

            currentDate = currentDate.add(1, 'day')
        }


        return dates;
    }

    hourTicks = () => {
        const { scaleX } = this.props;

        const dates: [number, Moment][] = []

        const domain = scaleX.domain()


        const endDate = moment(domain[1])
        let currentDate = moment(domain[0]).endOf('hour');


        while (currentDate < endDate) {
            dates.push([
                scaleX(currentDate), currentDate
            ])

            currentDate = currentDate.add('1', 'hour')
        }


        return dates;
    }

    renderDateTick = (point: [number, Moment]) => {
        const { showLegend } = this.props
        const x = point[0];
        return (
            <rect
                x={x}
                y={showLegend ? 20 : 0}
                width={2}
                height={50}
                fill={'BDBDBD'}
            />
        )
    }

    renderDateLegend = (ticks: [number, Moment][]) => {
        const distanceBetweenDates = ticks.length > 1 ? ticks[1][0] - ticks[0][0] : 0
        return ticks.map((tick, i) => {
            const legendText = tick[1].format('D-M')
            const legendSize = 0.7
            const legendWidth = this.calculateDateTextWidth(legendText, legendSize)
            return (
                <text
                    key={i}
                    x={tick[0] + distanceBetweenDates/2 - legendWidth/2}
                    y={15}
                    font-size={`${legendSize}em`}
                >
                    {tick[1].format('D-M')}
                </text>
            )
        })
    }

    // This is specific to the current font used, and the format used in the date tick legend. fontSize in em
    calculateDateTextWidth = (text: string, fontSize: number) => {
        const standardSize = 0.7    // Don't change unless if new numbers below are recalculated with different font size

        const standardHyphenWidth = 3.17/standardSize   // Don't change number unless if font changes
        const currentHyphenWidth = standardHyphenWidth*fontSize

        const standardDigitWidth = 5.11/standardSize    // Don't change number unless if font changes
        const currentDigitWidth = standardDigitWidth*fontSize

        const totalDigitWidth = (text.length - 1) * currentDigitWidth
        const totalWidth = currentHyphenWidth + totalDigitWidth

        return totalWidth
    }

    renderHourTick = (point: [number, Moment]) => {
        const x = point[0];
        return (
            <rect
                x={x}
                y={0}
                width={1}
                height={50}
                fill={'#E0E0E0'}
            />
        )
    }

    /**
     * Only show the hour ticks if the amount of days is minimal, to avoid clutter
     */
    get showHourTicks(): boolean {
        const { scaleX } = this.props;
        const domain = scaleX.domain()
        const days = moment(domain[1]).diff(moment(domain[0], 'days'));

        return days < 3;
    }

    render() {
        const { showLegend } = this.props
        const dateTicks = this.dateTicks();
        const hourTicks = this.hourTicks();

        return (
            <>
                {showLegend && this.renderDateLegend(dateTicks)}
                <g>
                    {dateTicks.map(this.renderDateTick)}
                    {this.showHourTicks && hourTicks.map(this.renderHourTick)}
                </g>
            </>
        )
    }

}
