import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ExactSalesReturn } from 'store/ExactSalesReturn'
import { ExactGoodsDeliveryLine } from 'store/ExactGoodsDeliveryLine'

export class ExactSalesReturnLine extends Model {
  static backendResourceName = 'exact_sales_return_line'

  @observable id = null
  @observable uuid = null
  @observable createCredit = null
  @observable notes = ''
  @observable lineNumber = null
  @observable plannedReturnQuantity = null
  @observable receivedQuantity = null

  relations() {
    return {
      exactSalesReturn: ExactSalesReturn,
      exactGoodsDeliveryLine: ExactGoodsDeliveryLine,
    }
  }
}

export class ExactSalesReturnLineStore extends Store {
  Model = ExactSalesReturnLine
  static backendResourceName = 'exact_sales_return_line'
}
