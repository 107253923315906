import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { ArticleType } from './ArticleType'
import { ProductionRequest } from './ProductionRequest'
import { StorageLocation } from './StorageLocation'
import { Warehouse } from './Warehouse'

export class MaterialIssue extends Model {
    static backendResourceName = 'material_issue'

    @observable id = null
    @observable isBackflush = false
    @observable quantity = '0'
    @observable transactionDate = null

    relations() {
        return {
            productionRequest: ProductionRequest,
            storageLocation: StorageLocation,
            articleType: ArticleType,
            warehouse: Warehouse,
        }
    }

    casts() {
        return {
            transactionDate: Casts.datetime,
        }
    }
}

export class MaterialIssueStore extends Store {
    Model = MaterialIssue
    static backendResourceName = 'material_issue'
}
