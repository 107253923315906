import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Header, Image, Popup } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { User } from 'store/User'
import { Operator } from 'store/Operator'
import defaultAvatar from 'image/default_avatar.png'

@observer
export class SmallAvatar extends Component {
  static propTypes = {
    user: PropTypes.oneOfType([PropTypes.instanceOf(User), PropTypes.instanceOf(Operator)]).isRequired,
  }

  render() {
    const { user, ...rest } = this.props

    return (
      <Image
        avatar
        {...rest}
        src={user.avatar || defaultAvatar}
        style={{
          objectFit: 'cover',
          width: '2.4rem',
          height: '2.4rem',
          margin: '-0.2rem 0',
        }}
      />
    )
  }
}

export class UserAvatar extends Component {
  static propTypes = {
    user: PropTypes.instanceOf(User).isRequired,
    withName: PropTypes.bool,
    className: PropTypes.string,
  }

  render() {
    const { user, withName, className, ...rest } = this.props

    const userNode = (
      <span className={className} data-test-user-avatar={user.fullName}>
        <SmallAvatar user={user} {...rest} />
        {withName && (
          <span data-test-full-name style={{ paddingLeft: '0.5rem' }}>
            {user.fullName}
          </span>
        )}
      </span>
    )
    return (
      <Popup trigger={userNode} flowing>
        <Header as="h4">{this.props.user.fullName}</Header>
        <div>
          <strong>{t('user.field.email.label')}:</strong> {this.props.user.email}
        </div>
      </Popup>
    )
  }
}

export default UserAvatar
