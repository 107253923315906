import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { StockRuleStore } from './StockRule'
import { FormField } from './FormField'

export class StockRuleset extends Model {
  static backendResourceName = 'stock_ruleset'

  @observable id = null
  @observable finalOrdering = 'free_capacity'
  @observable finalOrderingAscending = true


  relations() {
    return {
      formField: FormField,
      rules: StockRuleStore,
    }
  }
}

export class StockRulesetStore extends Store {
  static backendResourceName = 'stock_ruleset'
  Model = StockRuleset
}
