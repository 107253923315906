import {
    ProcessStep
} from './interface';
import {
    StepType,
    config
} from './config';
/**
 * For each step type, register the needed steps in the interface
 */
const PROCESSS_STEPS: Map<StepType, ProcessStep> = new Map<StepType, ProcessStep>();


config();

export const TYPES: StepType[] = Array.from(PROCESSS_STEPS.keys());

export default PROCESSS_STEPS;


/**
 * Register a new staep in the system, such that it can be used by the system
 *
 * @param type
 * @param data
 */
export function registerStep(type: StepType, data: ProcessStep): void {
    PROCESSS_STEPS.set(type, data)
}

export function isMultiStep(type: StepType): boolean {
    return !!PROCESSS_STEPS.get(type)?.multiStepView;
}
