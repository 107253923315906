import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { action, computed } from 'mobx'
import { observer } from 'mobx-react'
import { IconButton, RightDivider, TargetRadioButtons, TargetNumberInput, TargetFile, TargetSelect } from '@code-yellow/spider'
import { t } from 'i18n'
import styled from 'styled-components'

// components
import TargetTemplate from 'component/TargetTemplate'
import TargetOverlays from 'component/TargetOverlays'
import PrintButtonModal from 'component/PrintButtonModal'
import { MetafieldsContext } from 'component/Metafields'
// end components

// helpers
import InfoPopup from '../../../helpers/infoPopup'
// end helpers

// stores
import { Step, PrintStep, SHIPMENT_LINES_HEADER_OPTIONS, SHIPMENT_LINES_DETAIL_OPTIONS } from 'store/Step'
import { PROCESS_TYPES, TEMPLATE_TYPES, BatchType } from 'store/BatchType'
// end stores

const PrintButton = styled(IconButton)`
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
  &:hover {
    color: rgba(0, 0, 0, 0.5);
  }
  margin: 0 !important;
`

const TYPE_OPTIONS = PrintStep.TYPES.map((type) => ({
  value: type,
  text: t(`printStep.field.type.value.${type}`),
}))

export const LABEL_PRINTER_OPTIONS = PrintStep.LABEL_PRINTERS.map((printer) => ({
  value: printer,
  text: t(`printStep.field.labelPrinter.value.${printer}`),
}))

const ALLOWED_KEYS = [
  'qr',
  'serial_number',
  'batch_size',
  'date',
  'time',
  'article_type_name',
  'article_type_code',
  'article_type_gross_weight',
  'article_type_search_code',
  'article_type_barcode',
  'article_type_extra_description',
  'warehouse_code',
  'warehouse_name',
  'warehouse_locations',
  'quantity',
  'order_id',
  'operator_badge_id',
  'operator_name',
  'sales_order_description',
  'purchase_order_description',
  'transfer_warehouse_from_code',
  'transfer_warehouse_to_code',
  'transfer_description',
  'stock_count_default_storage_location_code',
].map((key) => ({ key, description: t(`printStep.field.labelTemplate.tracyKeys.${key}`) }))

const ERP_SLUGS = [
  'erp_order_id',
  'customer_name',
  'customer_code',
  'customer_VAT_number',
  'supplier_name',
  'supplier_code',
  'reference',
  'remarks',
  'delivery_address',
  'delivery_date',
  'delivery_name',
  'delivery_code',
  'receipt_date',
  'sales_order_line_description',
  'purchase_order_line_description',
  'amount_ex_vat',
  'shipping_method_description',
  'linked_erp_sales_order_id',
  'linked_erp_production_order_id',
  'linked_erp_purchase_orders_ids',
  'linked_erp_purchase_order_lines_ids',
  'linked_erp_sales_order_reference',
].map((key) => ({ key, description: t(`printStep.field.labelTemplate.erpSlugKeys.${key}`) }))

function stepsEmpty({ branches, steps }) {
  return steps.length === 0 && branches.every(stepsEmpty)
}

function getSubTree({ branches, steps }, step) {
  const pos = steps.indexOf(step)
  if (pos !== -1) {
    return { branches, steps: steps.slice(0, pos + 1) }
  }

  // eslint-disable-next-line
  for (const branch of branches) {
    const subTree = getSubTree(branch, step)
    if (!stepsEmpty(subTree)) {
      return subTree
    }
  }

  return { branches: [], steps: [] }
}

function flattenSteps({ branches, steps }) {
  const flatSteps = []
  // eslint-disable-next-line
  for (const branch of branches) {
    flatSteps.push(...flattenSteps(branch))
  }
  flatSteps.push(...steps)
  return flatSteps
}

export const CARRIER_ALLOWED_KEYS = [
  'delivery_address', 'delivery_name', 'serial_number', 'erp_order_id', 'carrier_barcode',
  'carrier_depot', 'carrier_order', 'carrier_numberofcollo', 'carrier_totalweight',
  'carrier_collodimensions', 'carrier_collonumber', 'customer_name', 'coli_type', 'collo_weight',
];

export function getAllowedKeys(step, steps, { metafields, parentMetafields }, { shipmentLines = false, erpSlugs = false } = {}) {
  return [
    ...ALLOWED_KEYS,
    ...erpSlugs ? ERP_SLUGS : [],
    ...(
      flattenSteps(getSubTree(steps, step))
        .flatMap((step) => {
          const keys = (
            [
              ...step.formStep.form.fields.map((field) => ({ step, field })),
              ...step.splitStep.form.fields.map((field) => ({ step, field }))
            ]
              .filter(({ field }) => field.slug !== null)
              .map(({ step, field }) => ({
                key: field.slug,
                description: `${field.label} (${step.label})`,
              }))
          )
          if (shipmentLines) {
            keys.push({
              key: 'shipment_lines[][]',
              description: t('printStep.field.labelTemplate.keys.shipment_lines.description'),
              subOptions: [
                SHIPMENT_LINES_HEADER_OPTIONS.map((key) => {
                  return {
                    key: key,
                    description: t(`printStep.field.labelTemplate.keys.shipment_lines.headerOption.${key}`)
                  }
                }),
                SHIPMENT_LINES_DETAIL_OPTIONS.map((key) => {
                  return {
                    key: key,
                    description: t(`printStep.field.labelTemplate.keys.shipment_lines.detailOption.${key}`)
                  }
                }),
              ]
            })
          }
          return keys
        })
        .sort((l, r) => (
          l.key.localeCompare(r.key.label) ||
          l.description.localeCompare(r.description.label)
        ))
    ),
    ...(
      [...metafields, ...parentMetafields]
        .filter(({ slug }) => slug !== null)
        .map((metafield) => ({ key: metafield.slug, description: metafield.name }))
    ),
  ]
}

@observer
export default class PrintStepEdit extends Component {
  static propTypes = {
    step: PropTypes.instanceOf(Step).isRequired,
    steps: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    batchType: PropTypes.instanceOf(BatchType).isRequired,
  }

  static defaultProps = {
    disabled: false,
  }

  constructor(...args) {
    super(...args)
    this.setType = this.setType.bind(this)
  }

  static contextType = MetafieldsContext

  @computed get isShippingDocument() {
    const { step, batchType } = this.props
    return step?.printStep?.type === 'document' &&
      ['receive_order', 'pick_order'].includes(batchType?.type)
  }

  @computed get allowedKeys() {
    const { step, steps, batchType } = this.props
    return getAllowedKeys(step, steps, this.context, {
      shipmentLines: this.isShippingDocument,
      erpSlugs: PROCESS_TYPES.includes(batchType.type) || TEMPLATE_TYPES.includes(batchType.type),
    })
  }

  @action setType(type) {
    const { step } = this.props
    step.printStep.setInput('type', type)
    if (type === 'label') {
      if (step.printStep.labelPrinter === null) {
        step.printStep.setInput('labelPrinter', 'altec')
      }
      if (step.printStep.labelTemplate === null) {
        step.printStep.setInput('labelTemplate', '')
      }
    }
    if (type === 'document') {
      if (step.printStep.documentOverlays === null) {
        step.printStep.setInput('documentOverlays', [])
      }
    }
    step.printStep.setInput('defaultPrinter', null)
  }

  render() {
    const { step, disabled } = this.props

    let printerOptions = []
    if (step.printStep.labelPrinter === 'altec') {
      printerOptions = window.viewStore.altecPrinterNames
    } else if (step.printStep.labelPrinter === 'zebra') {
      printerOptions = window.viewStore.zebraPrinterNames
    }

    let printerContent = null
    if (step.printStep.type === 'label') {
      printerContent = (
        <>
          <TargetRadioButtons
            target={step.printStep}
            name="labelPrinter"
            disabled={disabled}
            options={LABEL_PRINTER_OPTIONS}
            onChange={action((labelPrinter) => {
              step.printStep.setInput('labelPrinter', labelPrinter)
              step.printStep.setInput('defaultPrinter', null)
            })}
          />

          <TargetSelect clearable
            label={<>
              {t('printStep.field.defaultPrinter.label')}
              <RightDivider />
              <InfoPopup content={t('printStep.field.defaultPrinter.info')} />
            </>}
            target={step.printStep}
            name="defaultPrinter"
            disabled={disabled || step.printStep.labelPrinter === null}
            options={printerOptions.map((printer) => ({ value: printer, text: printer }))}
          />
          <TargetTemplate
            target={step.printStep}
            name="labelTemplate"
            disabled={disabled}
            allowedKeys={this.allowedKeys}
            viewTo={
              <PrintButtonModal
                title={t('printStep.field.labelTemplate.label')}
                getInstructions={() => step.printStep.labelTemplate}
                trigger={<PrintButton name="print" data-test-print-template-button />}
              />
            }
          />
        </>
      )
    } else if (step.printStep.type === 'document') {
      printerContent = (
        <>
          <TargetFile deletable
            target={step.printStep}
            name="documentBackground"
            disabled={disabled}
            accept="application/pdf"
          />
          <TargetOverlays
            target={step.printStep}
            name="documentOverlays"
            disabled={disabled}
            allowedKeys={this.allowedKeys}
            background={step.printStep.documentBackground}
            isShippingDocument={this.isShippingDocument}
          />
        </>
      )
    }

    return (
      <>
        <TargetRadioButtons data-test-pint-type-radio
          target={step.printStep}
          name="type"
          disabled={disabled}
          options={TYPE_OPTIONS}
          onChange={this.setType}
        />
        <TargetNumberInput
          target={step.printStep}
          name="copies"
          disabled={disabled}
        />
        {printerContent}
      </>
    )
  }
}
