import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { ExactPurchaseOrder } from 'store/Exact/PurchaseOrder'
import { PurchaseOrderLine } from 'store/PurchaseOrderLine'
import { ExactGoodsReceiptLineStore } from 'store/ExactGoodsReceiptLine'
import { ExactItem } from 'store/ExactItem'
import Decimal from 'decimal.js'

export class ExactPurchaseOrderLine extends Model {
  static backendResourceName = 'exact_purchase_order_line'

  @observable id = null
  @observable uuid = null
  @observable number = 0
  @observable quantity = Decimal(0)
  @observable quantityInPurchaseUnits = Decimal(0)
  @observable unit = ''
  @observable unitPrice = 0
  @observable netPrice = 0
  @observable receivedAt = null
  @observable created = null
  @observable modified = null

  relations() {
    return {
      exactPurchaseOrder: ExactPurchaseOrder,
      purchaseOrderLine: PurchaseOrderLine,
      goodsReceiptLines: ExactGoodsReceiptLineStore,
      exactItem: ExactItem,
    }
  }

  casts() {
    return {
      receivedAt: Casts.date,
      created: Casts.datetime,
      modified: Casts.datetime,
    }
  }
}

export class ExactPurchaseOrderLineStore extends Store {
  Model = ExactPurchaseOrderLine
  static backendResourceName = 'exact_purchase_order_line'
}
