import { observable } from 'mobx'
import { Model, Store } from 'store/Base'

export class Unit4Order extends Model {
  static backendResourceName = 'unit4_order'
  static idColor = 'grey'
  static idIcon = 'unit4'

  @observable id = null
  @observable orderId = null
  @observable orderDate = null
  @observable deliveryAddress = ''

  get _labelContent() {
    return this.orderId
  }
}

export class Unit4OrderStore extends Store {
  static backendResourceName = 'unit4_order'
  Model = Unit4Order
}
