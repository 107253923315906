import { Model, Store } from 'store/Base'
import { Integration } from '../Integration'
import { observable } from 'mobx'
import { ProductionRequest } from '../ProductionRequest';

export class NestRequest extends Model {
    static backendResourceName = 'nest_request';
    static idColor = 'black'

    @observable id = null;
    @observable nestId = '';
    @observable dimensions = '';
    @observable runTime = 0;
    @observable thickness = '';
    @observable material = '';
    @observable drawingImage = null;

    relations() {
        return {
            integration: Integration,
            productionRequest: ProductionRequest,
        };
    }

    get _labelContent() {
        return this.nestId
    }
}

export class NestRequestStore extends Store {
    static backendResourceName = 'nest_request'
    Model = NestRequest
}
