import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { Step } from 'store/Step'
import { MetafieldsContext } from 'component/Metafields'
import { BatchType } from '../../../store/BatchType'


@observer
export default class ByProductEdit extends Component {
  static propTypes = {
    step: PropTypes.instanceOf(Step).isRequired,
    steps: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    machineEnabled: PropTypes.bool,
    batchEnabled: PropTypes.bool,
    batchType: PropTypes.instanceOf(BatchType).isRequired,
    variable: PropTypes.shape({
      before: PropTypes.bool.isRequired,
      after: PropTypes.bool.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    disabled: false,
    machineEnabled: false,
    batchEnabled: true, // TODO should be false but we do not have the feature flag yet
  }

  static contextType = MetafieldsContext


  render() {

    return (
      <React.Fragment>
      </React.Fragment>
    )
  }
}
