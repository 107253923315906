import React from 'react'

import LabelDot from '../LabelDot'
import { COLORS } from '../colors'
import { t } from 'i18n'
import { TargetSelect } from '@code-yellow/spider'


/**
 * Select for one of the COLORS defined in `spider/colors`.
 */
export class TargetColorSelect extends TargetSelect {

  getOptions(props) {
    // We override this method instead of providing the options
    // to `defaultProps`, because we want the translation of
    // colors to react to language changes.
    return Object.entries(COLORS).map(
      ([name, color]) => ({
        value: name,
        text: (
          <React.Fragment>
            <LabelDot color={color} data-test-target-color={name}/>
            {t(`color.${name}`)}
          </React.Fragment>
        )
      })
    )
  }

}
