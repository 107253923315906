import { observable, action } from 'mobx'
import { Model, Store } from 'store/Base'
import { MetavalueStore } from './Metavalue'
import { Classification } from './Classification'
import { ArticleType } from './ArticleType'
import { LoadCarrier } from './LoadCarrier'

export class Metafield extends Model {
  static backendResourceName = 'metafield'
  static LEVELS = ['classification', 'article_type', 'production_request', 'load_carrier']
  static TYPES = ['text', 'check', 'image', 'choice', 'measure', 'format']

  @observable id = null

  @observable definitionLevel = null
  @observable entryLevel = null

  @observable name = ''
  @observable slug = null
  @observable type = 'text'
  @observable ordering = 0

  @observable textLong = false
  @observable choiceOptions = []
  @observable choiceMultiple = false
  @observable choiceAllowAdditions = false
  @observable measureMin = null
  @observable measureMax = null
  @observable formatFormat = []

  @observable default = false
  @observable defaultValue = null
  @observable defaultFile = null

  relations() {
    return {
      values: MetavalueStore,
      definitionClassification: Classification,
      definitionArticleType: ArticleType,
      definitionLoadCarrier: LoadCarrier,
    }
  }

  @action fromBackend(...args) {
    super.fromBackend(...args)

    if (this.textLong === null) {
      this.textLong = false
    }
    if (this.choiceOptions === null) {
      this.choiceOptions = []
    }
    if (this.choiceMultiple === null) {
      this.choiceMultiple = false
    }
    if (this.formatFormat === null) {
      this.formatFormat = []
    }
  }

  toBackend(...args) {
    const res = super.toBackend(args)

    if (this.type !== 'text') {
      res.text_long = null
    }
    if (this.type !== 'choice') {
      res.choice_options = null
      res.choice_multiple = null
      res.choice_allow_additions = null
    }
    if (this.type !== 'measure') {
      res.measure_min = null
      res.measure_max = null
    }
    if (this.type !== 'format') {
      res.format_format = null
    }
    if (!this.default) {
      res.default_value = null
      res.default_file = null
    }

    return res
  }
}

export class MetafieldStore extends Store {
  static backendResourceName = 'metafield'

  Model = Metafield
}
