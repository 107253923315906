import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { Button, Form, Modal, Icon } from 'semantic-ui-react'
import { RightDivider, TargetTextInput, TargetSelect, TargetCheckbox } from '@code-yellow/spider'
import styled from 'styled-components'

// components
import PrinterSelector from 'component/PrinterHelpers/PrinterSelector'
// end components

// helpers
import { showSaveNotification, showNotification } from 'helpers/notification'
import { isFeatureFlagEnabled } from 'helpers/featureFlags'
import { print, getPrinters, getPrinterType } from 'helpers/print'
import getGlobalValue from 'helpers/getGlobalValue'
// end helpers

// stores
import { Batch } from 'store/Batch'
import { StorageLocationStore } from 'store/StorageLocation'
// end stores

/**
 * Rework Batch button on Batch Overview. The `target` property is a Batch model, which will be copied and fetched
 * with the correct relations.
 */
@observer
export default class BatchReworkModal extends Component {
  static propTypes = {
    target: PropTypes.instanceOf(Batch).isRequired,
    trigger: PropTypes.func.isRequired,
    onClose: PropTypes.func,
  }

  @observable open = false
  @observable batch = new Batch({ id: this.props.target.id }, {
    relations: [
      'productionRequest.productionOrder.exactShopOrder',
      'productionRequest.productionOrder.exactGlobeProductionOrder',
      'batchType.articleType',
      'storageLocation.warehouse',
    ],
  })

  @observable reason = ''
  @observable storageLocation = this.props.target?.storageLocation?.id
  @observable createNewBatch = false
  @observable printLabel = false
  @observable print = null

  constructor(...args) {
    super(...args)

    this.onOpen = this.onOpen.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onChangeReason = this.onChangeReason.bind(this)
    this.onChangeStorageLocation = this.onChangeStorageLocation.bind(this)
    this.onChangeCreateNewBatch = this.onChangeCreateNewBatch.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onSubmitPrint = this.onSubmitPrint.bind(this)
    this.postRework = this.postRework.bind(this)
  }

  componentDidMount() {
    this.batch.setFetchParams({
      include_annotations: '*,resettable,rework_deletes_carrier_rows',
    })
    this.batch.fetch().then(() => {
      this.storageLocationStore = new StorageLocationStore({
        relations: ['warehouse', 'articleTypeWarehouses.articleType'],
        params: {
          '.warehouse': this.batch.storageLocation?.warehouse?.id,
          ...!isFeatureFlagEnabled('flexible_storage_locations') && {
            where: !this.batch.batchType?.articleType.isNew ? `article_type_warehouses(article_type=${this.batch.batchType?.articleType?.id})` : ''
          }
        }
      })
    })
  }

  getInstructions = async (batch) => {
    const now = new Date()
    const date = `${now.getFullYear()}-${now.getMonth()}-${now.getDate()}`
    const time = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`
    const articleType = batch?.batchType?.articleType

    const productionRequest = batch.productionRequest
    const exactShopOrder = productionRequest?.productionOrder?.exactShopOrder
    const exactGlobeProductionOrder = productionRequest?.productionOrder?.exactGlobeProductionOrder
    const erpOrderID = (!exactShopOrder?.isNew && exactShopOrder?.number) || (!exactGlobeProductionOrder?.isNew && exactGlobeProductionOrder?.productionOrderNumber)

    const template = await getGlobalValue('rework_label')
    const instructions = (
      template
        .replace(/\{\{batch_size\}\}/g, batch.quantity)
        .replace(/\{\{serial_number\}\}/g, batch.serialNumber)
        .replace(/\{\{date\}\}/g, date)
        .replace(/\{\{time\}\}/g, time)
        .replace(/\{\{article_type_code\}\}/g, articleType?.code)
        .replace(/\{\{article_type_name\}\}/g, articleType?.name)

        .replace(/\{\{erp_order_id\}\}/g, erpOrderID)
        .replace(/\{\{quantity\}\}/g, productionRequest?.quantity)
        .replace(/\{\{order_id\}\}/g, productionRequest?.productionOrder?.id)
        .replace(/\{\{[A-z]*\}\}/g, '')
    )

    return instructions
  }

  async onOpen(event) {
    event?.stopPropagation()
    this.open = true
    const [printerType, allPrinters, instructions] = await Promise.all([
      getGlobalValue('printer_model'),
      getPrinters(),
      this.getInstructions(this.batch),
    ])

    const printers = allPrinters.filter((printer) => getPrinterType(printer) === printerType)
    const printer = printers.length === 1 ? printers[0] : null

    this.print = { printerType, instructions, printers, printer }
  }

  onClose() {
    const { onClose } = this.props

    this.open = false
    this.print = null

    if (onClose) {
      onClose()
    }
  }

  onChangeReason(value) {
    this.reason = value
  }

  onChangeStorageLocation(value) {
    this.storageLocation = value
  }

  onChangeCreateNewBatch(value) {
    this.createNewBatch = value
  }

  postRework() {
    const batch = this.batch
    const givenBatch = this.props.target

    return batch.rework(this.reason, this.storageLocation, this.createNewBatch
    ).then((res) => {
      return givenBatch.fetch().then(showSaveNotification)
    }).catch((error) => {
      let messages = []
      if (error.response.status === 418) {
        // BinderRequestError
        messages.push(error.response.data.message)
      } else if (error.response.status === 400) {
        // BinderValidationError
        // eslint-disable-next-line
        for (const validation_error of error.response.data.errors) {
          messages.push(validation_error.message)
        }
      }
      // eslint-disable-next-line
      for (const message of messages) {
        showNotification({
          message: message,
          dismissAfter: 3000,
        })
      }
    })
  }

  @action async onSubmit(event) {
    event?.stopPropagation()

    await this.postRework().then(() => {
      this.onClose()
    })
  }

  @action async onSubmitPrint() {
    await this.postRework().then(() => {
      print(this.print.printer, this.print.instructions, { copies: this._copies })
      .then(() => {
        this.onClose()
      })
    })
  }

  @observable storageLocationStore = null

  render() {
    const { trigger } = this.props

    const ObjectCode = styled.span`
      color: #777;
      font-family: monospace, monospace;
      margin-right: 1em;
    `

    return (
      <Modal closeIcon data-test-batch-rework-modal
        closeOnDimmerClick={false}
        onClose={this.onClose}
        onOpen={this.onOpen}
        open={this.open}
        trigger={trigger({ onClick: this.onOpen, disabled: !this.batch.resettable })}
      >
        <Modal.Header>{t('performanceResetModal.title')}</Modal.Header>
        <Modal.Content>
          {this.batch.reworkDeletesCarrierRows && <div data-test-carrier-warning>
            <Icon style={{ color: '#EBBB12', marginRight: '0.5em' }} name={'warning sign'} />
            {t('performanceResetModal.carrierWarning')}
          </div>}
          <Form onSubmit={this.onSubmit}>
            <TargetTextInput
              autoFocus
              data-test-rework-reason
              label={t('performanceResetModal.reason')}
              name="reason"
              value={this.reason}
              onChange={this.onChangeReason}
            />
            <TargetSelect remote
              label={t('batch.field.storageLocation.label')}
              store={this.storageLocationStore}
              value={this.storageLocation}
              name="storageLocation"
              toOption={storageLocation => ({
                value: storageLocation.id,
                text: <span><ObjectCode>{storageLocation.code}</ObjectCode>{storageLocation.name}</span>
              })}
              onChange={this.onChangeStorageLocation}
            />
            <TargetCheckbox nolabel
              rightLabel
              label={t('performanceResetModal.createNewBatch')}
              name="createNewBatch"
              value={this.createNewBatch}
              onChange={this.onChangeCreateNewBatch}
            />
            <PrinterSelector print={this.print}/>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <RightDivider />
          <Button primary data-test-confirm-rework-button
            loading={this.batch.isLoading}
            icon="check"
            labelPosition="left"
            content={t('performanceResetModal.resetButton')}
            onClick={this.onSubmit}
          />
          <Button primary data-test-confirm-rework-print-button
            loading={this.batch.isLoading}
            icon="check"
            labelPosition="left"
            disabled={!this.createNewBatch || !this.print?.printer || this.print?.printer?.length === 0}
            content={t('performanceResetModal.printButton')}
            onClick={this.onSubmitPrint}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}
