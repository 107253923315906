import React from 'react'
import { observable, computed } from 'mobx'
import { Model, Store } from 'store/Base'
import { ProductionOrder } from './ProductionOrder'
import { ExactDocumentStore } from 'store/Exact/Document'
import { Popup, Label } from 'semantic-ui-react'

export class ExactShopOrder extends Model {
  static backendResourceName = 'exact_shop_order'
  static idColor = 'red'
  static idIcon = 'exact'

  static STATUS_NAMES = ['open', 'released', 'in_progress', 'finalized', 'complete']

  @observable id = null
  @observable number = null
  @observable uuid = null
  @observable status = 10
  @observable isReleased = false
  @observable reference = ''
  @observable producedQuantity = 0
  @observable isOnHold = false

  getUrl() {
    let shopUrl = this.api.baseUrl + this.url + 'exact/'
    shopUrl = shopUrl.replace(/([^:])(\/\/+)/g, '$1/')

    return shopUrl
  }

  getLink(props = {}) {
    return super.getLink({
      target: '_blank',
      ...props,
    })
  }

  getParentLink() {
    if (!this.exactParentShopOrder || this.exactParentShopOrder.isNew) {
      return
    }

    const idIcon = 'sitemap'
    const idColor = 'red'
    const to = this.exactParentShopOrder.getUrl()

    return (
      <Popup
        trigger={
          <Label data-test-parent-shop-order={this.exactParentShopOrder.id}
            id={this.exactParentShopOrder.id}
            to={to}
            icon={idIcon}
            color={idColor}
            content={this.exactParentShopOrder.number || this.exactParentShopOrder.id}
          />
        }
        content={<>{t('workloadPlanner.parentShopOrder', { number: this.exactParentShopOrder.number })}</>}
      />
    )
  }

  getTarget() {
    return '_blank'
  }

  get statusName() {
    switch (this.status) {
      case 10:
        return this.isReleased ? 'Released' : 'Open'
      case 20:
        return 'In progress'
      case 30:
        return 'Finalized'
      case 40:
        return 'Complete'
      default:
        return this.status
    }
  }

  @computed
  get _labelContent() {
    return this.number
  }

  relations() {
    return {
      productionOrder: ProductionOrder,
      exactDocuments: ExactDocumentStore,
      exactParentShopOrder: ExactShopOrder,
    }
  }
}

export class ExactShopOrderStore extends Store {
  Model = ExactShopOrder
  static backendResourceName = 'exact_shop_order'
}
