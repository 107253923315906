import styled from 'styled-components';
import { Content } from 're-cy-cle';

// No clue what these are, and why they are named this way, but they are not contained a bit
export const COLORS = {
  grey50: '#FAFAFA',
  grey800: '#424242',
  warning: '#FFF6E0',
  notWarning: '#FFF'
}

export const Caption1 = styled.span`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
`

export const Body2 = styled.span`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 12px;
  color: rgba(0, 0, 0, 0.87);
`

export const Body3 = styled.span`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 12px;
  color: rgba(0, 0, 0, 0.87);
`

export const FullContent = styled(Content)`
  main {
    padding: 25px;
    max-width: unset;
  }

  .ui.table {
    border-style: solid;
    border-collapse: collapse;
    tbody > tr.groupMember {
      border-left: 6px solid ${COLORS.grey800};
      border-right: 6px solid ${COLORS.grey800};
    }
    tbody > tr.borderMember {
      border-bottom: 6px solid ${COLORS.grey800};
    }
  }
`
