import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { Operator } from './Operator'
import { ProductionLine } from './ProductionLine'

export class OperatorAllocation extends Model {
  static backendResourceName = 'operator_allocation'

  @observable id = null
  @observable startTime = null
  @observable endTime = null
  @observable startDate = null
  @observable endDate = null
  @observable allDay = true
  @observable notes = ''

  relations() {
    return {
      operator: Operator,
      productionLine: ProductionLine,
    }
  }

  casts() {
    return {
      startTime: Casts.momentTime,
      endTime: Casts.momentTime,
      startDate: Casts.date,
      endDate: Casts.date,
    }
  }
}

export class OperatorAllocationStore extends Store {
  Model = OperatorAllocation
  static backendResourceName = 'operator_allocation'
}
