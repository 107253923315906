import { trimStart } from 'lodash'
import moment from 'moment'

export const DATE_FORMAT = 'DD-MM-YYYY'
export const COMPUTER_DATE_FORMAT = 'YYYY-MM-DD';

function parsePeriod(char) {
  switch (char.toUpperCase()) {
    case 'D':
      return 'days'
    case 'W':
      return 'weeks'
    case 'M':
      return 'months'
    default:
      throw new Error('Unknown period: ' + char)
  }
}

export function addInterval(momentInstance, interval) {
  if (!/P(\d+)[DWM]/.test(interval) || !moment.isMoment(momentInstance)) {
    return momentInstance
  }

  return momentInstance.clone().add(parseInt(trimStart(interval, 'P')), parsePeriod(interval.slice(-1)))
}

export function format(momentInstance, format = DATE_FORMAT, defaultResult = '') {
  if (!moment.isMoment(momentInstance)) {
    return defaultResult
  }

  return momentInstance.format(format)
}
