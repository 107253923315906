import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { SalesOrder } from './SalesOrder'

export class SalesOrderResponsibility extends Model {
  static backendResourceName = 'sales_order_responsibility'

  @observable id = null
  @observable name = null
  @observable company = null
  @observable role = null
  @observable email = null
  @observable phoneNumber = null

  relations() {
    return {
      salesOrder: SalesOrder,
    }
  }
}

export class SalesOrderResponsibilityStore extends Store {
  static backendResourceName = 'sales_order_responsibility'
  Model = SalesOrderResponsibility
}
