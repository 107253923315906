import styled from 'styled-components'
import { api } from 'store/Base'

export default styled.div`
  flex: 1;
  display: flex;

  background-image: url(${api.baseUrl}global_value/background_image/);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
`
