import React from 'react';
import { ModuleInterface } from '../module/module';
import { NavItem } from 're-cy-cle';
import { t } from 'i18next';
import { ModuleScreen } from './screens/module';
import enTranslations from './i18n/en';
import nlTranslations from './i18n/nl';
export const systemModule: ModuleInterface = {
  name: 'system-status',
  navigationMenu: () => ({
    basePath: '/system-status/',
    topMenuItem: <NavItem title={t('nav.main.system')} to="/system/modules" activePath="/system/" data-test-nav="system" />,
    subMenuItems: []
  }),
  routes: [{
    path: '/system/modules',
    screen: ModuleScreen
  }],
  translations: {
    en: enTranslations,
    nl: nlTranslations
  }
};