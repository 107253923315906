import styled from 'styled-components'
import { COLORS } from './colors'

/**
 * A small colored dot that may be placed in front of text.
 * Supports color as `#...`, `rgb(...)`, `rgba(...)`, or
 * as the name of a color defined COLORS in `spider/colors`
 */
const LabelDot = styled.span`
  display: inline-block;
  background-color: ${({ color }) => {
    if (!color){
      return 'rgba(0, 0, 0, 0)'  // transparant if no color provided
    }
    if(color.startsWith('#') || color.startsWith('rgb(') || color.startsWith('rgba(')){
      return color
    }
    else{
      return COLORS[color]
    }
    }};
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  border: 1px solid rgba(34, 36, 38, 0.15);
  margin-left: ${({ marginLeft }) => (marginLeft ? '0.3rem' : '-0.3rem')};
  margin-right: 0.3rem;
`
export default LabelDot;
