import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { ExactIntegration } from 'store/ExactIntegration'
import { ExactDocument } from './Document'

export class ExactDocumentAttachment extends Model {
  static backendResourceName = 'exact_document_attachment'

  @observable id = null
  @observable uuid = null
  @observable fileName = null
  @observable fileSize = null
  @observable fileUrl = null


  casts() {
    return {
      modified: Casts.datetime,
    }
  }


  relations() {
    return {
      integration: ExactIntegration,
      exactDocument: ExactDocument,
    }
  }
}

export class ExactDocumentAttachmentStore extends Store {
  static backendResourceName = 'exact_document_attachment'
  Model = ExactDocumentAttachment
}
