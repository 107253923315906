import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reaction, action } from 'mobx'
import { observer } from 'mobx-react'
import { Button, Segment, Icon, Form } from 'semantic-ui-react'
import { Section, SectionStore, Part } from 'store/Section'
import TargetMarkdown from 'component/TargetMarkdown'
import styled from 'styled-components'
import PlaceholderImage from 'image/placeholder-image.png'
import Dropzone from 'react-dropzone'
import { stripQueryParams } from '@code-yellow/spider'
import ReactMarkdown from 'react-markdown'
import { theme } from 'styles'
import { MetafieldEdit } from 'container/Process/Step/Form'

const EmptyMessageContainer = styled.div`
  text-align: center;
  padding: 1.5rem;
  color: rgba(0, 0, 0, 0.5);
`

const SectionSegment = styled.div`
  position: relative;
  ${({ important }) =>
    important
      ? `
        background-color: #FFFBF0;
    `
      : ''}
  transition: background-color 300ms ease;
`

const SectionSegmentGroup = styled(Segment)`
  max-width: 900px;
  margin: 0 auto !important;
  padding: 0.25rem !important;

  > ${SectionSegment}.important {
    margin-left: -0.25rem;
    padding-left: 0.25rem;

    margin-right: -0.25rem;
    padding-right: 0.25rem;

    &:first-child {
      border-top-left-radius: calc(0.28571429rem - 1px);
      border-top-right-radius: calc(0.28571429rem - 1px);
      margin-top: -0.25rem;
      padding-top: 0.25rem;
    }

    &:last-child {
      border-bottom-left-radius: calc(0.28571429rem - 1px);
      border-bottom-right-radius: calc(0.28571429rem - 1px);
      margin-bottom: -0.25rem;
      padding-bottom: 0.25rem;
    }
  }
`

const SectionImportantIcon = styled(({ active, ...props }) => <Icon data-test-important-section name="exclamation triangle" {...props} />)`
  position: absolute;
  left: -1rem;
  top: 50%;
  transform: translate(-100%, -50%);
  margin: 0 !important;
  font-size: 1.5rem !important;
  cursor: pointer;
  opacity: ${({ active }) => (active ? 0.75 : 0.25)} !important;
  transition: opacity 300ms ease;
`

const FloatingButtonGroup = styled(({ hidden, top, left, right, children, ...props }) => (
  <Button.Group basic size="mini" vertical={left || right} {...props}>
    {children}
  </Button.Group>
))`
  position: absolute;
  z-index: 100;
  ${({ top, left, right, index, buttons }) => {
    if (top) {
      return `
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%);
    `;
    }

    if (right) {
      return `
        right: calc(-0.25rem - 1px);
        top: 50%;
        transform: translate(50%, -50%);`
    }

    return `
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 50%);
    `;
  }}
  margin: 0 !important;

  background-color: #fff !important;
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15) !important;
  border-radius: 999px !important;
  > .ui.button.ui.button.ui.button.ui.button.ui.button:hover {
    background-color: transparent !important;
  }

  ${({ hidden }) =>
    hidden && false
      ? `
        opacity: 0;
        &:hover {
            opacity: 1;
        }
        transition: opacity 300ms ease;
    `
      : ''}
`

// const SectionContainer = styled.div`
//   padding: 0.5rem;
//   max-width: 900px;
//   margin: 0 auto;
// `

const SectionBody = styled.div`
  display: flex;
  justify-content: center;
`

const BasicPart = styled.div`
  flex: 1 1 0;
  margin: 0.25rem;
  border-radius: 0.28571429rem;
  position: relative;
  > div:first-child {
    > div {
      transition: filter 300ms ease;
    }
  }
  min-height: 6rem;
  ${({ edit }) =>
    edit
      ? `
        > div:first-child {
            overflow: hidden;
            > div {
                filter: blur(4px);
            }
        }
    `
      : ''}
`

const ImageOverlay = styled.div`
  position: absolute;
  border-radius: calc(0.28571429rem - 1px);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  transition: opacity 300ms ease;
`

const StyledDropzone = styled(Dropzone)`
  width: unset;
  height: unset;
  border: unset;
  cursor: pointer;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  > img {
    width: 100%;
    border-radius: calc(0.28571429rem - 1px);
    transition: filter 300ms ease;
  }
  overflow: hidden;
  position: relative;
  line-height: 0;
  margin-bottom: 0.25rem;
`

const ImagePart = styled.div`
  margin: 0.25rem;
  width: ${({ width }) => width}%;
  flex: 0 1 auto;
  position: relative;
  overflow: hidden;
  border-radius: 0.28571429rem;
  ${({ edit }) =>
    edit
      ? `
        > div:first-child {
            filter: blur(4px);
        }
    `
      : `
        > div:first-child > div:hover {
            img {
                filter: blur(4px);
            }
        }
    `}
  > div:first-child {
    > img {
      width: 100%;
      border-radius: calc(0.28571429rem - 1px);
    }
  }
`

const PartOverlay = styled.div`
  position: absolute;
  border-radius: calc(0.28571429rem - 1px);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 300ms ease;
  line-height: normal;
  border: 1px solid rgba(34, 36, 38, 0.15);
  ${({ active }) =>
    active
      ? ''
      : `
        opacity: 0;
        pointer-events: none;
    `}
  transition: opacity 300ms ease;
`

const PartCornerIcon = styled(Icon)`
  font-size: 1.25em !important;
  position: absolute;
  top: 0.5rem;
  ${({ left = false, offset = 0 }) =>
    left
      ? `
        left: ${1.75 * offset + 0.5}rem;
    `
      : `
        right: ${1.75 * offset + 0.5}rem;
    `}
  cursor: pointer;
  margin: 0 !important;
  color: rgba(0, 0, 0, 0.5);
  &:hover {
    color: rgba(0, 0, 0, 0.75);
  }
`

const PartTypeIcon = styled(Icon)`
  font-size: 2em !important;
  margin: 0 0.25em !important;
  ${({ active }) =>
    active
      ? `
        color: ${theme.primaryColor};
    `
      : `
        cursor: pointer;
        color: rgba(0, 0, 0, 0.5);
        &:hover {
            color: rgba(0, 0, 0, 0.75);
        }
    `}
`

const TablePartTable = styled.table`
  width: 100%;
  border-spacing: 0;
`

const TablePartRow = styled.tr``

const TablePartCell = styled.td`
  overflow: auto;
  ${({ collapsing }) =>
    collapsing
      ? `
        width: 1px;
        white-space: nowrap;
    `
      : ''}
  ${({ add, outside, left, top, right, bottom, onClick, hideIcon }) =>
    outside
      ? `
        text-align: center;
        border: 1px solid transparent;
        border-radius: 0.28571429rem;
        padding: 0.25rem;
        > i.icon {
            margin: 0 !important;
        }
        ${onClick
        ? `
            cursor: pointer;
            > i.icon {
                opacity: ${hideIcon ? 0 : 0.25} !important;
                &:hover {
                    opacity: 0.5 !important;
                }
            }
        `
        : `
            > i.icon {
                opacity: 0.5 !important;
            }
        `
      }
    `
      : `
        background-color: ${top ? '#f1f2f3' : '#fff'};
        border-width:
            ${top ? '1px' : 0}
            ${right ? '1px' : 0}
            1px
            ${left ? '1px' : 0};
        border-style: solid;
        border-color: rgba(34, 36, 38, 0.15);
        border-radius:
            ${top && left ? '0.28571429rem' : '0'}
            ${top && right ? '0.28571429rem' : '0'}
            ${bottom && right ? '0.28571429rem' : '0'}
            ${bottom && left ? '0.28571429rem' : '0'};

        > div {
            padding: 0 0.25em;
            margin-right: 1.25em;
        }
        > div:last-child {
            margin-right: 0;
        }
        position: relative;
        > i.icon {
            position: absolute;
            top: 50%;
            right: 0.25em;
            transform: translateY(-50%);
            line-height: 1;
            margin: 0 !important;
            cursor: pointer;
            opacity: 0 !important;
            &:hover {
                opacity: 0.5 !important;
            }
        }
    `}
    > i.icon {
    transition: opacity 300ms ease;
  }
`

const PART_ICONS = {
  text: 'align left',
  image: 'image',
  table: 'table',
  meta: 'question circle',
}

@observer
class SectionComponent extends Component {
  static propTypes = {
    section: PropTypes.instanceOf(Section).isRequired,
    disabled: PropTypes.bool,
    editable: PropTypes.bool,
    onRemove: PropTypes.func.isRequired,
    onMoveUp: PropTypes.func,
    onMoveDown: PropTypes.func,
    onAddAbove: PropTypes.func,
    onAddBelow: PropTypes.func,
    layoutEdit: PropTypes.bool,
    showImportant: PropTypes.bool,
    metavalues: PropTypes.object,
  }

  static defaultProps = {
    disabled: false,
    editable: false,
    layoutEdit: false,
    showImportant: false,
  }

  constructor(...args) {
    super(...args)
    this.renderPart = this.renderPart.bind(this)
  }

  componentDidMount() {
    this.setOrderingReaction = reaction(
      () => this.props.section.parts.map(({ cid }) => cid).join(','),
      action((cids) => {
        let i = 0
        // eslint-disable-next-line
        for (const part of this.props.section.parts.models) {
          part.setInput('ordering', i++)
        }
      })
    )
  }

  componentWillUnmount() {
    this.setOrderingReaction()
  }

  @action setLayout(layout) {
    const { section } = this.props

    const parts = []
    // eslint-disable-next-line
    for (const type of layout.split('_')) {
      parts.push(
        section.parts.find((part) => part.type === type) ||
        new Part(
          { type },
          {
            relations: section.__activeRelations
              .filter((rel) => rel.startsWith('parts.'))
              .map((rel) => rel.substring('parts.'.length)),
          }
        )
      )
    }
    section.parts.models = parts
    section.parts.markChanged()
  }

  renderTextPart(part) {
    const { disabled, editable, layoutEdit } = this.props

    return (
      <BasicPart edit={layoutEdit} key={part.cid}>
        <div>
          <div>
            {editable ? (
              <TargetMarkdown
                noLabel
                target={part.textPart}
                name="text"
                disabled={disabled}
                placeholder={t('textPart.field.text.placeholder')}
              />
            ) : (
              <ReactMarkdown source={part.textPart.text} />
            )}
          </div>
        </div>
        {this.renderPartOverlay(part)}
      </BasicPart>
    )
  }

  renderTablePart(part) {
    const { layoutEdit, disabled, editable } = this.props

    return (
      <BasicPart edit={layoutEdit} key={part.cid}>
        <div>
          <div>
            <TablePartTable>
              {part.tablePart.table.map((row, r) => (
                <TablePartRow key={r}>
                  {row.map((cell, c) => (
                    <TablePartCell
                      key={c}
                      left={c === 0}
                      top={r === 0}
                      right={c === row.length - 1}
                      bottom={r === part.tablePart.table.length - 1}
                    >
                      <TargetMarkdown
                        noLabel
                        inline
                        value={cell}
                        onChange={action((value) => {
                          row[c] = value
                          part.tablePart.markChanged('table')
                        })}
                        disabled={disabled}
                      />
                      {r === 0 && row.length > 1 && (
                        <Icon
                          name="delete"
                          onClick={action(() => {
                            // eslint-disable-next-line
                            for (const row of part.tablePart.table) {
                              row.splice(c, 1)
                            }
                          })}
                        />
                      )}
                    </TablePartCell>
                  ))}
                  {editable && !disabled && (
                    <TablePartCell
                      outside
                      collapsing
                      hideIcon={r === 0}
                      onClick={
                        r === 0
                          ? action(() => {
                            // eslint-disable-next-line
                            for (const row of part.tablePart.table) {
                              row.push('')
                            }
                            part.tablePart.markChanged('table')
                          })
                          : () => part.tablePart.table.splice(r, 1)
                      }
                    >
                      <Icon name={r === 0 ? 'add' : 'delete'} />
                    </TablePartCell>
                  )}
                </TablePartRow>
              ))}
              {editable && !disabled && (
                <TablePartRow>
                  <TablePartCell
                    outside
                    colSpan={part.tablePart.table[0].length}
                    onClick={action(() => {
                      const row = []
                      for (let i = 0; i < part.tablePart.table[0].length; i++) {
                        row.push('')
                      }
                      part.tablePart.table.push(row)
                      part.tablePart.markChanged('table')
                    })}
                  >
                    <Icon name="add" />
                  </TablePartCell>
                </TablePartRow>
              )}
            </TablePartTable>
          </div>
        </div>
        {this.renderPartOverlay(part)}
      </BasicPart>
    )
  }

  onImageDrop(imagePart, images) {
    // eslint-disable-next-line
    for (const image of images) {
      imagePart.setInput('image', image)
    }

    // set previews
    images.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file)
      })
    )
  }

  renderImagePart(part) {
    const { disabled, editable, layoutEdit } = this.props

    const imageUrl = typeof part.imagePart.image === 'string' ? part.imagePart.image : part.imagePart.image?.preview
    const imageSrc = stripQueryParams(imageUrl) || PlaceholderImage

    let image = <img alt="" src={imageSrc} data-test-img-is-placeholder={imageUrl === undefined}/>

    if (editable && !disabled) {
      image = (
        <StyledDropzone
          onDrop={(images) => this.onImageDrop(part.imagePart, images)}
          accept="image/jpeg, image/png, image/gif"
        >
          {image}
          <ImageOverlay>
            <Icon name="upload" size="big" />
          </ImageOverlay>
        </StyledDropzone>
      )
    }

    return (
      <ImagePart width={part.imagePart.width} edit={layoutEdit} editable={editable} key={part.cid}>
        <div>
          {image}
          {editable ? (
            <TargetMarkdown
              noLabel
              inline
              target={part.imagePart}
              name="subtitle"
              disabled={disabled}
              placeholder={t('imagePart.field.subtitle.placeholder')}
            />
          ) : (
            <ReactMarkdown source={part.imagePart.subtitle} />
          )}
        </div>
        {this.renderPartOverlay(part)}
      </ImagePart>
    )
  }

  renderMetaPart(part) {
    const { disabled, editable, metavalues } = this.props

    if (editable) {
      return (
        <BasicPart>
          <div>
              <Form>
                <MetafieldEdit includeParentLevels
                  target={part.metaPart}
                  name="metafield"
                  disabled={disabled}
                />
              </Form>
          </div>
          {this.renderPartOverlay(part)}
        </BasicPart>
      )
    }

    const metavalue = metavalues.find((metavalue) => metavalue.metafield.id === part.metaPart.metafield.id) ?? null

    if (part.metaPart.metafield.type === 'image') {
      return (
        <ImagePart width={100}>
          <div>
            <img alt="" src={metavalue?.file ?? PlaceholderImage} />
          </div>
          {this.renderPartOverlay(part)}
        </ImagePart>
      )
    }

    return (
      <BasicPart>
        <div>
          {metavalue && metavalue.value}
        </div>
        {this.renderPartOverlay(part)}
      </BasicPart>
    )
  }
  renderPartOverlay(part) {
    const { section, layoutEdit } = this.props
    const i = section.parts.models.indexOf(part)

    return (
      <PartOverlay active={layoutEdit}>
        {i === 0 && (
          <FloatingButtonGroup left hidden>
            <Button data-test-add-part-h icon="add" onClick={() => this.addPart(i)} />
          </FloatingButtonGroup>
        )}
        {part.type === 'image' && (
          <React.Fragment>
            <PartCornerIcon
              left
              name="zoom out"
              disabled={part.imagePart.width <= 10}
              onClick={() => part.imagePart.setInput('width', part.imagePart.width - 5)}
            />
            <PartCornerIcon
              left
              name="zoom in"
              disabled={part.imagePart.width >= 100}
              onClick={() => part.imagePart.setInput('width', part.imagePart.width + 5)}
              offset={1}
            />
          </React.Fragment>
        )}
        <PartCornerIcon name="delete" onClick={() => this.removePart(part)} />
        {Part.TYPES.map((type) => (
          <PartTypeIcon
            data-test-part-type={type}
            name={PART_ICONS[type]}
            active={part.type === type}
            onClick={part.type === type ? undefined : () => this.changePart(part, type)}
          />
        ))}
        <FloatingButtonGroup right hidden>
          <Button data-test-add-part-h icon="add" onClick={() => this.addPart(i + 1)} />
          {i !== section.parts.length - 1 && (
            <Button
              icon="exchange"
              onClick={action(() => {
                section.parts.models[i] = section.parts.models[i + 1]
                section.parts.models[i + 1] = part
                section.parts.markChanged()
              })}
            />
          )}
        </FloatingButtonGroup>
      </PartOverlay>
    )
  }

  renderPart(part) {
    switch (part.type) {
      case 'text':
        return this.renderTextPart(part)
      case 'table':
        return this.renderTablePart(part)
      case 'image':
        return this.renderImagePart(part)
      case 'meta':
        return this.renderMetaPart(part)
      default:
        throw new Error(`Unknown part: ${part.type}`)
    }
  }

  changePart(part, type) {
    part.setInput('type', type)
  }

  @action removePart(part) {
    const { section, onRemove } = this.props
    section.parts.remove(part)
    if (section.parts.length === 0) {
      onRemove()
    }
  }

  @action addPart(pos) {
    const { section } = this.props
    section.parts.add({ type: 'text' })
    if (pos !== undefined) {
      section.parts.models.splice(pos, 0, section.parts.models.pop())
    }
  }

  render() {
    const { section, disabled, editable, onMoveUp, onMoveDown, onAddAbove, onAddBelow, layoutEdit, showImportant } = this.props

    let sectionNode = <SectionBody>{section.parts.map(this.renderPart)}</SectionBody>

    if (editable) {
      sectionNode = (
        <SectionSegment className={section.important ? 'important' : undefined} important={section.important}>
          {editable && (
            <SectionImportantIcon
              active={section.important}
              onClick={disabled ? undefined : () => section.setInput('important', !section.important)}
            />
          )}
          {layoutEdit && !disabled && onAddAbove && (
            <FloatingButtonGroup top hidden>
              <Button data-test-add-part-v icon="add" onClick={onAddAbove} />
              {onMoveUp && (
                <Button onClick={onMoveUp}>
                  <Icon name="exchange" rotated="clockwise" />
                </Button>
              )}
            </FloatingButtonGroup>
          )}
          {sectionNode}
          {layoutEdit && !disabled && onAddBelow && (
            <FloatingButtonGroup hidden>
              <Button data-test-add-part-v icon="add" onClick={onAddBelow} />
              {onMoveDown && (
                <Button icon onClick={onMoveDown}>
                  <Icon name="exchange" rotated="clockwise" />
                </Button>
              )}
            </FloatingButtonGroup>
          )}
        </SectionSegment>
      )
    } else if (showImportant) {
      sectionNode = (
        <SectionSegment className={section.important ? 'important' : undefined} important={section.important}>
          {sectionNode}
        </SectionSegment>
      )
    }

    return sectionNode
  }
}

@observer
export default class SectionsComponent extends Component {
  static propTypes = {
    sections: PropTypes.instanceOf(SectionStore).isRequired,
    disabled: PropTypes.bool,
    editable: PropTypes.bool,
    onMoveUp: PropTypes.func,
    onMoveDown: PropTypes.func,
    layoutEdit: PropTypes.bool,
    showImportant: PropTypes.bool,
    metavalues: PropTypes.object,
  }

  static defaultProps = {
    disabled: false,
    editable: false,
    layoutEdit: false,
    showImportant: false,
  }

  constructor(...args) {
    super(...args)
    this.renderSection = this.renderSection.bind(this)
    this.addSection = this.addSection.bind(this)
  }

  componentDidMount() {
    this.sectionsReaction = reaction(
      () => this.props.sections,
      (sections) => {
        if (this.setOrderingReaction) {
          this.setOrderingReaction()
        }
        this.setOrderingReaction = reaction(
          () => sections.map(({ cid }) => cid).join(','),
          action(() => {
            let i = 0
            // eslint-disable-next-line
            for (const section of this.props.sections.models) {
              section.setInput('ordering', i++)
            }
          })
        )
      },
      { fireImmediately: true }
    )
  }

  componentWillUnmount() {
    this.sectionsReaction()
    this.setOrderingReaction()
  }

  @action moveSection(from, to) {
    const { sections } = this.props
    const temp = sections.models[from]
    sections.models[from] = sections.models[to]
    sections.models[to] = temp
  }

  renderSection(section, i) {
    const { sections, disabled, editable, onMoveUp, onMoveDown, layoutEdit, showImportant, metavalues } = this.props

    let onMoveUpFunction = undefined
    if (i > 0) {
      onMoveUpFunction = () => this.moveSection(i, i - 1)
    } else if (onMoveUp) {
      onMoveUpFunction = () => onMoveUp(section)
    }
    let onMoveDownFunction = undefined
    if (i < sections.length - 1) {
      onMoveDownFunction = () => this.moveSection(i, i + 1)
    } else if (onMoveDown) {
      onMoveDownFunction = () => onMoveDown(section)
    }

    return (
      <SectionComponent
        key={section.cid}
        section={section}
        disabled={disabled}
        editable={editable}
        onRemove={() => sections.remove(section)}
        onMoveUp={onMoveUpFunction}
        onMoveDown={onMoveDownFunction}
        onAddAbove={i > 0 ? undefined : () => this.addSection(0)}
        onAddBelow={() => this.addSection(i + 1)}
        layoutEdit={layoutEdit}
        showImportant={showImportant}
        metavalues={metavalues}
      />
    )
  }

  @action addSection(index) {
    const { sections } = this.props
    sections.add({ parts: [{ type: 'text' }] })
    if (index !== sections.length - 1) {
      const section = sections.models.pop()
      sections.models.splice(index, 0, section)
    }
  }

  render() {
    const { layoutEdit, sections, disabled } = this.props

    let content = null
    if (sections.length === 0) {
      content = (
        <SectionSegmentGroup>
          <SectionSegment>
            <EmptyMessageContainer>
              {t('step.field.sections.empty')}
              {layoutEdit && !disabled && (
                <FloatingButtonGroup>
                  <Button data-test-add-part-v icon="add" onClick={() => this.addSection(0)} />
                </FloatingButtonGroup>
              )}
            </EmptyMessageContainer>
          </SectionSegment>
        </SectionSegmentGroup>
      )
    } else {
      content = <SectionSegmentGroup>{sections.map(this.renderSection)}</SectionSegmentGroup>
    }

    return (
      <React.Fragment>
        {content}
      </React.Fragment>
    )
  }
}
