import { BatchStore } from 'store/Batch'
import Decimal from 'decimal.js'


function getBaseCounts({ branches, steps }, postCounts, quantity) {
    const counts = {}

    for (let i = steps.length - 1; i >= 0; i--) {
        counts[`pre_${steps[i].id}`] = Decimal(0)
        counts[`post_${steps[i].id}`] = postCounts[`post_${steps[i].id}`] || Decimal(0)

        quantity = Decimal.sub(quantity, Decimal(counts[`post_${steps[i].id}`])) // TODO: review double decimal?

        if (steps[i].type === 'multiplier') {
            quantity = Decimal.mul(quantity, steps[i].multiplierStep.multiplier) // TODO: review double decimal?
        }
    }

    if (branches.length > 0) {
        // eslint-disable-next-line
        for (const branch of branches) {
            Object.assign(counts, getBaseCounts(branch, postCounts, quantity))
        }
    } else if (steps.length > 0) {
        counts[`pre_${steps[0].id}`] = quantity
    }

    return counts
}

function MUTATING_countsPostToPre({ branches, steps }, counts) {
    for (let i = steps.length - 1; i > 0; i--) {
        counts[`pre_${steps[i].id}`] = counts[`pre_${steps[i].id}`].add(counts[`post_${steps[i - 1].id}`])
        counts[`post_${steps[i - 1].id}`] = Decimal(0)
    }
    if (branches.length > 0 && steps.length > 0) {
        const count = Decimal.min(...branches.map((branch) => counts[`post_${branch.steps[branch.steps.length - 1].id}`]))
        counts[`pre_${steps[0].id}`] = counts[`pre_${steps[0].id}`].add(count)
        // eslint-disable-next-line
        for (const branch of branches) {
            counts[`post_${branch.steps[branch.steps.length - 1].id}`] = counts[`post_${branch.steps[branch.steps.length - 1].id}`].sub(count)
        }
    }
    // eslint-disable-next-line
    for (const branch of branches) {
        MUTATING_countsPostToPre(branch, counts)
    }
}

function countsPostToPre(branch, counts) {
    const newCounts = { ...counts }
    MUTATING_countsPostToPre(branch, newCounts)
    return newCounts
}

function getSubprocessesStep({ branches, steps }) {
    const step = steps.find((step) => step.type === 'subprocesses')
    if (step !== undefined) {
        return step
    }
    // eslint-disable-next-line
    for (const branch of branches) {
        const step = getSubprocessesStep(branch)
        if (step !== undefined) {
            return step
        }
    }
    return null
}

function getMultiplierSteps({ branches, steps }) {
    const multiplierSteps = steps.filter((s) => s.type === 'multiplier')
    // eslint-disable-next-line
    for (const branch of branches) {
        multiplierSteps.push(...getMultiplierSteps(branch))
    }
    return multiplierSteps
}

export function divideCount(count, n) {
    if (count === 0) {
        return 0
    }
    if (n === null) {
        return 1
    }
    return count.div(n)
}

export function findStep({ branches, steps }, id) {
    const step = steps.find((step) => step.id === id)
    if (step !== undefined) {
        return step
    }
    // eslint-disable-next-line
    for (const branch of branches) {
        const step = findStep(branch, id)
        if (step !== undefined) {
            return step
        }
    }
    return undefined
}

function addTotalCounts({ branches, steps }, counts, total, quantity) {
    // return counts
    const countsWithTotals = {}

    if (quantity === undefined) {
        quantity = total
    }

    for (let i = steps.length - 1; i >= 0; i--) {
        const step = steps[i]

        const post = counts[`post_${step.id}`]
        countsWithTotals[`post_${step.id}`] = post
        quantity = quantity.sub(post)

        countsWithTotals[`total_post_${step.id}`] = quantity

        if (step.type === 'multiplier') {
            total = total.mul(step.multiplierStep.multiplier)
            quantity = quantity.mul(step.multiplierStep.multiplier)
        }

        const pre = counts[`pre_${step.id}`]
        countsWithTotals[`pre_${step.id}`] = pre
        quantity = quantity.sub(pre)

        countsWithTotals[`total_pre_${step.id}`] = total.sub(quantity)
    }

    // eslint-disable-next-line
    for (const branch of branches) {
        Object.assign(countsWithTotals, addTotalCounts(branch, counts, total, quantity))
    }

    return countsWithTotals
}

// TODO: find all occurences of getCounts, and check that `done` is a decimal.
export function getCounts({ steps, quantity, done = Decimal(0), batches = [], superrequestAtSubprocesses = true, subrequestsFinished = false }) {
    if (batches instanceof BatchStore) {
        batches = batches.models
    }

    let counts = {}
    // eslint-disable-next-line
    for (const batch of batches) {
        if (batch.scrapReason) {
            continue;
        }
        if (!batch.lastStep) {
            continue;
        }

        let lastStep = findStep(steps, batch.lastStep.id)


        if (!lastStep) {
            continue;
        }

        let nextStep = findStep(steps, lastStep.nextStep.id)

        if (!nextStep) {
            done = Decimal.add(done, batch.quantity)
        } else {
            const key = `post_${lastStep.id}`
            if (counts[key] === undefined) {
                counts[key] = Decimal(0)
            }
            counts[key] = Decimal.add(counts[key], batch.quantityRemaining)
        }
    }

    counts = getBaseCounts(steps, counts, superrequestAtSubprocesses ? Decimal.sub(quantity, done) : Decimal(0))

    const subprocessesStep = getSubprocessesStep(steps)
    const multiplierSteps = getMultiplierSteps(steps)
    let diff = true
    while (diff) {
        counts = countsPostToPre(steps, counts)
        diff = false
        if (subprocessesStep && subrequestsFinished && counts[`pre_${subprocessesStep.id}`].gt(0)) {
            counts[`post_${subprocessesStep.id}`] = counts[`pre_${subprocessesStep.id}`]
            counts[`pre_${subprocessesStep.id}`] = Decimal(0)
            diff = true
        }
        // eslint-disable-next-line
        for (const step of multiplierSteps) {
            if (counts[`pre_${step.id}`].gte(step.multiplierStep.multiplier)) {
                counts[`post_${step.id}`] = counts[`post_${step.id}`].add(counts[`pre_${step.id}`].div(step.multiplierStep.multiplier))
                counts[`pre_${step.id}`] = counts[`pre_${step.id}`].mod(step.multiplierStep.multiplier)
                diff = true
            }
        }
    }

    counts = addTotalCounts(steps, counts, superrequestAtSubprocesses ? quantity.sub(done) : Decimal(0))

    return counts
}
