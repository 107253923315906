import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { PurchaseOrderStore } from './PurchaseOrder'
import { SalesOrderStore } from './SalesOrder'

export class ShippingMethod extends Model {
  static backendResourceName = 'shipping_method'

  @observable id = null
  @observable code = ''
  @observable description = ''
  @observable notes = ''
  @observable active = false
  @observable shippingRatesUrl = null
  @observable trackingUrl = null
  @observable createdAt = null
  @observable updatedAt = null

  relations() {
    return {
      purchaseOrders: PurchaseOrderStore,
      salesOrders: SalesOrderStore,
    }
  }
}

export class ShippingMethodStore extends Store {
  static backendResourceName = 'shipping_method'
  Model = ShippingMethod
}
