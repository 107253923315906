import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ExactIntegration } from 'store/ExactIntegration'
import { ItemAssortment } from '../ItemAssortment'
import { ExactItemAssortmentPropertyStore } from './ItemAssortmentProperty'

export class ExactItemAssortment extends Model {
  static backendResourceName = 'exact_item_assortment'

  @observable id = null
  @observable uuid = null
  @observable code = ''
  @observable description = ''

  relations() {
    return {
      integration: ExactIntegration,
      itemAssortment: ItemAssortment,
      properties: ExactItemAssortmentPropertyStore,
    }
  }
}

export class ExactItemAssortmentStore extends Store {
  static backendResourceName = 'exact_item_assortment'
  Model = ExactItemAssortment
}
