import styled from 'styled-components'

export const PickLinesLinkContainer = styled.div`
margin-bottom: 20px;
`
export const IconButtonContainer = styled.div`
width:60px;
display:inline-block;
`

export const TextPickLinesHoverContainer = styled.div`
padding: 20px;
font-size: 30px;
text-align: center;
border-left: 4px solid rgba(137, 196, 244);
border-right: 4px solid rgba(137, 196, 244);
`

export const PickLinesAmountContainer = styled.div`
display:inline-block;
margin: 2px;
`

export const PickLinesHoverContainer = styled.div`
width:212px;
height:64px;
display: grid;
grid-template-columns: auto auto auto;
`

// We need to nicely format the size of files for the frontend
// Solution taken and modified from https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export function formatBytes(bytes, decimals = 0) {
    if (!+bytes) return '0 Bytes'

    const k = 1000
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}