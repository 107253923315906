import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { Operator } from './Operator'
import { User } from './User'

export class ExtraLeaveBalance extends Model {
  static backendResourceName = 'extra_leave_balance'

  @observable id = null
  @observable year = null
  @observable legalLeaveMinutes = 0
  @observable extraLeaveMinutes = 0
  @observable rwhMinutes = 0

  relations() {
    return {
      user: User,
      operator: Operator,
    }
  }
}

export class ExtraLeaveBalanceStore extends Store {
  Model = ExtraLeaveBalance
  static backendResourceName = 'extra_leave_balance'
}
