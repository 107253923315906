import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { WorkStation } from './WorkStation';


export class ExternalWorkStation extends Model {
  static backendResourceName = 'external_work_station'

  @observable id = null
  @observable name = ''

  relations() {
    return {
      workStation: WorkStation
    }
  }

}

export class ExternalWorkStationStore extends Store {
  Model = ExternalWorkStation
  static backendResourceName = 'external_work_station'
}
