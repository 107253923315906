import styled from 'styled-components';
const StepCount = styled.span`
  display: inline-block;
  min-width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.75rem;
  font-size: 0.9rem;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  text-align: center;
  line-height: 1.5rem;
  font-weight: bold;
  color: #fff;
  background-color: #212121;
  cursor: pointer;
`;
export default StepCount;