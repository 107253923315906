import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { NavisionIntegration } from 'store/NavisionIntegration'
import { NavisionCustomer } from 'store/NavisionCustomer'
import { SalesOrder } from 'store/SalesOrder'

export class NavisionProject extends Model {
  static backendResourceName = 'navision_project'
  static idIcon = 'navision'
  static idColor = 'violet'

  @observable id = null
  @observable orderId = ''
  @observable creationDate = null
  @observable startingDate = null
  @observable endingDate = null
  @observable description = null
  @observable deliveryAddress = null
  @observable deliveryCity = null
  @observable deliveryPostcode = null
  @observable orderId = null
  @observable status = null

  get _labelContent() {
    return this.orderId
  }

  relations() {
    return {
      integration: NavisionIntegration,
      salesOrder: SalesOrder,
      customer: NavisionCustomer,
    }
  }

  casts() {
    return {
      creationDate: Casts.date,
      startingDate: Casts.date,
      endingDate: Casts.date,
    }
  }
}

export class NavisionProjectStore extends Store {
  Model = NavisionProject
  static backendResourceName = 'navision_project'
}
