import React, { Component } from 'react';
import { Step } from '../../../../store/Step';
import { Grid, Container } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { t } from '../../../../i18n';
import StepTimePreview from './StepTimePreview';
import TargetTimeWithUnitInput from './TargetTimeWithUnitInput'

interface StepProcessTimingProps {
    step: Step;
    disabled: boolean;
}

const ProcessTimingExplanationText = styled(Container)`
    background-color: #F5F5F5;
    padding: 16px;

`;

const GridRowWithDoubleColumn = styled(Grid.Row)`
    // Don't change this.
  .fourtypercent.fourtypercent.fourtypercent.fourtypercent.fourtypercent.fourtypercent.fourtypercent.fourtypercent.fourtypercent.fourtypercent  {
    width:40% !important;
  }
`;

@observer
export default class StepProcessTiming extends Component<StepProcessTimingProps> {


    render() {
        const { step, disabled = false } = this.props;

        return (
            <Grid>
                <GridRowWithDoubleColumn columns={5}>
                    <Grid.Column>
                        <TargetTimeWithUnitInput
                            label={t('step.field.delayMinutes.label')}
                            target={step}
                            name={'delayInCustomTimeUnit'}
                            onChange={(value) => {
                                step.setInput('delayInCustomTimeUnit', value || 0)
                                step.setInput('delayMinutes', step.delayInCustomUnitAsMinutes)
                            }}
                            disabled={disabled}
                            onChangeUnit={(e, { value }) => {
                                step.setInput('delayTimeUnit', value)
                                step.setInput('delayMinutes', step.delayInCustomUnitAsMinutes)
                            }}
                            unitValue={!step.delayTimeUnit ? 'minutes' : step.delayTimeUnit}
                        />
                        <ProcessTimingExplanationText>
                            <p>
                                {t('step.field.delayMinutes.info')}
                            </p>
                            <p>
                                {t('step.field.delayMinutes.infoElaborate')}
                            </p>
                        </ProcessTimingExplanationText>
                    </Grid.Column>
                    <Grid.Column>
                        <TargetTimeWithUnitInput
                            label={t('step.field.setupMinutes.label')}
                            target={step}
                            name={'setupInCustomTimeUnit'}
                            onChange={(value) => {
                                step.setInput('setupInCustomTimeUnit', value || 0)
                                step.setInput('setupMinutes', step.setupInCustomUnitAsMinutes)
                            }}
                            disabled={disabled}
                            onChangeUnit={(e, { value }) => {
                                step.setInput('setupTimeUnit', value)
                                step.setInput('setupMinutes', step.setupInCustomUnitAsMinutes)
                            }}
                            unitValue={!step.setupTimeUnit ? 'minutes' : step.setupTimeUnit}
                        />
                        <ProcessTimingExplanationText>
                            <p>
                                {t('step.field.setupMinutes.info')}
                            </p>
                            <p>
                                {t('step.field.setupMinutes.infoElaborate')}
                            </p>
                        </ProcessTimingExplanationText>
                    </Grid.Column>
                    <Grid.Column>
                        <TargetTimeWithUnitInput
                            label={t('step.field.workMinutes.label')}
                            target={step}
                            name={'workInCustomTimeUnit'}
                            onChange={(value) => {
                                step.setInput('workInCustomTimeUnit', value || 0)
                                step.setInput('workMinutes', step.workInCustomUnitAsMinutes)
                            }}
                            disabled={disabled}
                            onChangeUnit={(e, { value }) => {
                                step.setInput('workTimeUnit', value)
                                step.setInput('workMinutes', step.workInCustomUnitAsMinutes)
                            }}
                            unitValue={!step.workTimeUnit ? 'minutes' : step.workTimeUnit}
                        />
                        <ProcessTimingExplanationText>
                            <p>
                                {t('step.field.workMinutes.info')}
                            </p>
                            <p>
                                {t('step.field.workMinutes.infoElaborate')}
                            </p>
                        </ProcessTimingExplanationText>
                    </Grid.Column>
                    <Grid.Column width={6} className="fourtypercent">
                        <StepTimePreview
                            step={step}
                        />
                    </Grid.Column>
                </GridRowWithDoubleColumn>
            </Grid>
        )
    }
}

