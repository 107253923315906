import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { OperatorCapabilityStore } from './OperatorCapability'

export class Capability extends Model {
  static backendResourceName = 'capability'

  @observable id = null
  @observable name = ''

  getUrl() {
    return `/personnel/capability/${this.id}/edit`
  }

  relations() {
    return {
      operatorCapabilities: OperatorCapabilityStore,
    }
  }
}

export class CapabilityStore extends Store {
  Model = Capability
  static backendResourceName = 'capability'
}
