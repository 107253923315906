import { observable } from 'mobx'
import { Model, Store } from 'store/Base'

export class ExactGlobeAccount extends Model {
  static backendResourceName = 'exact_globe_account'

  @observable id = null
  @observable uuid = null
  @observable code = ''
  @observable name = ''

  @observable deliveryPostCode = ''
  @observable deliveryCity = ''
  @observable deliveryCountry = ''
}

export class ExactGlobeAccountStore extends Store {
  static backendResourceName = 'exact_globe_account'
  Model = ExactGlobeAccount
}
