import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { LoadCarrier } from './LoadCarrier'
import { User } from './User'

export class LoadCarrierWeighing extends Model {
  static backendResourceName = 'load_carrier_weighing'

  @observable id = null
  @observable weight = 0
  @observable weightUnit = 'kg'
  @observable weighedAt = null

  relations() {
    return {
      loadCarrier: LoadCarrier,
      weighedBy: User,
    }
  }

  casts() {
    return {
      weighedAt: Casts.datetime,
    }
  }
}

export class LoadCarrierWeighingStore extends Store {
  Model = LoadCarrierWeighing
  static backendResourceName = 'load_carrier_weighing'
}
