import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { SalesOrderLine } from '../SalesOrderLine'
import { ExactGlobeSalesOrder } from './SalesOrder'

export class ExactGlobeSalesOrderLine extends Model {
  static backendResourceName = 'exact_globe_sales_order_line'

  @observable id = null
  @observable lineNumber = 1;

  relations() {
    return {
      header: ExactGlobeSalesOrder,
      salesOrderLine: SalesOrderLine,
    }
  }

  casts() {
    return {
    }
  }
}

export class ExactGlobeSalesOrderLineStore extends Store {
  static backendResourceName = 'exact_globe_sales_order_line'
  Model = ExactGlobeSalesOrderLine
}
