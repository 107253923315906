import styled from 'styled-components';
import { getStepColor, STEP_TRANSITION } from './StepMarkup';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import { Icon } from '@code-yellow/spider';


const StepLabelDiv = styled.div`
  z-index: 1;
  position: absolute;
  left: 1rem;
  width: 6rem;
  color: ${getStepColor};
  font-weight: bold;
  line-height: 1em;
  ${({ isNew, selected, top }) => {
      if (isNew) {
        return `
            transform: scale(0) translate(${-2 * (top ? -1 : 1)}rem);
        `;
      }
      if (selected) {
        return `
          transform: scale(1.1) translate(0, ${0.5 * (top ? -1 : 1)}rem);
        `
      }
      return '';
  }}
  transition: ${['transform', 'color'].map((property) => `${property} ${STEP_TRANSITION}`).join(', ')};
  i.icon {
    cursor: pointer;
    margin: 0 0 0 0.25rem;
    font-size: 0.85em;
    position: relative;
    top: -0.075em;
  }
  text-overflow: ellipsis;
  ${({ top, multiplier }) =>
  top
    ? `
        bottom: ${multiplier ? 4.75 : 5.25}rem;
    `
    : `
        top: ${multiplier ? 4.75 : 5.25}rem;
    `}
`;

const ICON_WIDTH = '10px';

const StepLabelText = styled.div`
  float: left;
  max-width:  calc(100% - ${ICON_WIDTH});
`;

const StepLabelIcon = styled.div`
  float: left;
  width: ${ICON_WIDTH};
`;

@observer
class StepLabel extends Component {
  static propTypes = {
    icon: PropTypes.instanceOf(Icon),
    children: PropTypes.node,
  }

  render() {
    const { children, icon } = this.props;

    if (!icon) {
      return (
        <StepLabelDiv data-test-step-label>
          {children}
        </StepLabelDiv>
      )
    }

    return (
      <StepLabelDiv>
        <StepLabelText data-test-step-label>{children}</StepLabelText>
        <StepLabelIcon>{icon}</StepLabelIcon>
      </StepLabelDiv>
    )
  }
}

export { StepLabelDiv as OldStepLabel }
export default StepLabel;
