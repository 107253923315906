import { observable } from 'mobx'
import { Model, Store } from 'store/Base'

export class ExactAddress extends Model {
  static backendResourceName = 'exact_address'

  @observable id = null
  @observable uuid = null
  @observable main = false
  @observable addressLine1 = ''
  @observable addressLine2 = ''
  @observable addressLine3 = ''
  @observable mailbox = ''
  @observable phone = ''
  @observable phoneExtension = ''
  @observable city = ''
  @observable postcode = ''
  @observable country = ''
  @observable countryName = ''
  @observable stateDescription = ''
}

export class ExactAddressStore extends Store {
  static backendResourceName = 'exact_address'
  Model = ExactAddress
}
