import React from 'react'
import { computed, reaction } from 'mobx'
import { AdminOverview as BaseAdminOverview } from '@code-yellow/spider';
import Toolbar from 'component/Toolbar'
import styled from 'styled-components'
import { Metafilters } from 'component/Metafields'
import { Icon } from 'semantic-ui-react';
import { t } from 'i18n';

export const EmptyMessageContainer = styled.p`
  text-align: center;
  margin-top: 2rem;
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.5);
`

function getPrefix(prefix) {
  if (prefix === '') {
    return ''
  } else {
    return `${prefix} - `
  }
}


const DeleteIconContainer = styled.div`
    margin-top: 52px;
    margin-bottom: -52px;
    margin-right: 0px;
    margin-left: 268px;
    cursor: pointer;
`;

const DeleteIconHackContainer = styled.div`

  .daycy {
    margin-right: 35px !important;
  }
  margin-bottom: 10px;
`;

export default class AdminOverview extends BaseAdminOverview {
  Toolbar = Toolbar

  // So we do this with a reaction instead of a computed value
  // because the base admin overview already has tabTitlePrefix as an
  // attribute and thus we sadly cannot override it with a getter
  tabTitlePrefix = getPrefix(window.viewStore.tabTitlePrefix)

  fullWidth = true

  // if enabled, title = 'this.title (count)'
  // else, title = 'this.title'
  showStoreCountAtTitle = true

  componentDidMount() {
    super.componentDidMount()
    this.tabTitlePrefixReaction = reaction(
      () => window.viewStore.tabTitlePrefix,
      (tabTitlePrefix) => {
        this.tabTitlePrefix = getPrefix(tabTitlePrefix)
        this.forceUpdate()
      }
    )
  }

  componentWillUnmount() {
    super.componentWillUnmount()
    this.tabTitlePrefixReaction()
  }

  emptyMessage = null
  emptyMessageSearch = null

  itemButtonProps = {
    primary: false,
  }

  @computed get empty() {
    return (
      !this.store.isLoading &&
      (this.store.totalPages === 0 ||
        (this.store.totalPages === 1 && this.store.currentPage === 1 && this.store.length === 0))
    )
  }

  // shows 'Title (store count)' or just 'Title', and renders titleRight if defined
  renderTitle() {
    if (!this.title) {
      return <></>
    } else if (this.showStoreCountAtTitle && this.store) {
      return <this.HeaderRight as="h1" content={this.renderTitleLeft()} store={this.store}>
        {this.renderTitleRight()}
      </this.HeaderRight>
    } else {
      return <this.HeaderRight as="h1" content={this.renderTitleLeft()}>
        {this.renderTitleRight()}
      </this.HeaderRight>
    }
  }

  renderTitleLeft() {
    return this.title
  }

  renderOverviewTable() {
    return this.empty && this.emptyMessage ? (
      <EmptyMessageContainer>{this.emptyMessage}</EmptyMessageContainer>
    ) : (
      super.renderOverviewTable()
    )
  }

  renderPaginationControls() {
    return this.empty && this.emptyMessage ? null : super.renderPaginationControls()
  }

  renderFilter(filter, i) {
    // scope none does not need to filter on day/week/month
    if (window.viewStore.progressScope === 'none' && filter.type === 'progress') {
      return
    }
    if (typeof filter === 'function') {
      filter = filter();
    }
    if (filter.type === 'progress') {
      filter.type = {
        month: 'month',
        week: 'week',
        day: 'date',
      }[window.viewStore.progressScope];
    }
    if (filter.type === 'metafields') {
      const withDivider = filter.withDivider
      filter = {
        type: 'custom',
        callback: () => (
          <Metafilters
            store={this.store}
            fetch={this.fetch.bind(this)}
            debouncedFetch={this.debouncedFetch}
            withDivider={withDivider}
          />
        ),
      }
    }

    // T45629: Disable enter to prevent other filters acting weird...
    filter.onKeyPress = e => {
      if (e.key === 'Enter') {
        e.preventDefault()
        e.stopPropagation()
      }
    }

    const res =  super.renderFilter(filter, i)
    if (['month', 'week', 'date'].includes(filter.type)) {
      return (
        <>
          <DeleteIconHackContainer>
            <DeleteIconContainer onClick={() => {

              delete this.store.params[filter.name]
              this.fetch()
            }
            } >
              <Icon name="delete" />
            </DeleteIconContainer>
            {res}
          </DeleteIconHackContainer>


        </>
      )
    }

    return res;
  }

  removeItem(item) {
    if (item?.__store?.Model?.backendResourceName) {
      // overwrite this part from spider, so we can fit it in "deleteConfirmation: 'Are you sure you want to delete {{name}} ?',"
      if (window.confirm(t('form.deleteConfirmation', { name: `${item?.__store?.Model?.backendResourceName}(${item?._id})` }))) {
          return item.delete();
      } else {
          return Promise.reject();
      }
    } else {
      return super.removeItem(item);
    }
}
}

export { PaginationControls, Sidebar, SidebarToggle, StyledTableRow, TableHeader } from '@code-yellow/spider';
