import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { Operator } from './Operator'
import { Capability } from './Capability'

export class OperatorCapability extends Model {
  static backendResourceName = 'operator_capability'

  @observable id = null
  @observable level = 3

  relations() {
    return {
      operator: Operator,
      capability: Capability,
    }
  }
}

export class OperatorCapabilityStore extends Store {
  Model = OperatorCapability
  static backendResourceName = 'operator_capability'
}
