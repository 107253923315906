import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { NavisionIntegration } from 'store/NavisionIntegration'
import { SalesOrder } from 'store/SalesOrder'
import { NavisionSalesOrderLineStore } from 'store/NavisionSalesOrderLine'

export class NavisionSalesOrder extends Model {
  static backendResourceName = 'navision_sales_order'
  static idIcon = 'navision'
  static idColor = 'violet'

  @observable id = null
  @observable documentType = null
  @observable orderId = ''
  @observable shipToName = ''
  @observable deliveryAddress = ''
  @observable deliveryAddress2 = ''
  @observable deliveryCity = ''
  @observable deliveryPostcode = ''
  @observable deliveryCountryRegionCode = ''
  @observable sellToAddress = ''
  @observable sellToAddress2 = ''
  @observable sellToCity = ''
  @observable sellToPostcode = ''
  @observable orderDate = null
  @observable shipmentMethodCode = ''
  @observable dueDate = null
  @observable salespersonCode = ''
  @observable documentDate = null
  @observable externalDocumentNumber = ''
  @observable shippingAgentCode = ''
  @observable packageTrackingNumber = ''
  @observable status = ''
  @observable requestedDeliveryDate = null
  @observable promisedDeliveryDate = null
  @observable lastDatetimeModified = null

  get _labelContent() {
    return this.orderId
  }

  relations() {
    return {
      integration: NavisionIntegration,
      salesOrder: SalesOrder,
      lines: NavisionSalesOrderLineStore,
    }
  }

  casts() {
    return {
      orderDate: Casts.date,
      dueDate: Casts.date,
      documentDate: Casts.date,
      requestedDeliveryDate: Casts.date,
      promisedDeliveryDate: Casts.date,
      lastDatetimeModified: Casts.datetime,
    }
  }
}

export class NavisionSalesOrderStore extends Store {
  Model = NavisionSalesOrder
  static backendResourceName = 'navision_sales_order'
}
