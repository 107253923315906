import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'semantic-ui-react'
import styled from 'styled-components'

function CloseIcon(props) {
  return <Icon {...props} className={props.className ? `close ${props.className}` : 'close'} />
}

CloseIcon.propTypes = {
  className: PropTypes.string
};

export default styled(CloseIcon)`
  right: ${({ index = 1 }) => index * 2.5 + 1}rem !important;
  ${({ disabled }) =>
    disabled
      ? `
        cursor: unset !important;
    `
      : ''}
`
