import { observable } from 'mobx';
import { Model, Store } from 'store/Base';

export class Change extends Model {
    @observable field = '';
    @observable diff = null;
    @observable model = '';
    @observable oid = null;
    @observable before = '';
    @observable after = '';
}

export class ChangeStore extends Store {
    Model = Change;
}