import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { User } from './User'
import { ArticleType } from './ArticleType'

export class UserArticleTypePermissionScope extends Model {
    static backendResourceName = 'user_article_type_permission_scope'

    @observable id = null
    @observable scope = ''

    relations() {
        return {
            user: User,
            articleType: ArticleType,
        }
    }
}

export class UserArticleTypePermissionScopeStore extends Store {
    Model = UserArticleTypePermissionScope
    static backendResourceName = 'user_article_type_permission_scope'
}
