import { observable, computed } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { User } from './User';
import { result, omit, groupBy } from 'lodash';
import { ChangeStore } from './Change';

export function storeWithHistory(Store) {
    return class extends Store {
        Model = modelWithHistory((new Store()).Model);
    };
}

export function modelWithHistory(Model) {
    return class extends Model {
        // TODO: move to relations, and also let mobx-spine ignore relations
        // starting with `_` when fetching.
        @observable _history = new HistoryStore({ relations: ['user', 'changes'] });

        fetchHistory() {
            this._history.url = this.url + 'history/';
            return this._history.fetch();
        }

        toJS() {
            // Make sure history is converted as well.
            return {
                ...super.toJS(),
                _history: this._history.toJS(),
            };
        }
    };
}

export class History extends Model {
    @observable id = null;
    @observable source = '';
    @observable uuid = '';
    @observable date = '';

    relations() {
        return {
            user: User,
            changes: ChangeStore,
        };
    }

    casts() {
        return {
            date: Casts.datetime,
        };
    }

    // To make it more consisent with other models.
    @computed get createdAt() {
        return this.date;
    }
}

export class HistoryStore extends Store {
    Model = History;

    // Mobx-spine requires a meta attribute in the response, lets fix it here
    // for now.
    fetch(options = {}) {
        const api = this.api;

        api.fetchStoreWithoutMeta = function ({ url, data, requestOptions }) {
            return api.get(url, data, requestOptions).then(res => {
                return {
                    response: res,
                    data: res.data,
                    repos: res.with,
                    relMapping: { user: 'user' },
                    reverseRelMapping: res.with_related_name_mapping,
                    totalRecords: 0,
                };
            });
        }

        const data = this.buildFetchData(options);
        const promise = this.wrapPendingRequestCount(
            api
                .fetchStoreWithoutMeta({
                    url: options.url || result(this, 'url'),
                    data,
                    requestOptions: omit(options, 'data'),
                })
                .then(res => {
                    this.__state.totalRecords = res.totalRecords;
                    this.fromBackend(res);

                    return res.response;
                })
        );

        return promise;
    }

    /**
     * Group the history by date.
     */
    groupedByDate() {
        const groupedByDate = groupBy(this.models, elem => elem.date?.format('dd-MM-yyyy HH:mm'))

        return Object.values(groupedByDate).map((currentGroup) => {
            const mergedGroup = new History(currentGroup[0].toJS(), { relations: ['user', 'changes'] });

            // Merge all changes
            mergedGroup.changes.models = currentGroup.reduce((acc, currentHistory) => {
                acc.push(...currentHistory.changes.models)
                return acc;
            }, [])

            return mergedGroup;
        })
    }
}
