import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ArticleType } from './ArticleType'
import { BillOfMaterialItemStore } from './BillOfMaterialItem'

export class BillOfMaterialVersion extends Model {
  static backendResourceName = 'bill_of_material_version';

  @observable id = null;
  @observable active = false;
  @observable default = false;
  @observable version = 0;
  @observable description = '';
  @observable status = '';
  @observable calculatedCostPrice = 0;
  @observable defaultBatchQuantity = 0;
  @observable type = '';

  relations() {
    return {
      articleType: ArticleType,
      items: BillOfMaterialItemStore,
    }
  }
}

export class BillOfMaterialVersionStore extends Store {
  Model = BillOfMaterialVersion
  getMaxVersion = () => {
    if (this.models.length === 0) {
      return null
    }
    return this.models.reduce((model1, model2) => {
      return model1.version > model2.version ? model1 : model2
    })
  }

  static backendResourceName = 'bill_of_material_version'
}
