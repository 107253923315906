import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { computed } from 'mobx'
import { observer } from 'mobx-react'
import { ProductionRequest } from 'store/ProductionRequest'
import Progress from './Progress'

@observer
export default class LiveProgress extends Component {
  static propTypes = {
    productionRequest: PropTypes.instanceOf(ProductionRequest).isRequired,
  }

  @computed get quantityDone() {
    const { productionRequest } = this.props
    return productionRequest.quantityDone
  }

  @computed get workMinutesDone() {
    const { productionRequest } = this.props
    return productionRequest.workMinutesDone
  }

  @computed get workMinutesTotal() {
    const { productionRequest } = this.props
    return productionRequest.workMinutesTotal
  }

  @computed get progress() {
    if (this.workMinutesTotal === 0) {
      return 0
    }
    return this.workMinutesDone / this.workMinutesTotal
  }

  render() {
    const { productionRequest, ...props } = this.props
    let res = (
      <Progress
        quantity={productionRequest.quantity}
        quantityDone={this.quantityDone}
        progress={this.progress}
        {...props}
      />
    )

    return res
  }
}
