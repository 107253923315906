import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { ExactGoodsReceiptLineStore } from 'store/ExactGoodsReceiptLine'

export class ExactGoodsReceipt extends Model {
  static backendResourceName = 'exact_goods_receipt'

  @observable id = null
  @observable uuid = null
  @observable description = ''
  @observable remarks = ''
  @observable quantity = 0
  @observable created = null
  @observable modified = null
  @observable receiptDate = null
  @observable receiptNumber = null
  @observable reference = ''

  relations() {
    return {
      lines: ExactGoodsReceiptLineStore,
    }
  }

  casts() {
    return {
      created: Casts.datetime,
      modified: Casts.datetime,
      receiptDate: Casts.date,
    }
  }
}

export class ExactGoodsReceiptStore extends Store {
  Model = ExactGoodsReceipt
  static backendResourceName = 'exact_goods_receipt'
}
