import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ItemAssortmentValueStore } from './ItemAssortmentValue'


export class ItemAssortment extends Model {
  static backendResourceName = 'item_assortment'

  @observable id = null
  @observable code = ''
  @observable description = ''

  relations() {
    return {
      values: ItemAssortmentValueStore,
    }
  }

}

export class ItemAssortmentStore extends Store {
  Model = ItemAssortment
  static backendResourceName = 'item_assortment'
}
