import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { User } from './User'
import { Operator } from './Operator'
import { WorkSlotStore } from './WorkSlot'
import { WorkEmployer } from './WorkEmployer'

export class WorkSchedule extends Model {
  static backendResourceName = 'work_schedule'
  static omitFields = ['endDate']

  @observable id = null
  @observable startDate = null
  @observable endDate = null
  @observable allowedLegalLeaveMinutes = null
  @observable allowedExtraLeaveMinutes = null
  @observable allowedRwhMinutes = null

  relations() {
    return {
      user: User,
      operator: Operator,
      workSlots: WorkSlotStore,
      employer: WorkEmployer,
    }
  }

  casts() {
    return {
      startDate: Casts.date,
      endDate: Casts.date,
    }
  }
}

export class WorkScheduleStore extends Store {
  Model = WorkSchedule
  static backendResourceName = 'work_schedule'
}
