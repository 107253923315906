import React, { Component } from 'react';
import { ScaleTime } from 'd3-scale';
import { observer } from 'mobx-react';
import { Moment } from 'moment';

interface FinishBarProps {
    scaleX: ScaleTime<number, number>;

    date: Moment;

    rowHeight: number;
}

const WIDTH = 20;
const HEIGHT = 5;

@observer
export class FinishBar extends Component<FinishBarProps> {

    get coordinates() {
        const { rowHeight, scaleX, date } = this.props

        const xStart = scaleX(date);
        const xMiddle = xStart + WIDTH / 2;

        function* xGen(): Iterator<number> {
            while (true) {
                yield xStart;
                yield xMiddle;
            }
        }


        const coordinates: [number, number][] = [];

        let currentY = 0;

        const x = xGen();

        while (currentY < rowHeight) {
            coordinates.push([x.next().value, currentY]);
            currentY += HEIGHT;
        }

        return coordinates
    }

    render() {
        const coordinates = this.coordinates
        return (
            <g>
                {coordinates.map(coordinate => {
                    return (
                        <rect
                            x={coordinate[0]}
                            y={coordinate[1]}
                            width={WIDTH / 2}
                            height={HEIGHT}
                            fill={'#E0E0E0'}
                        />
                    )
                })}
            </g>
        )
    }

}
