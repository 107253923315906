import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observable, computed, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { Input, Modal, Icon } from 'semantic-ui-react'
import { SUI } from 'semantic-ui-react/dist/es/lib'
import { camelToSnake } from 'helpers'
import styled from 'styled-components'
import { api } from 'store/Base'

const CATEGORIES = [
  'accessibility',
  'arrows',
  'audioVideo',
  'business',
  'chess',
  'code',
  'communication',
  'computers',
  'currency',
  'dateTime',
  'design',
  'editors',
  'files',
  'genders',
  'handsGestures',
  'health',
  'images',
  'interfaces',
  'logistics',
  'maps',
  'medical',
  'objects',
  'paymentsShopping',
  'shapes',
  'sports',
  'status',
  'usersPeople',
  'vehicles',
  'writing',
]

const CategoryHeader = styled.h3`
  padding-bottom: 0.25rem;
  border-bottom: 2px solid rgba(34, 36, 38, 0.15);
`

const CategoryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const CategoryItem = styled.div`
  width: calc(100% / 6);
  padding: 1rem;
  text-align: center;

  i.icon {
    cursor: pointer;
  }
`

const FilterContent = styled(Modal.Content)`
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
`

@observer
class IconButton extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
  }

  constructor(...args) {
    super(...args)
    this.onSelect = this.onSelect.bind(this)
  }

  onSelect() {
    const { name, onSelect } = this.props
    onSelect(name)
  }

  render() {
    const { name } = this.props
    return (
      <CategoryItem>
        <Icon name={name} size="big" onClick={this.onSelect} />
        <div>{name}</div>
      </CategoryItem>
    )
  }
}

@observer
class IconCategory extends Component {
  static propTypes = {
    category: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    filter: PropTypes.string.isRequired,
  }

  constructor(...args) {
    super(...args)
    this.renderIcon = this.renderIcon.bind(this)
  }

  @computed get icons() {
    const { category, filter } = this.props
    let icons = SUI[camelToSnake(category).toUpperCase()]
    if (filter !== '') {
      icons = icons.filter((name) => name.toLowerCase().includes(filter))
    }
    return icons
  }

  renderIcon(name) {
    const { onSelect } = this.props
    return <IconButton key={name} name={name} onSelect={onSelect} />
  }

  render() {
    const { category } = this.props
    return (
      this.icons.length > 0 && (
        <React.Fragment>
          <CategoryHeader>{t(`iconModal.category.${category}`)}</CategoryHeader>
          <CategoryContainer>{this.icons.map(this.renderIcon)}</CategoryContainer>
        </React.Fragment>
      )
    )
  }
}

@observer
export default class IconModal extends Component {
  static propTypes = {
    trigger: PropTypes.node.isRequired,
    onSelect: PropTypes.func.isRequired,
  }

  @observable open = false
  @observable filter = ''
  @observable favorites = null

  constructor(...args) {
    super(...args)
    this.onOpen = this.onOpen.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onSelect = this.onSelect.bind(this)
    this.renderCategory = this.renderCategory.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.renderFavorite = this.renderFavorite.bind(this)
  }

  async onOpen() {
    const favorites = await api.get('step/favorite_icons/')
    runInAction(() => {
      this.open = true
      this.favorites = favorites
    })
  }

  onClose() {
    this.open = false
    this.favorites = null
  }

  onSelect(icon) {
    const { onSelect } = this.props
    this.onClose()
    onSelect(icon)
  }

  renderCategory(category) {
    return <IconCategory key={category} category={category} onSelect={this.onSelect} filter={this.filter} />
  }

  handleFilterChange(e, { value }) {
    this.filter = value
  }

  renderFavorite({ icon }) {
    return <IconButton key={icon} name={icon} onSelect={this.onSelect} />
  }

  render() {
    const { trigger } = this.props

    return (
      <Modal
        size="large"
        trigger={trigger}
        open={this.open}
        onOpen={this.onOpen}
        onClose={this.onClose}
        closeIcon
        closeOnDimmerClick={false}
      >
        <Modal.Header>{t('iconModal.header')}</Modal.Header>
        <FilterContent>
          <Input
            fluid
            size="large"
            value={this.filter}
            onChange={this.handleFilterChange}
            placeholder={t('iconModal.filterPlaceholder')}
          />
        </FilterContent>
        <Modal.Content scrolling>
          {this.favorites !== null && this.favorites.length > 0 && (
            <>
              <CategoryHeader>{t('iconModal.category.favorites')}</CategoryHeader>
              <CategoryContainer>{this.favorites.map(this.renderFavorite)}</CategoryContainer>
            </>
          )}
          {CATEGORIES.map(this.renderCategory)}
        </Modal.Content>
      </Modal>
    )
  }
}
