import { Model, Store } from 'mobx-spine';
import { observable, computed } from 'mobx';


/** Counts received via ws from backend. */
export class Counts extends Model {

  static primaryKey = 'syncer';

  @observable syncer = '';

  @observable requested = 0;
  @observable pending = 0;

  @observable created = 0;
  @observable updated = 0;
  @observable from_db = 0;

  @observable from_inline = 0; // partially synced, possibly need an extra update

  @observable deps = {};

  @computed get total_done() {
    return this.from_db + this.created + this.updated;
  }

}


export class CountsStore extends Store {
  Model = Counts
}
