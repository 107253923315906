import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { NavisionIntegration } from 'store/NavisionIntegration'
import { NavisionSalesOrder } from 'store/NavisionSalesOrder'
import { SalesOrderLine } from 'store/SalesOrderLine'

export class NavisionSalesOrderLine extends Model {
  static backendResourceName = 'navision_sales_order_line'

  @observable id = null
  @observable lineNumber = ''
  @observable type = ''
  @observable locationCode = ''
  @observable unitOfMeasure = ''
  @observable outstandingQuantity = 0
  @observable quantity = 0
  @observable quantityToShip = 0
  @observable quantityShipped = 0
  @observable binCode = ''
  @observable unitOfMeasureCode = ''
  @observable requestedDeliveryDate = null
  @observable promisedDeliveryDate = null
  @observable plannedDeliveryDate = null
  @observable reservedQuantity = 0

  relations() {
    return {
      integration: NavisionIntegration,
      navisionSalesOrder: NavisionSalesOrder,
      salesOrderLine: SalesOrderLine,
    }
  }

  casts() {
    return {
      requestedDeliveryDate: Casts.date,
      promisedDeliveryDate: Casts.date,
      plannedDeliveryDate: Casts.date,
    }
  }
}

export class NavisionSalesOrderLineStore extends Store {
  Model = NavisionSalesOrderLine
  static backendResourceName = 'navision_sales_order_line'
}
