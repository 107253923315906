import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { NavisionIntegration } from 'store/NavisionIntegration'
import { NavisionProject } from 'store/NavisionProject'

export class NavisionProjectLine extends Model {
  static backendResourceName = 'navision_project_line'

  @observable id = null
  @observable lineNumber = ''
  @observable quantity = 0
  @observable promisedDeliveryDate = null
  @observable plannedDeliveryDate = null

  relations() {
    return {
      integration: NavisionIntegration,
      navisionProject: NavisionProject,
    }
  }

  casts() {
    return {
      promisedDeliveryDate: Casts.date,
      plannedDeliveryDate: Casts.date,
    }
  }
}

export class NavisionProjectLineStore extends Store {
  Model = NavisionProjectLine
  static backendResourceName = 'navision_project_line'
}
