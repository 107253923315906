import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Icon, Menu } from 'semantic-ui-react';
import { Scrollbars } from 'react-custom-scrollbars';
import { PaginationControls } from '@code-yellow/spider';
import { Document, Page, pdfjs }  from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DocumentContainer = styled.div`
    position: relative;
    background-color: #EEE;
    height: 100%;
`;

const DocumentWrapper = styled.div`
    height: 100%;
    > div {
        > div {
            margin: 2.5rem 0;
            canvas {
                box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.25);
                margin: 0 auto;
            }
        }
        margin-bottom: 4rem;
    }
`;

const PaginationBar = styled.div`
    height: 4rem;
    line-height: 4rem;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
`;

const DownloadMenu = styled(Menu)`
    position: absolute;
    top: 2rem;
    right: 2rem;
    transform: translate(50%, -50%);
`;


const PdfMessage = styled.div`
    text-align: center;
    font-size: 120%;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.6);
`;

@observer
export default class Pdf extends Component {
    static propTypes = {
        url: PropTypes.string.isRequired,
        downloadName: PropTypes.string,
    };

    static defaultProps = {
        downloadName: 'document.pdf',
    };

    @observable currentPage = 1;
    @observable totalPages = 0;

    getContainer() {
        return ReactDOM.findDOMNode(this).childNodes[0].childNodes[0];
    }

    getPages(container) {
        if (container === undefined) {
            container = this.getContainer();
        }
        return container.childNodes[0].childNodes[0].childNodes;
    }

    setPage = (page) => {
        if (page < 1 || page > this.totalPages) {
            return;
        }

        const pages = this.getPages();
        pages[page - 1].scrollIntoView({ behavior: 'smooth', block: 'start' });
        return Promise.resolve();
    };

    setCurrentPage = () => {
        const container = this.getContainer();
        const pages = this.getPages(container);

        const target = container.scrollTop + container.offsetHeight / 2;
        for (let i = 0; i < pages.length; i++) {
            if (pages[i].offsetTop + pages[i].offsetHeight >= target) {
                this.currentPage = i + 1;
                break;
            }
        }
    };

    render() {
        const { url, downloadName } = this.props;
        const pages = [];

        for (let i = 1; i <= this.totalPages; i++) {
            pages.push(<Page key={i} pageNumber={i} />);
        }

        return (
            <DocumentContainer>
                <Scrollbars onScroll={this.setCurrentPage}>
                    <DocumentWrapper>
                        <Document
                            file={url}
                            options={{ disableWorker: false }}
                            onLoadSuccess={({ numPages }) => this.totalPages = numPages}
                            loading={<PdfMessage>{t('form.pdfLoading')} <Icon loading name="spinner" /></PdfMessage>}
                            error={<PdfMessage>{t('form.pdfError')}</PdfMessage>}
                        >
                            {pages}
                        </Document>
                    </DocumentWrapper>
                </Scrollbars>
                <PaginationBar>
                    <PaginationControls store={{
                        currentPage: this.currentPage,
                        totalPages: this.totalPages,
                        hasPreviousPage: this.currentPage > 1,
                        getPreviousPage: () => this.setPage(this.currentPage - 1),
                        hasNextPage: this.currentPage < this.totalPages,
                        getNextPage: () => this.setPage(this.currentPage + 1),
                        setPage: this.setPage,
                    }} />
                    <DownloadMenu pagination size="mini">
                        <Menu.Item icon as="a" download={downloadName} href={url}>
                            <Icon name="download" />
                        </Menu.Item>
                    </DownloadMenu>
                </PaginationBar>
            </DocumentContainer>
        );
    }
}
