import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ArticleType } from './ArticleType'
import { ExactGoodsDeliveryLineStore } from './ExactGoodsDeliveryLine'
import { ExactGoodsReceiptLineStore } from './ExactGoodsReceiptLine'
import { ExactPurchaseOrderLineStore } from './ExactPurchaseOrderLine'
import { ExactSalesOrderLineStore } from './ExactSalesOrderLine'
import { ExactItemAssortmentProperty } from './Exact/ItemAssortmentProperty'

export class ExactItem extends Model {
  static backendResourceName = 'exact_item'

  @observable id = null
  @observable code = ''
  @observable name = ''
  @observable classification = ''

  @observable isMakeItem = false
  @observable isPurchaseItem = false
  @observable isSalesItem = false
  @observable isStockItem = false

  // Annotations
  @observable hasArticleType = false
  @observable hasComponentType = false

  getUrl() {
    return `/assets/item/${this.id}/edit`
  }

  static getClassRelations() {
    return [
      'classOne', 'classTwo', 'classThree', 'classFour',
      'classFive', 'classSix', 'classSeven', 'classEight',
      'classNine', 'classTen',
    ]
  }

  getClasses() {
    return [
      this.classOne, this.classTwo, this.classThree, this.classFour,
      this.classFive, this.classSix, this.classSeven, this.classEight,
      this.classNine, this.classTen,
    ]
  }

  getNotNullClassProperties() {
    let properties = []
    // eslint-disable-next-line
    for (let value of this.getClasses()) {
      if (value && !value.isNew) {
        properties.push(value)
      }
    }
    return properties
  }

  relations() {
    return {
      articleType: ArticleType,
      goodsDeliveryLines: ExactGoodsDeliveryLineStore,
      goodsReceiptLines: ExactGoodsReceiptLineStore,
      purchaseOrderLines: ExactPurchaseOrderLineStore,
      salesOrderLines: ExactSalesOrderLineStore,
      classOne: ExactItemAssortmentProperty,
      classTwo: ExactItemAssortmentProperty,
      classThree: ExactItemAssortmentProperty,
      classFour: ExactItemAssortmentProperty,
      classFive: ExactItemAssortmentProperty,
      classSix: ExactItemAssortmentProperty,
      classSeven: ExactItemAssortmentProperty,
      classEight: ExactItemAssortmentProperty,
      classNine: ExactItemAssortmentProperty,
      classTen: ExactItemAssortmentProperty,
    }
  }

  toBackend(...args) {
    const res = super.toBackend(...args)

    delete res.has_article_type
    delete res.has_component_type

    return res
  }
}

export class ExactItemStore extends Store {
  Model = ExactItem
  static backendResourceName = 'exact_item'
}
