import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ExactIntegration } from './ExactIntegration'
import { ExternalWorkStation } from './ExternalWorkStation'

export class ExactWorkcenter extends Model {
  static backendResourceName = 'exact_workcenter'

  @observable id = null
  @observable code = ''
  @observable description = ''

  relations() {
    return {
      integration: ExactIntegration,
      externalWorkStation: ExternalWorkStation,
    }
  }
}

export class ExactWorkcenterStore extends Store {
  Model = ExactWorkcenter
  static backendResourceName = 'exact_workcenter'
}
