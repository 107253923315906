import { observable, computed } from 'mobx'
import { Model, Store } from 'store/Base'
import { ExactGlobeProductionOrder } from './ProductionOrder'
import { ExactGlobePurchaseOrderLineStore } from './PurchaseOrderLine'

export class ExactGlobePurchaseOrder extends Model {
  static backendResourceName = 'exact_globe_purchase_order_header'
  static idColor = 'red'
  static idIcon = 'exact'

  getLink(props = {}) {
    return super.getLink({
      target: '_blank',
      ...props,
    })
  }

  @observable id = null
  @observable purchaseOrderNumber = ''
  @observable description = ''

  @computed
  get _labelContent() {
    return this.purchaseOrderNumber
  }

  relations() {
    return {
      lines: ExactGlobePurchaseOrderLineStore,
      exactGlobeProductionOrder: ExactGlobeProductionOrder,
    }
  }
}

export class ExactGlobePurchaseOrderStore extends Store {
  static backendResourceName = 'exact_globe_purchase_order_header'
  Model = ExactGlobePurchaseOrder
}
