import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ExactPurchaseReturn } from 'store/ExactPurchaseReturn'
import { ExactGoodsReceiptLine } from 'store/ExactGoodsReceiptLine'

export class ExactPurchaseReturnLine extends Model {
  static backendResourceName = 'exact_purchase_return_line'

  @observable id = null
  @observable uuid = null
  @observable lineNumber = null
  @observable notes = null
  @observable returnQuantity = null

  relations() {
    return {
      exactPurchaseReturn: ExactPurchaseReturn,
      exactGoodsReceiptLine: ExactGoodsReceiptLine,
    }
  }
}

export class ExactPurchaseReturnLineStore extends Store {
  Model = ExactPurchaseReturnLine
  static backendResourceName = 'exact_purchase_return_line'
}
