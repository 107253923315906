import { lighten } from 'polished'

function getStepColor({ hasError, color, selected }) {
  if (hasError) {
    color = '#DB2828'
  }
  if (selected) {
    color = lighten(0.15, color)
  }
  return color
}

const STEP_TRANSITION = '300ms ease'


export { getStepColor, STEP_TRANSITION };
