import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';
import { InfoIcon } from '@code-yellow/spider';

export default class InfoPopup extends Component {
    static propTypes = {
        content: PropTypes.string.required,
        popupProps: PropTypes.object,
    }

    render() {
        const { content, popupProps } = this.props

        return <Popup {...popupProps} data-test-info-popup
            trigger={<InfoIcon name="info circle" />}
            content={content}
        />
    }
}
