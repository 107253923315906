import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import { ProductionRequest } from 'store/ProductionRequest';
import PlannedWeekTag from './PlannedWeekTag';
import styled from 'styled-components';
import { formatDuration, SHORT_DATETIME_FORMAT } from '../../helpers';
import { Moment } from 'moment';
import { t } from '../../i18n';
import { Caption1 } from '../PerformanceStyles';
interface PlannedOnInformationProps {
  productionRequest: ProductionRequest;
}
const PlannedOnInformationContainer = styled.div`
    width: 240px;
`;
const RightAlignTextBox = styled.span`
    right:14px;
    position: absolute;
`;
export default class PlannedOnInformation extends Component<PlannedOnInformationProps> {
  render() {
    const {
      productionRequest
    } = this.props;

    // @ts-ignore
    const actualStartedAt: Moment | null = productionRequest.startedAt;
    const plannedStartedAt: Moment | null = productionRequest.startAt;
    const erpPlannedEndDate = productionRequest.erpPlannedEndDate;
    return <PlannedOnInformationContainer data-sentry-element="PlannedOnInformationContainer" data-sentry-component="PlannedOnInformation" data-sentry-source-file="PlannedOnInformation.tsx">
                <Grid centered columns={2} data-sentry-element="Grid" data-sentry-source-file="PlannedOnInformation.tsx">
                    <Grid.Column width={5} data-sentry-element="unknown" data-sentry-source-file="PlannedOnInformation.tsx">
                        <PlannedWeekTag productionRequest={productionRequest} data-sentry-element="PlannedWeekTag" data-sentry-source-file="PlannedOnInformation.tsx" />
                    </Grid.Column>
                    <Grid.Column width={10} data-sentry-element="unknown" data-sentry-source-file="PlannedOnInformation.tsx">
                                <Caption1 data-sentry-element="Caption1" data-sentry-source-file="PlannedOnInformation.tsx">
                                {t('progress.plannedOnInformation.start')}:
                                <RightAlignTextBox data-test-start-at data-sentry-element="RightAlignTextBox" data-sentry-source-file="PlannedOnInformation.tsx">
                                    {
              // @ts-ignore
              plannedStartedAt?.format(SHORT_DATETIME_FORMAT) || '-'}
                                </RightAlignTextBox>
                                </Caption1>
                                <Caption1 data-sentry-element="Caption1" data-sentry-source-file="PlannedOnInformation.tsx">
                                {t('progress.plannedOnInformation.actual')}:
                                <RightAlignTextBox data-sentry-element="RightAlignTextBox" data-sentry-source-file="PlannedOnInformation.tsx">
                                    {
              // @ts-ignore
              actualStartedAt?.format(SHORT_DATETIME_FORMAT) || t('productionRequest.notStartedYet')}
                                </RightAlignTextBox>
                                </Caption1>
                                <Caption1 data-sentry-element="Caption1" data-sentry-source-file="PlannedOnInformation.tsx">
                                {t('productionRequest.erpEnd')}:
                                <RightAlignTextBox data-sentry-element="RightAlignTextBox" data-sentry-source-file="PlannedOnInformation.tsx">
                                    {
              // @ts-ignore
              erpPlannedEndDate?.format(SHORT_DATETIME_FORMAT) || '-'}
                                </RightAlignTextBox>
                                </Caption1>
                                <Caption1 data-sentry-element="Caption1" data-sentry-source-file="PlannedOnInformation.tsx">
                                {t('progress.plannedOnInformation.duration')}:
                                <RightAlignTextBox data-test-total-process-time data-sentry-element="RightAlignTextBox" data-sentry-source-file="PlannedOnInformation.tsx">
                                    {formatDuration(productionRequest.totalProcessTime, {
                unit: 'minute'
              })}
                                </RightAlignTextBox>
                                </Caption1>
                                {productionRequest.erpDeliveryDate && <Caption1>
                                {t('progress.plannedOnInformation.delivery')}:
                                <RightAlignTextBox data-test-delivery-date-erp>
                                    {productionRequest.erpDeliveryDate?.format('DD-MM-YYYY') || '-'}
                                </RightAlignTextBox>
                                </Caption1>}
                    </Grid.Column>
                </Grid>
            </PlannedOnInformationContainer>;
  }
}