import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { Detail } from './Detail'
import { BillOfMaterialItem } from './BillOfMaterialItem'
import { Step } from './Step'
import { ProductionRequest } from './ProductionRequest'

export class DetailMaterialTask extends Model {
  static backendResourceName = 'detail_material_task'

  @observable id = null
  @observable value = null
  @observable batches = []
  @observable quantityFinished = null

  relations() {
    return {
      detail: Detail,
      billOfMaterialItem: BillOfMaterialItem,
      step: Step,
      productionRequest: ProductionRequest,
    }
  }
}

export class DetailMaterialTaskStore extends Store {
  Model = DetailMaterialTask
  static backendResourceName = 'detail_material_task'
}
