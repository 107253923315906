import { observable } from 'mobx'
import { Model, Store } from 'store/Base'

export class Unit4PurchaseOrder extends Model {
  static backendResourceName = 'unit4_purchase_order'
  static idColor = 'grey'
  static idIcon = 'unit4'

  @observable id = null
  @observable orderId = null

  get _labelContent() {
    return this.orderId
  }
}

export class Unit4PurchaseOrderStore extends Store {
  static backendResourceName = 'unit4_purchase_order'
  Model = Unit4PurchaseOrder
}
