import { Model } from '../../../../store/Base';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Grid, Label, Segment, Table } from 'semantic-ui-react';
import { t } from '../../../../i18n';
import { Step, TIME_UNITS_ABBREVIATIONS } from '../../../../store/Step';
import TargetNumberInputWithChildren from '../../../../component/Target/TargetNumberInputWithChildren';
import styled from 'styled-components';
import StepTimeCircle from './StepTimeCircle';
import Decimal from 'decimal.js';

export const COLOR_RUN = '#81C784';
export const COLOR_SETUP = '#C2A0FB';
export const COLOR_WAIT = '#FFA726';

const OverlayLabel = styled(Label)`
    margin-left: -33px !important;
`;

const OrderSizeExplanationText = styled.p`
    color: #757575;
    margin-left: 14px;

`;

interface StepTimePreviewProps {
    step: Step;
}

class StepTimePreviewModel extends Model {
    @observable orderSize = 1;
}

interface LegendDotProps {
    size: number;
    color: string;

}

const LegendDots = styled.div<LegendDotProps>`
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    background-color: ${props => props.color};
    border-radius: 50%;
`;


@observer
export default class StepTimePreview extends Component<StepTimePreviewProps> {

    target = new StepTimePreviewModel();

    render() {
        const { step } = this.props;

        const waitMinutes = step.delayMinutes ? new Decimal(step.delayMinutes) : new Decimal(0);
        const setupMinutes = step.setupMinutes ? new Decimal(step.setupMinutes) : new Decimal(0);
        const multipliedMinutes = step.workMinutes ? new Decimal(step.workMinutes) : new Decimal(5)
        const runMinutes = multipliedMinutes.mul(this.target.orderSize || 0);

        const waitInCustomTimeUnit = step.delayInCustomTimeUnit;
        const setupInCustomTimeUnit = step.setupInCustomTimeUnit;
        const runInCustomTimeUnit = (step.workInCustomTimeUnit || 0) * this.target.orderSize;

        const numberInputContentProps = {
            labelPosition: 'left',
            children: [
                <input/>,
                <OverlayLabel basic>#</OverlayLabel>
            ]
        };

        return (
            <Segment>
                <TargetNumberInputWithChildren
                    target={this.target}
                    label={t('step.field.orderSize.label')}
                    name="orderSize"
                    contentProps={numberInputContentProps}
                />
                <OrderSizeExplanationText>
                    {t('step.field.orderSize.explanation')}
                </OrderSizeExplanationText>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={6}>
                            <StepTimeCircle

                                waitMinutes={waitMinutes}
                                setupMinutes={setupMinutes}
                                runMinutes={runMinutes}
                            />
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Table basic="very">
                                <Table.Row>
                                    <Table.Cell>
                                        <LegendDots size={13} color={COLOR_WAIT}/>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {t('step.field.delayMinutes.label')}
                                    </Table.Cell>
                                    <Table.Cell data-test-delay-summary>
                                        {waitInCustomTimeUnit} {TIME_UNITS_ABBREVIATIONS[step.delayTimeUnit]}
                                    </Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>
                                        <LegendDots size={13} color={COLOR_SETUP}/>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {t('step.field.setupMinutes.label')}
                                    </Table.Cell>
                                    <Table.Cell data-test-setup-summary>
                                        {setupInCustomTimeUnit} {TIME_UNITS_ABBREVIATIONS[step.setupTimeUnit]}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        <LegendDots size={13} color={COLOR_RUN}/>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {t('step.field.workMinutes.label')}
                                    </Table.Cell>
                                    <Table.Cell data-test-work-summary>
                                        {runInCustomTimeUnit} {TIME_UNITS_ABBREVIATIONS[step.workTimeUnit]}
                                    </Table.Cell>
                                </Table.Row>
                            </Table>
                        </Grid.Column>

                    </Grid.Row>
                </Grid>

            </Segment>
        )
    }
}
