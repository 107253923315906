import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { WorkScheduleStore } from './WorkSchedule'

export class WorkEmployer extends Model {
  static backendResourceName = 'work_employer'

  @observable id = null
  @observable name = ''

  relations() {
    return {
      workSchedules: WorkScheduleStore,
    }
  }
}

export class WorkEmployerStore extends Store {
  Model = WorkEmployer
  static backendResourceName = 'work_employer'
}
