import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { Batch } from 'store/Batch'
import { ExactItem } from 'store/ExactItem'
import { ExactSalesOrderLine } from 'store/ExactSalesOrderLine'
import { ExactGoodsDelivery } from 'store/ExactGoodsDelivery'
import { ExactSalesReturnLineStore } from 'store/ExactSalesReturnLine'

export class ExactGoodsDeliveryLine extends Model {
  static backendResourceName = 'exact_goods_delivery_line'

  @observable id = null
  @observable uuid = null
  @observable notes = ''
  @observable quantityOrdered = null
  @observable quantityDelivered = null
  @observable lineNumber = 0
  @observable trackingNumber = ''
  @observable created = null
  @observable modified = null

  relations() {
    return {
      batch: Batch,
      exactItem: ExactItem,
      exactSalesOrderLine: ExactSalesOrderLine,
      exactGoodsDelivery: ExactGoodsDelivery,
      exactSalesReturnLines: ExactSalesReturnLineStore,
    }
  }

  casts() {
    return {
      created: Casts.datetime,
      modified: Casts.datetime,
    }
  }
}

export class ExactGoodsDeliveryLineStore extends Store {
  Model = ExactGoodsDeliveryLine
  static backendResourceName = 'exact_goods_delivery_line'
}
