import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Label, Icon } from 'semantic-ui-react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { theme } from 'styles'

const semanticUiRed = theme.semanticUiRed
const semanticUiGreen = theme.semanticUiGreen
const semanticUiRedHover = theme.semanticUiRedHover
const semanticUiGreenHover = theme.semanticUiGreenHover

const StyledLabel= styled(Label)`
    cursor: pointer;
    white-space: nowrap;
`

const StyledIcon = styled(Icon)`
    vertical-align: bottom;
`

// When link is not clicked, be red, when link is clicked, be green
const StyledLink = styled(Link)`
  &:link {
    color: white !important;
    background-color: ${semanticUiRed} !important;
    :hover {
      background-color: ${semanticUiRedHover} !important;
    }
  }
  &:visited {
    color: white !important;
    background-color: ${semanticUiGreen} !important;
    :hover {
      background-color: ${semanticUiGreenHover} !important;
    }
  }
`

type LinkLabelProps = {
    id: number;
    to: string;
    icon: Icon;
    content: Component;
}

@observer
export class LinkLabel extends Component<LinkLabelProps> {

  render() {
    const { id, to, icon, content } = this.props;

      return (
          <StyledLabel data-test-tag-linked-exact-sales-order={id}
            as={StyledLink}
            to={to}
            style={{ textDecoration: 'none', margin: '0' }}
            target={'_blank'}
          >
            <StyledIcon name={icon} />
            {content}
          </StyledLabel>
      )
  }
}
