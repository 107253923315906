import { observer } from 'mobx-react'
import { TargetNumberInput } from '@code-yellow/spider'
import Decimal from 'decimal.js'
import { humanReadable } from 'helpers/decimal'


@observer
export default class TargetDecimalInput extends TargetNumberInput {
  static defaultProps = {
    ...TargetNumberInput.defaultProps,
    allowDecimal: true,
  };

  fromModel(value) {
    return humanReadable(value.toString())
  }

  toModel(value) {
    // By default go with a 0, which is not 100% correct but does allow all
    // other calculations to assume that model value is a Decimal.
    return Decimal(value === '' ? 0 : value)
  }

  fromTarget(value) {
    const { decimalSymbol } = this.props;

    value = humanReadable(value.toString())
    return value.replace('.', decimalSymbol);
  }

  toTarget(value) {
    value = this.normalizeValue(value);
    return Decimal(value === '' ? 0 : value)
  }
}
