import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Image, Modal } from 'semantic-ui-react'
import { ProductionRequest } from 'store/ProductionRequest'
import { NestRequest } from 'store/Workstation/NestRequest'


export class ProductionRequestDrawingImage extends Component {
  static propTypes = {
    productionRequest: PropTypes.instanceOf(ProductionRequest).isRequired,
    size: PropTypes.string,
  }

  static defaultProps = {
    size: 'tiny'
  }

  render(){
  return <GenericDrawingImage productionRequest={this.props.productionRequest} size={this.props.size}/>
  }
}

export class NestRequestDrawingImage extends Component {
  static propTypes = {
    nestRequest: PropTypes.instanceOf(NestRequest).isRequired,
    size: PropTypes.string,
  }

  static defaultProps = {
    size: 'tiny'
  }

  render(){
  return <GenericDrawingImage nestRequest={this.props.nestRequest} size={this.props.size}/>
  }
}

class GenericDrawingImage extends Component{
  static propTypes = {
    productionRequest: PropTypes.instanceOf(ProductionRequest),
    nestRequest: PropTypes.instanceOf(NestRequest),
    size: PropTypes.string,
  }

  static defaultProps = {
    size: 'tiny'
  }

  render() {
    const { productionRequest, nestRequest, size } = this.props



    if (!productionRequest && !nestRequest) {
      return null
    }

    if(productionRequest && nestRequest){
      throw Error('You have provided both a productionRequest and a nestRequest. Only one should be provided')
    }

    let targetItem = !!productionRequest ? productionRequest : nestRequest


    if(!targetItem.drawingImage){
      return null
    }

    return (
      <Modal
        closeOnDimmerClick={false}
        closeIcon
        trigger={
          <Image
            data-test-sub-batch-bitmap={targetItem.id}
            data-test-image-imported={Boolean(targetItem.drawingImage)}
            size={size}
            src={targetItem.drawingImage}
            style={{ cursor:'pointer' }}
          />
        }
      >
        <Modal.Content image>
          <Image
            data-test-modal-image
            data-test-image-imported={Boolean(targetItem.drawingImage)}
            size='huge'
            src={targetItem.drawingImage}
          />
        </Modal.Content>
      </Modal>
    )
  }
}