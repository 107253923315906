import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { Step } from './Step'
import { Metafield } from './Metafield'
import { action } from 'mobx'

export class Section extends Model {
  static backendResourceName = 'section'

  @observable id = null
  @observable important = false
  @observable ordering = 0

  relations() {
    return {
      step: Step,
      parts: PartStore,
    }
  }
}

export class SectionStore extends Store {
  Model = Section
  static backendResourceName = 'section'
}

export class Part extends Model {
  static backendResourceName = 'part'

  static TYPES = ['text', 'table', 'image', 'meta']

  @observable id = null
  @observable type = null
  @observable ordering = 0

  relations() {
    return {
      textPart: TextPart,
      tablePart: TablePart,
      imagePart: ImagePart,
      metaPart: MetaPart,
    }
  }

  toBackend(...args) {
    const res = super.toBackend(...args)
    delete res.type

    // eslint-disable-next-line
    for (const type of Part.TYPES) {
      if (type !== this.type) {
        res[`${type}_part`] = null
      }
    }
    return res
  }

  @action toBackendAll(...args) {
    const activeCurrentRelations = this.__activeCurrentRelations

    const filterRelations = Part.TYPES.filter((type) => type !== this.type).map((type) => `${type}Part`)

    this.__activeCurrentRelations = activeCurrentRelations.filter((rel) => !filterRelations.includes(rel))

    try {
      return super.toBackendAll(...args)
    } finally {
      this.__activeCurrentRelations = activeCurrentRelations
    }
  }
}

export class PartStore extends Store {
  Model = Part
  static backendResourceName = 'part'
}

export class TextPart extends Model {
  static backendResourceName = 'text_part'

  @observable id = null
  @observable text = ''
}

export class TablePart extends Model {
  static backendResourceName = 'table_part'

  @observable id = null
  @observable table = [
    ['A', 'B', 'C'],
    ['A1', 'B1', 'C1'],
    ['A2', 'B2', 'C2'],
    ['A3', 'B3', 'C3'],
  ]
}

export class ImagePart extends Model {
  static backendResourceName = 'image_part'

  @observable id = null
  @observable image = null
  @observable width = 25
  @observable subtitle = ''
}

export class MetaPart extends Model {
  static backendResourceName = 'meta_part'

  @observable id = null

  relations() {
    return {
      metafield: Metafield,
    }
  }
}
