import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { Operator } from './Operator'
import { WorkStation } from './WorkStation'

export class OperatorAssignment extends Model {
  static backendResourceName = 'operator_assignment'

  @observable id = null
  @observable startAt = null
  @observable endAt = null

  relations() {
    return {
      operator: Operator,
      workStation: WorkStation,
    }
  }

  casts() {
    return {
      startAt: Casts.tzDatetime,
      endAt: Casts.tzDatetime,
    }
  }
}

export class OperatorAssignmentStore extends Store {
  Model = OperatorAssignment
  static backendResourceName = 'operator_assignment'
}
