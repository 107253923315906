import React, { Component } from 'react'
import { computed, observable } from 'mobx'
import { observer } from 'mobx-react'
import { Popup } from 'semantic-ui-react'
import styled from 'styled-components'
import { humanReadable, toNumber } from 'helpers/decimal'
import { t } from '../../i18n';
import Decimal from 'decimal.js';
import { COLORS } from '@code-yellow/spider';
const extraColor = '#30e080'

interface ProgressContainerProps {
    // Height of the whole progress bar, and the corresponding unit
    height: number;
    heightUnit: string;

    /**
     *     Various percentages in the progress bar, with percentages between [0, 1]
     *
     *     100%  ~ #Planned to be done + #Extra done
     *
     *     DonePercentage => Percentage of products which is finished and planned
     *     progressPercentage => Percentage of products which is unfinished and planned
     *     extrePercentage => Percentage of products which were unplanned
     *
     *     Note that "DonePercentage + progressPercentage + extraPercentage = 1"
     *
     *     E.g. If we plan to produce 12 products, and we produced 16 products:
     *
     *     donePercentage => (12/16 = 0.75)
     *     progressPercentage => (0/16 = 0)
     *     extraPercentage => ((16-12)/16 = 0.25)
     *
     *     E.g. If we plan 12 products, and only 7 are finiehed
     *
     *     donePercentage => (7/12 = 0.58333)
     *     progressPercentage => (5/12 = 0.41667)
     *     extraPercentage => (0/12 = 0)
     */
    donePercentage: number;
    progressPercentage: number;
    extraPercentage: number;
}

/**
 * Progress bar contain an indication of how many of the units we have already competed
 */
const ProgressContainer = styled.div<ProgressContainerProps>`
  position: relative;
  display: inline-block;
  height: ${({ height, heightUnit }) => `${height}${heightUnit}`};
  width: ${({ height, heightUnit }) => `${(10 / 1.5) * height}${heightUnit}`};
  border-radius: 0.25em;
  background: ${({ donePercentage, progressPercentage, extraPercentage }) => `linear-gradient(
    to right,
    ${COLORS.teal} 0%, ${COLORS.teal} ${donePercentage * 100}%,
    ${COLORS.yellow} ${donePercentage * 100}%, ${COLORS.yellow} ${progressPercentage * 100}%,
    ${extraColor} ${progressPercentage * 100}%, ${extraColor} ${(progressPercentage + extraPercentage) * 100}%,
    #BDBDBD ${(progressPercentage + extraPercentage) * 100}%, #BDBDBD 100%
  )`};
`

interface ProgressOverlayInterface {
    offset: number;
    progress: number;
}

const ProgressOverlay = styled.div<ProgressOverlayInterface>`
  position: absolute;
  left: ${({ offset }) => offset * 100}%;
  top: 0;
  width: ${({ progress }) => progress * 100}%;
  height: 100%;
`

const QuantityOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  color: white;
  font-size: 14px;
`

const ProgressTotal = styled.span`
  font-size: 1.25rem;
  font-weight: bold;
  margin-right: 0.5rem;
  min-width: 2.2em;
  text-align: right;
`

const FullProgressContainer = styled.span`
  display: inline-flex;
  align-items: center;
`

const ProgressPopup = styled(Popup)`
  width: ${({ height, heightUnit }) => `${(10 / 1.5) * height}${heightUnit}`};
  height: ${({ extra, height, heightUnit }) => `${(extra ? 2.5 : 2) * height}${heightUnit}`};
  font-size: ${({ height, heightUnit }) => `${0.6 * height}${heightUnit}`} !important;
  text-align: center;
  padding: 0.45rem 0 0;
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0.3rem;
    background: ${({ progress, progressColor, extra }) => `linear-gradient(to right, ${progressColor} 0%, ${progressColor} ${progress * 100}%, ${extra ? extraColor : 'transparent'} ${progress * 100}%, ${extra ? extraColor : 'transparent'} 100%)`};
  }
`;


/**
 * Type of overlays to show on hovering
 */
type ProgressHoverOptions = 'progress' | 'quantityDone';

interface ProgressProps {
    quantity: number | Decimal;
    quantityDone: number | Decimal;
    progress: number | Decimal;
    withTotal: boolean ;
    height: number;
    heightUnit: string ;
    withTotalOverlay: boolean;
}

@observer
export default class Progress extends Component<ProgressProps> {
  static defaultProps = {
    withTotal: false,
    withTotalOverlay: false,
    height: 1.5,
    heightUnit: 'rem',
  }



  @computed get quantityDone(): number {
    const { quantityDone, quantity } = this.props
    return Math.min(toNumber(quantityDone), toNumber(quantity))
  }

  @computed get quantityExtra(): number {
    const { quantityDone } = this.props
    return toNumber(quantityDone) - this.quantityDone
  }

  @computed get done(): number {
    const { quantity } = this.props
    return this.quantityDone / (toNumber(quantity) + this.quantityExtra)
  }

  @computed get progress(): number {
    const { progress, quantity } = this.props
    return (Math.min(toNumber(progress), 1) * toNumber(quantity)) / (toNumber(quantity) + this.quantityExtra)
  }

  @computed get extra(): number {
    const { quantity } = this.props
    return this.quantityExtra / (toNumber(quantity) + toNumber(this.quantityExtra))
  }

  @observable hover: null | ProgressHoverOptions = null

  render() {
    const { quantity, progress, withTotal, height, heightUnit, withTotalOverlay } = this.props

    let progressPercentage;
    if (this.hover === 'quantityDone') {
      progressPercentage = this.done;
    } else {
      progressPercentage = this.hover === 'progress'
        ? this.progress
        : 0;
    }

    let progressColor;
    if (this.hover === 'quantityDone') {
      progressColor = '#00b5ad';
    } else {
      progressColor = this.hover === 'progress'
        ? '#fbbd08'
        : 'transparent';
    }


    let res = (
      <ProgressPopup
        progress={progressPercentage}
        progressColor={progressColor}
        extra={this.extra !== 0}
        position="top center"
        height={height}
        heightUnit={heightUnit}
        open={this.hover !== null}
        trigger={
          <ProgressContainer donePercentage={this.done} progressPercentage={this.progress} extraPercentage={this.extra} height={height} heightUnit={heightUnit}>
            <ProgressOverlay data-test-progress-done
              offset={0}
              progress={this.done}
              onMouseEnter={() => this.hover = 'quantityDone'}
              onMouseLeave={() => this.hover = null}
            />
            <ProgressOverlay data-test-progress-progress
              offset={this.done}
              progress={this.progress - this.done}
              onMouseEnter={() => this.hover = 'progress'}
              onMouseLeave={() => this.hover = null}
            />
            <ProgressOverlay data-test-progress-extra
              offset={this.done}
              progress={this.extra}
              onMouseEnter={() => this.hover = 'quantityDone'}
              onMouseLeave={() => this.hover = null}
            />
            {withTotalOverlay && <QuantityOverlay>{humanReadable(`${quantity}`)}</QuantityOverlay>}
          </ProgressContainer>
        }
      >
        {this.hover === 'quantityDone' && (
          <>
            {t('productionRequest.overview.done', { count: this.quantityDone })}
            {this.quantityExtra !== 0 && <><br /><span style={{ color: extraColor, fontWeight: 'bold' }}>{t('productionRequest.overview.extra', { count: this.quantityExtra })}</span></>}
          </>
        )}
        {this.hover === 'progress' && t('productionRequest.overview.progress', { percentage: `${Math.round(toNumber(progress) * 100)}%` })}
      </ProgressPopup>
    )
    if (withTotal) {
      res = (
        <FullProgressContainer>
          <ProgressTotal data-test-progress-total>{humanReadable(`${quantity}`)}</ProgressTotal>
          {res}
        </FullProgressContainer>
      )
    }
    return res
  }
}
