import { observable, computed } from 'mobx'
import { Model, Store } from 'store/Base'
import { ProductionRequest } from './ProductionRequest'
import { InShipmentLineStore } from './InShipmentLine'
import { PurchaseOrder, PurchaseOrderStore } from './PurchaseOrder'

export class InShipment extends Model {
  static backendResourceName = 'in_shipment'
  static idPrefix = 'RE'
  static idColor = 'blue'
  static idIcon = 'move_to_inbox_white'

  getUrl() {
    if (!this.purchaseOrder.isNew) {
      return `/warehouse/inbound/order/${this.purchaseOrder.id}/shipment/${this.id}?.id=${this.purchaseOrder.id}`;
    }

    return '';
  }

  getMiniTag() {
    return window.viewStore.tracyTagSize === 'mini'
  }

  @observable id = null

  @computed
  get purchaseOrder() {
    return this.purchaseOrders?.length > 0 ? this.purchaseOrders.at(0) : new PurchaseOrder()
  }

  relations() {
    return {
      lines: InShipmentLineStore,
      productionRequest: ProductionRequest,
      purchaseOrders: PurchaseOrderStore,
    }
  }

  toBackendAll(...args) {
    const data = super.toBackendAll(...args)

    // eslint-disable-next-line
    for (const in_shipment_line of data.relations.in_shipment_line || []) {
      if (in_shipment_line.id >= 0) {
        continue
      }
      const production_request = data.relations.production_request.find(({ id }) => id === in_shipment_line.production_request)
      production_request.superrequest = data.data[0].production_request
    }
    return data
  }
}

export class InShipmentStore extends Store {
  static backendResourceName = 'in_shipment'

  Model = InShipment

  totalQuantityForPurchaseOrderLine(purchaseOrderLine, purchaseOrder) {
    purchaseOrder = purchaseOrder ?? purchaseOrderLine.purchaseOrder
    let totalQuantity = this.models.reduce(
      (result, inShipment) =>
        result +
        inShipment.lines
          .filter((l) => purchaseOrder.lines.get(l.purchaseOrderLine.id).articleType.id === purchaseOrderLine.articleType.id)
          .reduce((result2, line) => result2 + Math.max(line.productionRequest.quantity, line.productionRequest.quantityDone), 0),
      0
    )

    // eslint-disable-next-line
    for (const line of purchaseOrder.lines.models) {
      if (line.articleType.id !== purchaseOrderLine.articleType.id) {
        continue
      }
      const lineQuantity = Math.min(totalQuantity, line.quantity)
      if (line.id === purchaseOrderLine.id) {
        return lineQuantity
      }
      totalQuantity -= lineQuantity
    }
  }

  availableQuantityForPurchaseOrderLine(purchaseOrderLine, purchaseOrder) {
    const added = this.totalQuantityForPurchaseOrderLine(purchaseOrderLine, purchaseOrder)

    return purchaseOrderLine.quantity - added
  }
}
