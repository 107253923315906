/**
 * Checks if a materialPlanItem belongs to this workstation.
 * Returns true only if the item is assigned to this workstation and false otherwise.
 *
 * @param workStation
 * @param materialPlanItem
 */
export const isMyWorkstation = (workStationCode, materialPlanItem) => {
  if (materialPlanItem.externalWorkStation.isNew) {
    return false;
  }

  if (materialPlanItem.externalWorkStation.workStation.isNew) {
    return false;
  }

  return materialPlanItem.externalWorkStation.workStation.code === workStationCode;
}

/**
 * Checks if an materialPlanItem doesn't have a workstation or the workstation is not known to Tracy.
 * In this case, this item should be shown under a dropdown since it won't show up anywhere else.
 *
 * @param materialPlanItem
 */
export const isUnknownWorkstation = (materialPlanItem) => {
  if (materialPlanItem.externalWorkStation.isNew) {
    return true;
  }

  if (materialPlanItem.externalWorkStation.workStation.isNew) {
    return true;
  }

  return false;
}
