import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { action, observable, computed } from 'mobx'
import { observer } from 'mobx-react'
import { Button, Form, Modal, Label } from 'semantic-ui-react'
import styled from 'styled-components'
import { RightDivider, TargetTextInput } from '@code-yellow/spider'
import { theme } from 'styles'
import Decimal from 'decimal.js'
import { modalColors } from 'styles'

// helpers
import { getViewStore } from 'helpers/viewStore'
// end helpers

// stores
import { Step } from 'store/Step'
import { BatchStore } from 'store/Batch'
import { api } from 'store/Base'
// end stores

const Options = styled.div`
  margin-top: 1rem;
  > code {
    color: ${theme.primaryColor};
  }
`

@observer
export default class BatchModal extends Component {
  static propTypes = {
    onSelect: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    requirements: PropTypes.arrayOf(
      PropTypes.shape({
        step: PropTypes.instanceOf(Step).isRequired,
        quantity: PropTypes.number.isRequired,
        multiplier: PropTypes.number,
      }).isRequired
    ).isRequired,
    batchSize: PropTypes.number,
    split: PropTypes.bool,
    batches: PropTypes.instanceOf(BatchStore).isRequired,
  }

  static defaultProps = {
    batchSize: 1,
    split: false,
  }

  constructor(...args) {
    super(...args)

    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.renderOption = this.renderOption.bind(this)
  }

  @observable value = ''
  @observable error = null
  @observable batches = [{}]


  @computed get loadCarriers() {
    const { batches } = this.props

    if (batches.models.every((b) => !b.loadCarrier.isNew)) {
      return batches.map((batch) => batch.loadCarrier)
    }

    return []
  }

  onChange(value) {
    this.value = value
  }

  @action async onSubmit() {
    const { requirements, batches, onSelect, split } = this.props

    // Find batch with serial number
    let batch = batches.find((b) => {
      // Direct match.
      if (b.serialNumber === this.value) {
        return true
      }

      // Identifying through another batch.
      return b.performances.models.some((performance) => (
        performance.details.models.some((detail) => (
          detail.components.models.some((batch) => (
            batch.batchUsings.models.some((batchUsing) => (
              // TODO: add proper decimal support, breaks production/operating/article.spec.js.
              batchUsing.usedBatch.serialNumber === this.value && Decimal(batchUsing.quantity).equals(Decimal(batchUsing.usedBatch.quantity))
            ))
          ))
        ))
      ))
    })

    // Fallback to load carrier if no batch was found
    if (!batch) {
      const res = await api.get('load_carrier/', { '.serial_number': this.value })
      if (res.data.length === 1 && res.data[0].batch !== null) {
        batch = batches.find((b) => b.id === res.data[0].batch)
      }
    }
    // While batch is a subassembly take the parent batch
    while (batch && batch.quantityRemaining.eq(0)) {
      // eslint-disable-next-line no-loop-func
      batch = batches.find((b) =>
        b.batchUsings.models.some(
          (
            usage // eslint-disable-next-line no-loop-func
          ) => usage.usedBatch.id === batch.id && Decimal(usage.quantity).equals(Decimal(batch.quantity))
        )
      )
    }

    this.error = null
    this.value = ''

    if (!batch) {
      this.error = t('workStation.production.batchModal.error.notFound')
      return
    }

    if (requirements === null && batch !== undefined) {
      onSelect({ batches: { [batch.id]: batch.quantity } })
      return
    }

    const requirement = requirements[this.batches.length - 1]
    const requirementBatches = this.batches[this.batches.length - 1]

    if (batch.lastStep.id !== requirement.step.id) {
      this.error = t('workStation.production.batchModal.error.wrongLastStep')
    } else if (this.batches.some((requirementBatches) => requirementBatches[batch.id])) {
      this.error = t('workStation.production.batchModal.error.alreadyScanned')
    } else {
      let done

      if (split) {
        const quantityTodo = requirement.quantity - Object.values(requirementBatches).reduce((a, b) => a + b, 0)
        requirementBatches[batch.id] = Math.min(batch.quantityRemaining, quantityTodo)
        done = batch.quantityRemaining >= quantityTodo
      } else {
        requirementBatches[batch.id] = batch.quantityRemaining
        done = Object.keys(requirementBatches).length === requirement.multiplier
      }

      if (done) {
        if (this.batches.length === requirements.length) {
          const batches = {}
          // eslint-disable-next-line
          for (const requirementBatches of this.batches) {
            // eslint-disable-next-line
            for (const [target, quantity] of Object.entries(requirementBatches)) {
              batches[target] = quantity
            }
          }
          onSelect({ batches })
        } else {
          this.batches.push({})
        }
      }
    }
  }

  renderOptions = (options) => {
    if (!getViewStore().showSerialNumberSuggestion) {
      return
    }

    if (getViewStore().showLoadCarrierSugesstion && this.loadCarriers.length > 0) {
      options = this.loadCarriers
    }

    return (
      <Options>{options.map(this.renderOption)}</Options>
    )
  }

  renderOption(batch, i) {
    return (
      <React.Fragment key={`batch_${batch.cid}`}>
        <Label as='a' color='gray' style={{ marginTop: '3px', color: 'black' }} onClick={() => {
          this.onChange(batch.serialNumber)
          this.onSubmit()
        }}>
          <h3 data-test-batch-serial-number >{batch.serialNumber}</h3>
        </Label>
      </React.Fragment>
    )
  }

  render() {
    const { requirements, split, batchSize, batches, onClose } = this.props

    let requirement
    let requirementBatches
    let options

    if (requirements === null) {
      options = batches.models
    } else {
      requirement = requirements[this.batches.length - 1]
      requirementBatches = this.batches[this.batches.length - 1]
      options = batches.filter(
        (batch) =>
          !batch.quantityRemaining.equals(0) &&
          batch.scrapReason === null &&
          batch.lastStep.id === requirement.step.id &&
          !requirementBatches[batch.id]
      )
    }

    let label = ''
    if (!requirement) {
      label = t('batch.field.serialNumber.label')
    } else {
      label = requirement.step.label
      if (split) {
        label += ` (${requirement.quantity - Object.values(requirementBatches).reduce((a, b) => a + b, 0)})`
      } else if (requirement.quantity > batchSize) {
        label += ` (${Object.values(requirementBatches).reduce((a, b) => a + b, 0) / batchSize + 1}/${requirement.quantity / batchSize})`
      }
    }

    return (
      <Modal open closeIcon data-test-batch-modal onClose={onClose} size="tiny" closeOnDimmerClick={false}>
        <Modal.Header style={{ backgroundColor:modalColors.orange }}>
          {t(`workStation.production.batchModal.${this.loadCarriers.length > 0 ? 'loadCarriers' : 'title'}`)}
        </Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.onSubmit}>
            <TargetTextInput
              autoFocus
              label={label}
              name="serialNumber"
              value={this.value}
              onChange={this.onChange}
              errors={this.error === null ? [] : [this.error]}
            />
          </Form>
          {this.renderOptions(options)}
        </Modal.Content>
        <Modal.Actions>
          <RightDivider />
          <Button
            primary
            data-test-confirm-button
            icon="check"
            labelPosition="left"
            content={t('workStation.production.batchModal.confirmButton')}
            onClick={this.onSubmit}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}
