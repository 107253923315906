import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { FormField } from './FormField';
import { Metafield } from './Metafield';

export class ScanConstraint extends Model {
  static backendResourceName = 'scan_constraint';

  @observable id = null
  @observable operator = 'eq'
  @observable rightValue = null

  relations() {
    return {
      formField: FormField,
      leftMetafield: Metafield,
      rightMetafield: Metafield,
    }
  }
}

export class ScanConstraintStore extends Store {
  Model = ScanConstraint
  static backendResourceName = 'scan_constraint'
}
