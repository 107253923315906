import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { ScrollModal } from '@code-yellow/spider';
import Pdf from 'component/Pdf';

@observer
export default class PdfModal extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        trigger: PropTypes.node.isRequired,
    };

    @observable isOpen = false;
    open = () => this.isOpen = true;
    close = () => this.isOpen = false;

    render() {
        const { title, url, trigger, ...props } = this.props;

        return (
            <ScrollModal closeIcon
                centered={false}
                open={this.isOpen}
                onOpen={this.open}
                onClose={this.close}
                trigger={trigger}
                {...props}
            >
                <ScrollModal.Header>{title}</ScrollModal.Header>
                <ScrollModal.Content noScrollbars noPadding>
                    {this.isOpen && <Pdf url={url} />}
                </ScrollModal.Content>
            </ScrollModal>
        );
    }
}
