import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Modal, Form } from 'semantic-ui-react';
import { Batch } from 'store/Batch';
import { BatchTypeStore } from 'store/BatchType';
import { WarehouseStore } from 'store/Warehouse';
import { TargetTextInput,  TargetSelect,  SaveButton,  TargetDatePicker,  CancelButton,  RightDivider,  ToolbarButton } from '@code-yellow/spider';
import { clearNotifications, showSaveNotification } from 'helpers/notification';
import TargetDecimalInput from 'component/Target/TargetDecimalInput';
import { t } from 'i18n';


/**
 * A very quick & dirty modal to create batches manually by abusing stock count process. This is used for getting
 * customers live and registering stock that is already in the warehouse.
 *
 * Initially created for getting Meatless live, and used again for getting hyCLEANER live.
 */
@observer
export default class BatchCreateModal extends Component {
    @observable batch = new Batch(undefined, { relations: ['batchType', 'warehouse'] })
    @observable batchTypeStore = new BatchTypeStore({
        relations: ['articleType'],
        params: { '.type': 'stock_count' },
    })
    @observable warehouseStore = new WarehouseStore()

    @observable isOpen = false
    close = () => this.isOpen = false
    open = () => {
        this.isOpen = true
    }

    save = () => {
        clearNotifications()
        this.batch.__backendValidationErrors = {}
        const copy = new Batch({ ...this.batch.toJS(), id: null }, { relations: ['batchType', 'warehouse'] })

        return this.batch.wrapPendingRequestCount(
            copy.save({ url: copy.url + 'manually_create_batch/' }).then(showSaveNotification).catch(() => {
                this.batch.__backendValidationErrors = copy.__backendValidationErrors
            })
        )
    }

    render() {
        return (
            <Modal data-test-batch-create-modal
                open={this.isOpen}
                onClose={this.close}
                trigger={<ToolbarButton icon="add" data-test-show-create-batch-modal onClick={this.open} content="Create batch" />}
            >
                <Modal.Content>
                    <Form>
                        <TargetTextInput autoFocus
                            autoComplete="off"
                            target={this.batch}
                            name="serialNumber"
                        />
                        <TargetSelect search remote
                            target={this.batch}
                            name="batchType"
                            searchKey=".article_type.search"
                            store={this.batchTypeStore}
                            toOption={batchType => ({
                                text: `${batchType.description} | ${batchType.articleType.code} | ${batchType.articleType.name}`,
                                value: batchType.id,
                            })}
                            afterChange={batchType => {
                                this.warehouseStore.params['.article_type_warehouses.article_type'] = batchType.articleType.id
                                this.warehouseStore.fetch()

                                this.batch.setInput('warehouse', null)
                            }}
                        />
                        <TargetSelect search remote
                            target={this.batch}
                            name="warehouse"
                            store={this.warehouseStore}
                            toOption={warehouse => ({
                                text: `${warehouse.code} | ${warehouse.name} `,
                                value: warehouse.id,
                            })}
                        />
                        <TargetDecimalInput
                            target={this.batch}
                            name="quantity"
                        />
                        <TargetDatePicker
                            target={this.batch}
                            name="bestBeforeDate"
                            label={t('formStepField.field.type.value.best_before_date')}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <CancelButton
                        onClick={this.close}
                    />
                    <RightDivider />
                    <SaveButton
                        loading={this.batch.isLoading}
                        onClick={this.save}
                    />
                </Modal.Actions>
            </Modal>
        )
    }
}
