import { observable, action, computed } from 'mobx'
import { Model, Store } from 'store/Base'
import { ArticleType } from './ArticleType'
import { ProcessVersionStore } from './ProcessVersion'
import { serialNumberFormatToRegex } from 'helpers'
import { Classification } from './Classification'
import { ItemAssortmentValue } from './ItemAssortmentValue'

export const TYPE_BUY = 'buy'
export const TYPE_ASSEMBLY = 'assembly'
export const TYPE_MAKE = 'make'
export const TYPE_SELL = 'sell'
export const TYPE_TRANSFER = 'transfer'
export const TYPE_TRANSFER_LINE = 'transfer_line'
export const TYPE_ON_THE_FLY = 'on_the_fly'
export const TYPE_RECEIVE = 'receive_order'
export const TYPE_PICK = 'pick_order'
export const TYPE_COMPONENT = 'component'
export const TYPE_STOCK_COUNT = 'stock_count'

export const GLOBAL_TYPES = [
  TYPE_RECEIVE,
  TYPE_PICK,
  TYPE_TRANSFER,
]
export const LOCAL_TYPES = [
  TYPE_BUY,
  TYPE_MAKE,
  TYPE_SELL,
  TYPE_TRANSFER_LINE,
  TYPE_ON_THE_FLY,
  TYPE_STOCK_COUNT,
]

export const PROCESS_TYPES = [
  TYPE_RECEIVE, TYPE_BUY,
  TYPE_PICK, TYPE_SELL,
  TYPE_TRANSFER, TYPE_TRANSFER_LINE,
  TYPE_MAKE, TYPE_STOCK_COUNT,
]

export const MAIN_PROCESS_TYPES = [
  TYPE_MAKE, TYPE_RECEIVE, TYPE_PICK, TYPE_TRANSFER, TYPE_STOCK_COUNT,
]

export const TYPE_COLORS = {
  [TYPE_RECEIVE]: '#42A5F5',
  [TYPE_PICK]: '#C0CA33',
  [TYPE_TRANSFER]: '#FF5252',
  [TYPE_BUY]: '#42A5F5',
  [TYPE_SELL]: '#C0CA33',
  [TYPE_TRANSFER_LINE]: '#FF5252',
  [TYPE_MAKE]: '#f2711c',
  [TYPE_STOCK_COUNT]: '#e03997',
}

export const USER_DEFINED_TYPES = [...GLOBAL_TYPES, ...LOCAL_TYPES]
export const TEMPLATE_TYPES = (
  LOCAL_TYPES
    .map((type) => `${type}_template`)
)
export const SYSTEM_TYPES = [TYPE_COMPONENT]
export const TYPES = [...USER_DEFINED_TYPES, ...TEMPLATE_TYPES, ...SYSTEM_TYPES]

export const TYPE_ICONS = {
  [TYPE_ASSEMBLY]: 'cubes',
  [TYPE_MAKE]: 'tools',
  [TYPE_BUY]: 'shopping basket',
  [TYPE_RECEIVE]: 'shopping basket',
  [TYPE_SELL]: 'shipping fast',
  [TYPE_PICK]: 'shipping fast',
  [TYPE_TRANSFER]: 'dolly',
  [TYPE_TRANSFER_LINE]: 'dolly',
  [TYPE_STOCK_COUNT]: 'boxes',
}

export const ISSERIAL_ICON = 'barcode'

export class BatchType extends Model {
  static backendResourceName = 'batch_type'
  static omitFields = ['targetCount']
  static ON_THE_FLY_TYPES = ['fixed', 'flexible', 'none']
  static ON_THE_FLY_SOURCES = ['new', 'existing']

  @observable id = null
  @observable type = TYPE_MAKE
  @observable description = ''
  @observable deleted = false
  @observable default = false
  @observable targetCount = 0
  @observable onTheFlyType = 'fixed'
  @observable onTheFlySize = 1
  @observable onTheFlySource = 'new'
  @observable onTheFlySerialNumberFormat = [{ type: 'anything' }]
  @observable autoAddProcessToNewArticles = false

  @computed
  get onTheFlySerialNumberRegex() {
    return serialNumberFormatToRegex(this.onTheFlySerialNumberFormat, this.articleType?.code)
  }

  toBackend(...args) {
    const data = super.toBackend(...args)
    const fly = this.type !== 'on_the_fly' && this.type !== 'on_the_fly_template'
    if (fly) {
      data.on_the_fly_type = null
      data.on_the_fly_source = null
      data.on_the_fly_serial_number_format = null
      delete data.serial_number_format
    }
    if (fly || this.onTheFlyType !== 'fixed') {
      data.on_the_fly_size = null
    }
    return data
  }

  @action toBackendAll(...args) {
    let oldRelations = null
    if (
      this.processVersions &&
      ((this.source && !this.source.isNew) || this.type === 'on_the_fly')
    ) {
      oldRelations = this.__activeCurrentRelations
      this.__activeCurrentRelations = oldRelations.filter((rel) => rel !== 'processVersions')
    }

    try {
      return super.toBackendAll(...args)
    } finally {
      if (oldRelations !== null) {
        this.__activeCurrentRelations = oldRelations
      }
    }
  }

  relations() {
    return {
      articleType: ArticleType,
      processVersions: ProcessVersionStore,
      source: BatchType,
      targets: BatchTypeStore,
      classification: Classification,
      itemAssortmentValue: ItemAssortmentValue,
    }
  }
}

export class BatchTypeStore extends Store {
  Model = BatchType
  static backendResourceName = 'batch_type'
}
