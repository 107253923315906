import { Model, Store } from 'store/Base'
import { Integration } from '../Integration'
import { observable } from 'mobx'
import { Metafield } from '../Metafield';

export class RadanIntegration extends Model {
  static backendResourceName = 'radan_integration';
  static omitFields = ['active'];

  @observable id = null;
  @observable active = false;
  @observable nextNestNumber = 0;

  relations() {
    return {
      integration: Integration,
      symbolMetafield: Metafield,
    };
  }

  createExportXml = async (batchStore) => {
    const data = {
      'batches': batchStore.map(pr => pr.id)
    }

    return await this.__getApi()
      .post(
        `${this.url}export_xml/`,
        data,
        {}
      )
  }

  importXml = (operator, formData, progressReporter) => {
    formData.append('operator', operator.id)

    return this.__getApi().post(
      `${this.url}import_xml/`,
      formData,
      {
        onUploadProgress: progressEvent => {
          progressReporter(progressEvent.total, progressEvent.loaded)
        }
      }
    )
  }
}

export class RadanIntegrationStore extends Store {
  static backendResourceName = 'radan_integration'
  Model = RadanIntegration
}
