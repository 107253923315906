import styled, { css } from 'styled-components'

export const openCss = css`
  transform: translate(-50%, -100%);
  opacity: 1;
`

export const closedCss = css`
  transform: translate(-50%, 0) scale(0);
  opacity: 0;
`

interface ToolTipProps {
    open: boolean
}
export default styled.div<ToolTipProps>`
  font-size: 0.8em;
  line-height: 1;
  white-space: nowrap;
  padding: 0.5em;
  border-radius: 0.5em;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(34, 36, 38, 0.15);
  position: absolute;
  left: 50%;
  top: -0.5em;
  z-index: 10;

  ${({ open }) => (open ? openCss : closedCss)}
  transition: transform 300ms ease, opacity 300ms ease;
  pointer-events: none;

  ::after {
    content: '';
    background-color: #fff;
    width: 0.6em;
    height: 0.6em;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 50%) rotate(45deg) translate(0.5px, 0.5px);
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-left-width: 0;
    border-top-width: 0;
  }
  filter: drop-shadow(0 0.1rem 0.3rem rgba(0, 0, 0, 0.1));
`
