import { observable } from 'mobx'
import { Model, Store } from 'store/Base'

export class NavisionCustomer extends Model {
  static backendResourceName = 'navision_customer'

  @observable id = null
  @observable customerId = ''
  @observable name = ''
}

export class NavisionCustomerStore extends Store {
  static backendResourceName = 'navision_customer'
  Model = NavisionCustomer
}
